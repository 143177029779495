import {PUB_SCOPE} from 'constants/ad';

const top100Wrapper = {
  // Флаг, что топста загружена
  isLoaded: false,

  // Очередь функций для загруженной топсты
  pushArray: [],

  // IDs проектов топсты, для которых инициирован счетчик
  projectIds: [],

  // Общие настройки для всех счетчиков
  options: {
    attributes_dataset: [],
    splits:             [],
  },

  // Инициированные счетчики
  top100Counters: {},

  init({projectIds = [], ...options} = {}) {
    if (typeof window === 'undefined') {
      return;
    }

    //eslint-disable-next-line
    window._top100q = window._top100q || [];

    this.options = {
      ...options,
      attributes_dataset: [
        ...this.options.attributes_dataset,
        ...options.attributes_dataset,
      ],
      splits: [
        ...this.options.splits,
        ...options.splits,
      ],
      pub_scope: PUB_SCOPE,
      user_id:   null,
    };

    projectIds.forEach((projectId, index) => {
      //eslint-disable-next-line
      window._top100q.push(() => {
        //eslint-disable-next-line
        this.top100Counters[projectId] = new window.top100({
          ...this.options,
          project: projectId,
        });

        if (index === 0) {
          // Первый счетчик принимаем за дефолтный
          window.top100Counter = this.top100Counters[projectId];
        }
      });
    });

    //eslint-disable-next-line
    window._top100q.push(() => {
      this.pushArray.forEach((fn) => fn());
      this.pushArray = [];
      this.isLoaded = true;
    });
  },

  push(fn) {
    if (this.isLoaded) {
      fn();
    } else {
      this.pushArray.push(fn);
    }
  },

  sendCustomVars(vars) {
    if (!Object.keys(vars).length) {
      return;
    }

    this.push(() => {
      Object.keys(this.top100Counters).forEach((projectId) => {
        this.top100Counters[projectId].sendCustomVars(vars);
      });
    });
  },

  trackPageview() {
    this.push(() => {
      Object.keys(this.top100Counters).forEach((projectId) => {
        this.top100Counters[projectId].trackPageview();
      });
    });
  },

  sendUserId(userId) {
    this.push(() => {
      Object.keys(this.top100Counters).forEach((projectId) => {
        this.top100Counters[projectId].syncUserId(userId);
      });
    });
  },
};

export default top100Wrapper;
