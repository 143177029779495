import {
  FETCH_GEO_AUTODETECT_DATA,
  FETCH_GEO_AUTODETECT_DATA_FAILURE,
  FETCH_GEO_CURRENT_DATA,
  FETCH_GEO_CURRENT_DATA_FAILURE,
  SET_GEO_CURRENT_DATA_FAILURE,
  FETCH_GEO_SUGGEST_DATA,
  FETCH_GEO_SUGGEST_DATA_FAILURE,
  CLEAR_GEO_SUGGEST_DATA,
} from 'constants/actions/geo';

import {
  SEARCH_API_URLS,
  GEO_API_URLS,
} from 'constants/index';

import {updateHome} from 'core/updater';
import captureException from 'core/captureException';

export function autodetect() {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      const req = await fetch(GEO_API_URLS.autodetect);
      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось определить местоположение автоматически`);

        err.status = req.status;
        err.response = req;
        throw err;
      }
      if (__SERVER__) {
        statsd.timing('service_request.autodetect.timer', startTime);
        statsd.timing('service_request.fetchGeoGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.autodetect.timer', startTime);
      }

      dispatch({
        type:    FETCH_GEO_AUTODETECT_DATA,
        payload: {
          declinationCases: data.declination_cases,
          name:             data.name,
          id:               +data.id,
        },
      });
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.autodetect.counter');
      }

      await dispatch({
        type:    FETCH_GEO_AUTODETECT_DATA_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}

export function current() {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      const req = await fetch(GEO_API_URLS.current, {credentials: 'include'});
      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось определить текущее местоположение`);

        err.status = req.status;
        err.response = req;
        throw err;
      }
      if (__SERVER__) {
        statsd.timing('service_request.current.timer', startTime);
        statsd.timing('service_request.fetchGeoGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.current.timer', startTime);
      }

      dispatch({
        type:    FETCH_GEO_CURRENT_DATA,
        payload: {
          declinationCases: data.declination_cases,
          name:             data.name,
          id:               +data.id,
        },
      });
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.current.counter');
      }

      await dispatch({
        type:    FETCH_GEO_CURRENT_DATA_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}

export function setGeoLocation(geoID) {
  return async(dispatch, getState, {fetch, statsd}) => {
    const back = geoID === false ? '' : `?back=${geoID}`;

    const startTime = new Date();

    try {
      const req = await fetch(`${GEO_API_URLS.setLocation}${back}`, {credentials: 'include'});
      if (req.ok === false) {
        const error = new Error(`${new Date()} Не удалось установить текущее местоположение`);
        error.response = req;
        throw error;
      }

      if (__SERVER__) {
        statsd.timing('service_request.setGeoLocation.timer', startTime);
        statsd.timing('service_request.fetchGeoGlobalTimer.timer', startTime);
      }

      current();

      const isMobile = getState().runtime.isMobile || false;
      dispatch(updateHome(geoID, isMobile ? 'mobile' : 'desktop'));
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.setGeoLocation.counter');
      }

      dispatch({type: SET_GEO_CURRENT_DATA_FAILURE});
    }
  };
}

export function requestSuggestData(query) {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      const url = `${SEARCH_API_URLS.geo}${encodeURIComponent(query)}`;
      const req = await fetch(url);
      if (req.ok === false) {
        const error = new Error(`${new Date()} Не удалось найти город в GEO`);
        error.response = req;
        throw error;
      }
      if (__SERVER__) {
        statsd.timing('service_request.requestSuggestData.timer', startTime);
        statsd.timing('service_request.fetchGeoGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.requestSuggestData.timer', startTime);
      }

      dispatch({
        type:    FETCH_GEO_SUGGEST_DATA,
        payload: {data, query},
      });
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.requestSuggestData.counter');
      }

      dispatch({type: FETCH_GEO_SUGGEST_DATA_FAILURE});
      console.error(error);
    }
  };
}

export function clearSuggestData() {
  return {
    type:    CLEAR_GEO_SUGGEST_DATA,
    payload: {},
  };
}
