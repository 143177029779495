import {connect} from 'react-redux';

import Agroprognoz from './index';

const mapStateToProps = state => ({
  dateWeather: state.agroprognoz.dateWeather,
  tableData:   state.agroprognoz.tableData,
  currentDay:  state.agroprognoz.currentDay,
});

export default connect(mapStateToProps)(Agroprognoz);
