import React, {Fragment} from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import {scroller} from 'react-scroll';

import {
  AD_SUPERFOOTER_ID,
  AD_LISTING_1_ID,
} from 'constants/ad';

import Ad from 'components/Ad';

import GeoModal from 'components/GeoModal';

import YandexLogo from 'components/YandexLogo';

import ChangeUserGeo from 'Desktop/components/ChangeUserGeo';
import ChangeCity from 'Desktop/components/ChangeCity';
import WeatherNdaysTable from 'Desktop/components/WeatherNdaysTable';
import WeatherNdaysChart from 'components/WeatherNdaysChart';
import SimpleNowBlock from 'Desktop/components/SimpleNowBlock';
import SimplePopularRequests from 'Desktop/components/SimplePopularRequests';
import ChartNdaysAsTable from 'Desktop/components/ChartNdaysAsTable';
import WeatherDayLines from 'Desktop/components/WeatherDayLines';
import WeatherDayCards from 'Desktop/components/WeatherDayCards';
import TableDataBlock from 'Desktop/components/TableDataBlock';

import CitiesWeather from 'Desktop/components/CitiesWeather';
import NearestCities from 'Desktop/components/NearestCities';

import Table from './i/table.svg';
import s from './index.scss';

class Ndays extends React.Component {
  static propTypes = {
    town: pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    windowType:        pt.string.isRequired,
    screenTypeReady:   pt.bool.isRequired,
    page:              pt.string.isRequired,
    days:              pt.number.isRequired,
    chunkedChartsData: pt.array,
    rangeData:         pt.array,
    tableData:         pt.array,
    currentDay:        pt.string,
  }

  static defaultProps = {
    chunkedChartsData: null,
    rangeData:         null,
    tableData:         null,
    currentDay:        null,
  }

  getTitleText = (town, days) => {
    if (!town) return null;

    let text = `Погода ${town.loc_case_name} на `;

    if (days === 3) {
      text = `Погода на 3 дня ${town.loc_case_name}`;
    } else if (days === 7) {
      text += 'неделю';
    } else if (days === 10) {
      text += '10 дней';
    } else if (days === 14) {
      text += 'две недели';
    }

    return text;
  }

  scrollToTable = () => {
    scroller.scrollTo('table', {
      duration: 250,
      smooth:   true,
    });
  }

  render() {
    const {
      days,
      town,
      windowType,
      screenTypeReady,
      page,
      chunkedChartsData,
      rangeData,
      tableData,
      currentDay,
    } = this.props;

    const hasDataInChunkedChartsData = chunkedChartsData && chunkedChartsData[0].length > 0;

    return (
      <div className={s.ndays}>
        <div className={
          cn(
            s.ndaysBlock,
            days === 3 && s.ndaysBlockShort,
          )}
        >
          <div className={s.ndaysBlockContainer} data-block="location">
            <div className={s.ndaysTop}>
              <h1 className={s.ndaysTitle}>{this.getTitleText(town, days)}</h1>
              <ChangeUserGeo />
            </div>
            {days !== 3 &&
              <div className={s.ndaysSwitcher}>
                <div
                  className={s.ndaysSwitcherItem}
                  onClick={() => this.scrollToTable()}
                  data-weather={`${page}::detailed`}
                >
                  <Table className={s.ndaysSwitcherItemIcon} />
                  <span className={s.ndaysSwitcherItemText}>Подробный прогноз</span>
                </div>
              </div>
            }
            <ChangeCity className={s.ndaysLocation} />
          </div>
          {hasDataInChunkedChartsData && days !== 3 &&
            <noscript
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `<style>
                  .${s.ndaysChart} {display: none}
                  .${s.ndaysNoJsTable} {
                    visibility: visible;
                    height: auto;
                    min-height: 200px !important;
                    padding-top: 20px !important;
                  }
                </style>`,
              }}
            />
          }
          {days !== 3 &&
            <WeatherNdaysChart className={s.ndaysChart} />
          }
          {hasDataInChunkedChartsData && days !== 3 &&
            chunkedChartsData.map((data, index) => {
              const key = `ChartNdaysAsTable-chunk${index}`;

              return (
                <ChartNdaysAsTable
                  className={s.ndaysNoJsTable}
                  chartsData={data}
                  key={key}
                />
              );
            })
          }
          {days === 3 && (
            <Fragment>
              <WeatherDayLines
                currentDay={currentDay}
                rangeData={rangeData}
                tableData={tableData}
              />
              <YandexLogo className={cn(s.yandex, s.yandex3days)} />
            </Fragment>
          )}
          {days !== 3 && (
            <YandexLogo
              className={s.yandex}
              underChart
              withBottomPadding
            />
          )}
        </div>
        {days === 3 &&
          <WeatherDayCards
            currentDay={currentDay}
            rangeData={rangeData}
            tableData={tableData}
          />
        }
        {windowType !== 'mobile' && screenTypeReady &&
          <Ad
            id={AD_SUPERFOOTER_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isLazy
          />
        }
        {windowType === 'mobile' && screenTypeReady &&
          <Ad
            id={AD_LISTING_1_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isMobInDesk
            isLazy
          />
        }
        {days === 3 &&
          <TableDataBlock
            period={`${days}days`}
            tableData={tableData}
            currentDay={currentDay}
          />
        }
        {days === 14 &&
          <div className={s.ndaysSimpleBlocks}>
            <SimpleNowBlock />
            <SimplePopularRequests />
          </div>
        }
        <NearestCities />
        {days !== 3 &&
          <WeatherNdaysTable className={s.ndaysTable} />
        }
        <CitiesWeather />

        <GeoModal />
      </div>
    );
  }
}

export const NdaysWithoutHocs = Ndays;
export default withStyles(s)(Ndays);
