import {createSelector} from 'reselect';

import _forEach from 'lodash.foreach';
import _chunk from 'lodash.chunk';

import {MONTHS_ABBR_GEN} from 'constants/months';

const dayPartTime = {
  night:   '00:00',
  morning: '06:00',
  day:     '12:00',
  evening: '18:00',
};

const dayNight = {
  day:   'Днём',
  night: 'Ночью',
};

const getData = state => state || [];

export default createSelector(
  [getData],
  (data) => {
    const readyData = [];

    const onlyDayNight = data.length > 3;

    data.forEach((item) => {
      const dayObj = {
        data:     [],
        dayParts: [],
      };

      const day = new Date(item.date);
      const date = `${day.getDate()}`;
      const month = MONTHS_ABBR_GEN[day.getMonth()];

      dayObj.date = `${date} ${month}`;

      _forEach(item.forecast, (params, key) => {
        const paramsObj = {
          temperature:   params.temperature,
          humidity:      params.humidity !== null ? params.humidity : 'Нет данных',
          pressure:      params.pressure !== null ? params.pressure : 'Нет данных',
          wind:          params.wind_speed,
          precipitation: params.precipitation !== null ? params.precipitation : 'Нет данных',
        };

        if (onlyDayNight) {
          if (['day', 'night'].includes(key)) {
            dayObj.dayParts.push(dayNight[key]);
            dayObj.data.push(paramsObj);
          }
        } else {
          dayObj.dayParts.push(dayPartTime[key]);
          dayObj.data.push(paramsObj);
        }
      });

      readyData.push(dayObj);
    });

    return _chunk(readyData, data.length === 14 ? 3 : 4);
  },
);
