import {
  FETCH_HEADER_STATUS_DATA_SUCCESS,
  FETCH_HEADER_STATUS_DATA_FAILURE,
  FETCH_HEADER_MOON_DATA_SUCCESS,
  FETCH_HEADER_MOON_DATA_FAILURE,
} from 'constants/actions/header';

import {DEFAULT_API_URL} from 'constants/index';

import captureException from 'core/captureException';

export function fetchHeaderData(geoId) {
  return async(dispatch, getState, {fetch}) => {
    try {
      const url = `${DEFAULT_API_URL}/sunrise-sunset/today/?geo_id=${geoId}`;

      const req = await fetch(url);

      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось получить данные для хедера: ${url}`);

        err.status = req.status;
        throw err;
      }

      const data = await req.json();

      await dispatch({
        type:    FETCH_HEADER_STATUS_DATA_SUCCESS,
        payload: {data},
      });
    } catch (error) {
      captureException(error);

      await dispatch({
        type:    FETCH_HEADER_STATUS_DATA_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}

export function fetchMoonData(isProd) {
  return async(dispatch, getState, {fetch}) => {
    try {
      const url = isProd
        ? 'https://horoscopes.rambler.ru/api/front/v4/moon/widget/'
        : 'https://stage.horos.rambler.ru/api/front/v4/moon/widget/';

      const req = await fetch(url);

      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось получить данные лунных дней для хедера: ${url}`);

        err.status = req.status;
        throw err;
      }

      const data = await req.json();

      await dispatch({
        type:    FETCH_HEADER_MOON_DATA_SUCCESS,
        payload: {data},
      });
    } catch (error) {
      captureException(error);

      await dispatch({
        type:    FETCH_HEADER_MOON_DATA_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}
