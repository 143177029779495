import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import sendMetrics from 'core/metrics/metrics';

import WeatherIcon from 'components/WeatherIcon';

import s from './index.scss';

class WeatherHours extends React.Component {
  static propTypes = {
    data: pt.arrayOf(pt.shape({
      hour:           pt.number.isRequired,
      icon:           pt.string.isRequired,
      temperature:    pt.number.isRequired,
      prec_mm:        pt.number.isRequired,
      wind_speed:     pt.number.isRequired,
      wind_direction: pt.string.isRequired,
      pressure_mm:    pt.number.isRequired,
      humidity:       pt.number.isRequired,
    })),
    isFirstVisit:   pt.bool.isRequired,
    seoDescription: pt.string.isRequired,
  };

  static defaultProps = {
    data: null,
  };

  componentDidMount() {
    if (window.performance) {
      const mountMoment = performance.now();
      const renderTime = Math.round(mountMoment - window.startTime);

      if (this.props.isFirstVisit) {
        sendMetrics({name: 'client_desktop_now_hours_time', value: renderTime});
      }
    }
  }

  render() {
    const {
      data,
      seoDescription,
    } = this.props;

    return (
      <div className={s.root} data-block="hourly">
        {data &&
          <div className={s.container}>
            <h2 className={s.title}>Почасовой прогноз погоды</h2>
            <table className={s.table}>
              <thead>
                <tr className={s.theadLine}>
                  <th>Часы</th>
                  <th>Температура</th>
                  <th>Осадки</th>
                  <th>Ветер</th>
                  <th>Давление</th>
                  <th>Влажность</th>
                </tr>
              </thead>
              <tbody className={s.tbodyLine}>
                {data.map((item, i) => (
                  <tr
                    className={s.item}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`WeatherHour-${i}`}
                  >
                    <td>
                      <div className={s.itemColumn}>
                        <span className={s.itemTitle}>
                          {item.hour < 10 ? '0' : ''}{item.hour}:00
                        </span>
                      </div>
                    </td>
                    <td className={s.itemTemperature}>
                      <div className={s.itemColumn}>
                        <WeatherIcon
                          size={30}
                          type={item.icon}
                          className={s.itemTemperatureIcon}
                        />
                        <span className={s.itemTemperatureValue}>
                          {item.temperature < 0 ? `−${Math.abs(item.temperature)}` : item.temperature}&deg;
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className={s.itemColumn}>
                        <span className={s.itemPrecipitation}>{item.prec_mm} мм</span>
                      </div>
                    </td>
                    <td>
                      <div className={s.itemColumn}>
                        <div className={s.itemWind}>
                          <WeatherIcon
                            type="wind"
                            size={15}
                            className={cn(
                              s.itemWindIcon,
                              s[`itemWindIcon--${item.wind_direction.toLowerCase()}`],
                            )}
                          />
                          <span className={s.itemWindText}>{item.wind_speed} м/с</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={s.itemColumn}>
                        <span className={s.itemPressure}>{item.pressure_mm} мм</span>
                      </div>
                    </td>
                    <td>
                      <div className={s.itemColumn}>
                        <span className={s.itemHumidity}>{item.humidity}%</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {seoDescription && <p className={s.seo}>{seoDescription}</p>}
          </div>
        }
        {!data &&
          <div className={s.noData}>
            Извините, нет подходящих данных.<br />
            Попробуйте обновить страницу
          </div>
        }
      </div>
    );
  }
}

export const WeatherHoursWithoutHocs = WeatherHours;
export default withStyles(s)(WeatherHours);
