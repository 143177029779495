import React, {Fragment} from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import _debounce from 'lodash.debounce';

import {URL_REGEXP} from 'constants/index';
import {UPDATE_TIMEOUT} from 'constants/updater';

// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';

import {
  AD_FULLSCREEN_ID,
  AD_FULLSCREEN_MOBILE_ID,
  AD_RICH,
  AD_BILLBOARD_ID,
  AD_TOP_BANNER_ID,
  AD_240X400_ID,
  AD_240X400_2_ID,
  AD_PROMOVIDZHET_ID,
  AD_FOOTER_ID,
  AD_SHOW_UP_ID,
} from 'constants/ad';

import history from 'core/history';
import sendMetrics from 'core/metrics/metrics';
import RoutePageContext from 'context/routePageContext';

import AdblockDetect from 'components/AdblockDetect';
import Ad from 'components/Ad';
import TopPart from 'Desktop/components/TopPart';
import Menu from 'Desktop/components/Menu';

import BreadcrumbsOldDesign from 'Desktop/components/Breadcrumbs';
import BreadcrumbsNewDesign from 'components/BreadcrumbsNewDesign';

import Footer from 'Desktop/components/Footer';

import s from './index.scss';

class Layout extends React.Component {
  static propTypes = {
    children:        pt.node.isRequired,
    isPadVersion:    pt.bool.isRequired,
    setScreenType:   pt.func.isRequired,
    setCityChanging: pt.func.isRequired,
    windowType:      pt.string.isRequired,
    screenTypeReady: pt.bool.isRequired,
    isNewDesign:     pt.bool.isRequired,
    town:            pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    routePage:          pt.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isCityChanging:     pt.bool.isRequired,
    updateAll:          pt.func.isRequired,
    withoutBreadcrumbs: pt.bool,
  };

  static defaultProps = {
    withoutBreadcrumbs: false,
  }

  componentDidMount() {
    if (window.performance) {
      const initMoment = performance.now();
      const clientParsingTime = Math.round(window.clientDesktopEnd - window.clientDesktopStart);
      const initTime = Math.round(initMoment - window.clientDesktopEnd);
      let domTimerStop;

      sendMetrics({name: 'client_parsing_time', value: clientParsingTime});
      sendMetrics({name: 'react_init_time', value: initTime});

      const domTimer = setInterval(() => {
        if (window.DOMContentLoaded) {
          sendMetrics({name: 'client_dom_content_loaded', value: window.DOMContentLoaded});

          clearTimeout(domTimerStop);
          clearInterval(domTimer);
        }
      }, 5000);

      domTimerStop = setTimeout(() => {
        clearInterval(domTimer);
      }, 60000);

      // chrome only!
      // eslint-disable-next-line no-restricted-syntax
      // for (const ev of performance.getEntriesByType('paint')) {
      //   if (ev.name === 'first-contentful-paint') {
      //     sendMetrics({
      //       name:  'chrome_time_to_first_contentful_paint',
      //       value: ev.startTime - window.startTime,
      //     });
      //     break;
      //   }
      // }

      document.addEventListener('visibilitychange', this.onVisibilityChanged);
      this.onVisibilityChanged();
    }

    requestAnimationFrame(() => {
      // для показа мобильной версии, т.к. с сервера прилетает обычная по-умолчанию
      // https://github.com/erikras/react-redux-universal-hot-example/issues/769#issuecomment-169153596
      this.setWindowType();
    });

    window.addEventListener('resize', _debounce(() => {
      this.setWindowType();
    }, 10));
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      town,
      routePage,
    } = this.props;
    const nextTown = nextProps.town;
    const cityReg = /(v-.*|vo-.*)/;

    if (
      nextProps.isCityChanging
      && town.url_path !== nextTown.url_path
      && routePage.match(URL_REGEXP)
    ) {
      this.props.setCityChanging(false);
      history.push(`/${nextTown.url_path}${routePage}`);
    } else if (
      nextProps.isCityChanging
      && town.url_path !== nextTown.url_path
      && (routePage.match(cityReg) || routePage === '/')
    ) {
      this.props.setCityChanging(false);
      history.push(`/${nextTown.url_path}/`);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.onVisibilityChanged);

    if (this.pageUpdater) {
      clearTimeout(this.pageUpdater);
    }
  }

  onVisibilityChanged = () => {
    if (document.hidden) {
      this.onTabDeactivate();
    } else {
      this.onTabActivate();
    }
  }

  onTabDeactivate = () => {
    if (this.pageUpdater) {
      return;
    }

    this.isUpdated = false;

    const upd = () => {
      this.pageUpdater = setTimeout(upd, UPDATE_TIMEOUT);
      this.isUpdated = true;
    };

    this.pageUpdater = setTimeout(upd, UPDATE_TIMEOUT);
  }

  onTabActivate() {
    if (this.pageUpdater) {
      clearTimeout(this.pageUpdater);
      this.pageUpdater = null;
    }

    if (this.isUpdated) {
      this.scrollToTop();
      this.updateUrl();
      this.props.updateAll();
    }
  }

  setWindowType = () => {
    const {
      windowType,
      isPadVersion,
    } = this.props;

    const width = window.innerWidth
          || document.documentElement.clientWidth
          || document.body.clientWidth;
    const nowWindowType = this.getWindowType(width);

    if (windowType !== nowWindowType) {
      this.props.setScreenType(nowWindowType, isPadVersion);
    }
  }

  getWindowType = (width) => {
    const {
      isNewDesign,
      isPadVersion,
    } = this.props;

    const maxScreenWidth = isNewDesign ? 1260 : 1280;

    if (width >= maxScreenWidth) {
      return 'normal';
    } else if ((width < maxScreenWidth && width >= 1024) || !isPadVersion) {
      return 'small';
    }

    return 'mobile';
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  updateUrl = () => {
    const {
      origin,
      pathname,
      search,
      hash,
    } = document.location;
    const link = origin + pathname + search;

    if (window.history && window.history.replaceState) {
      if (link.indexOf('updated') === -1) {
        const urlWithChar = link.indexOf('?') === -1 ? `${link}?` : `${link}&`;
        const url = `${urlWithChar}updated${hash}`;
        const stateData = {path: url, scrollTop: 0};

        window.history.replaceState(stateData, 'title', url);
      } else {
        const url = `${link}${hash}`;
        const stateData = {path: url, scrollTop: 0};

        window.history.replaceState(stateData, 'title', url);
      }
    }
  }

  render() {
    const {
      isNewDesign,
      isPadVersion,
      windowType,
      routePage,
      screenTypeReady,
      withoutBreadcrumbs,
    } = this.props;

    const Breadcrumbs = isNewDesign ? BreadcrumbsNewDesign : BreadcrumbsOldDesign;

    return (
      <RoutePageContext.Provider value={routePage}>
        {windowType !== 'mobile' && screenTypeReady && <Ad id={AD_SHOW_UP_ID} />}
        {windowType !== 'mobile' && screenTypeReady && <Ad id={AD_FULLSCREEN_ID} />}
        {windowType === 'mobile' && screenTypeReady &&
          <Fragment>
            <Ad id={AD_FULLSCREEN_MOBILE_ID} isMobInDesk />
            <Ad id={AD_RICH} isMobInDesk />
          </Fragment>
        }
        {/* AdblockDetect удалять нельзя. Да будет 2 div с ним (1 этот, 2 из компонента рекламы) */}
        {/* Однако, тот что из компонента нельзя встроить везде, где это нужно */}
        <AdblockDetect />
        <TopPart isNeedBanner={windowType !== 'mobile' && screenTypeReady} />
        <Menu />
        {(!isPadVersion || (windowType !== 'mobile' && screenTypeReady)) &&
          <div className={cn(s.billboardBlock, 'banner-billboard')}>
            <Ad
              id={AD_BILLBOARD_ID}
              wrapperClassName={s.billboard}
              isReloaded
            />
          </div>
        }
        {windowType === 'mobile' && screenTypeReady &&
          <Ad
            id={AD_TOP_BANNER_ID}
            onRenderWrapperStyle={{paddingTop: isNewDesign ? 0 : 15}}
            isMobInDesk
            isReloaded
          />
        }
        <div className={cn(
          s.main,
          'commercial-branding',
          isNewDesign ? s.mainNewDesign : s.mainOldDesign,
        )}
        >
          {!withoutBreadcrumbs && <Breadcrumbs />}
          <div className={cn(
            s.center,
            isNewDesign ? s.centerNewDesign : s.centerOldDesign,
            withoutBreadcrumbs && s.withoutBreadcrumbs,
          )}
          >
            {this.props.children}
          </div>
          <div
            className={cn(
              s.right,
              isNewDesign && s.rightNewDesign,
              withoutBreadcrumbs && s.withoutBreadcrumbs,
            )}
          >
            {windowType !== 'mobile' && screenTypeReady &&
              <Ad
                id={AD_PROMOVIDZHET_ID}
                onRenderWrapperStyle={{marginBottom: isNewDesign ? 20 : 15}}
                isReloaded
              />
            }
            {(!isPadVersion || (windowType !== 'mobile' && screenTypeReady)) &&
              <Ad
                id={AD_240X400_ID}
                isReloaded
              />
            }
            {windowType !== 'mobile' && screenTypeReady &&
              <Ad
                id={AD_240X400_2_ID}
                onRenderWrapperStyle={{marginTop: isNewDesign ? 20 : 15}}
                isReloaded
                isLazy
                isSticky
              />
            }
          </div>
        </div>
        {windowType === 'mobile' && screenTypeReady &&
          <Ad
            id={AD_FOOTER_ID}
            isMobInDesk
            isReloaded
            isLazy
          />
        }
        <Footer />
      </RoutePageContext.Provider>
    );
  }
}

export const LayoutWithoutHocs = Layout;
export default withStyles(normalizeCss, s)(Layout);
