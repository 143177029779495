import React from 'react';
import pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import _debounce from 'lodash.debounce';

import Hint from 'rambler-ui/Hint';
import WeatherIcon from 'components/WeatherIcon';
import Link from 'components/Link';

import {links} from 'static/dayLinks';

import s from './index.scss';

class CitiesHint extends React.Component {
  static propTypes = {
    country: pt.shape({
      name:          pt.string.isRequired,
      url_path:      pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
    }),
    city: pt.shape({
      town__name:     pt.string.isRequired,
      town__url_path: pt.string.isRequired,
      icon_name:      pt.string.isRequired,
      temp:           pt.number.isRequired,
    }).isRequired,
    page: pt.string.isRequired,
  }

  static defaultProps = {
    country: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      contentMargin: 0,
    };
    this.hint = React.createRef();
  }

  componentDidMount() {
    this.updateMargin();
    window.addEventListener('resize', this.updateMargin);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMargin);
  }

  getTop100Attr = (page, country, city) => {
    const cityName = city.town__name;
    let text = `${page}::items`;

    if (country.country) {
      text += `::${country.country}::${country.name}`;
    } else {
      text += `::${country.name}`;
    }

    text += `::${cityName}`;

    return text;
  }

  updateMargin = _debounce(() => {
    if (this.hint.current) {
      this.setState({
        contentMargin: this.hint.current.offsetHeight,
      });
    }
  }, 10)

  render() {
    const {
      city,
      page,
      country,
    } = this.props;

    return (
      <div
        ref={this.hint}
        className={s.wrapper}
      >
        <Hint
          key={city.town__url_path}
          icon={ref => (
            <div ref={ref}>
              <Link
                to={`/${city.town__url_path}/`}
                key={city.town__url_path}
                data-weather={this.getTop100Attr(page, country, city)}
                className={s.hintLink}
              >
                <span className={s.citiesListItemTownTitle}>
                  {city.town__name}
                </span>
                <WeatherIcon
                  size={30}
                  type={city.icon_name}
                  className={s.citiesListItemTownIcon}
                />
                <span
                  className={s.citiesListItemTownTemperature}
                >
                  {city.temp < 0 ? `−${Math.abs(city.temp)}` : city.temp}&deg;
                </span>
              </Link>
            </div>
          )}
          className={s.citiesListItemTown}
          contentClassName={s.citiesListItemTownHint}
          autoPositionY
        >
          <div
            className={s.citiesListItemTownHintContent}
            style={{marginTop: this.state.contentMargin}}
          >
            Прогноз погоды на
            <div className={s.citiesListItemTownHintContentLinks}>
              {links.map(link => (
                <Link
                  to={`/${city.town__url_path}/${link.href}/`}
                  key={link.href}
                  className={s.citiesListItemTownHintContentLink}
                  data-weather={
                    `${this.getTop100Attr(page, country, city)}::popup_range_menu::${link.top100}`
                  }
                >
                  {link.title}
                </Link>
              ))}
            </div>
          </div>
        </Hint>
      </div>
    );
  }
}

export default withStyles(s)(CitiesHint);
