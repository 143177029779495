import {connect} from 'react-redux';

import getTableData from 'selectors/month/tableData';

import WeatherMonthTable from './index.js';

const mapStateToProps = state => ({
  weeks:          getTableData([...state.month.monthList]),
  currentDate:    state.month.currentDate,
  town:           state.town.town,
  page:           state.runtime.page,
  hasClimateData: state.month.hasClimateData,
});

export default connect(mapStateToProps)(WeatherMonthTable);
