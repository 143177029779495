import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';

import moment from 'moment';

import WeatherIcon from 'components/WeatherIcon';
import {WEEKDAYS_ABBR, MONTHS_GEN, MONTHS_ABBR_GEN} from 'constants/months';

import s from './index.scss';

class WeatherMonthNameTable extends React.Component {
  static propTypes = {
    weeks: pt.array,
    town:  pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }),
    page:      pt.string.isRequired,
    monthName: pt.string.isRequired,
  };

  static defaultProps = {
    weeks: [],
    town:  null,
  };

  getDayGuts = day => (
    <div className={s.tableCellInfo}>
      <WeatherIcon size={40} type={day.icon} className={s.tableCellIcon} />
      <div>
        <span className={s.tableCellTemperatureDay}>
          {day.day_temperature < 0 ? `−${Math.abs(day.day_temperature)}` : day.day_temperature}&deg;
        </span>
        <span className={s.tableCellTemperatureNight}>
          {day.night_temperature < 0 ? `−${Math.abs(day.night_temperature)}` : day.night_temperature}&deg;
        </span>
      </div>
      <div className={s.tableCellWind}>
        <WeatherIcon
          type="wind"
          size={15}
          className={cn(
            s.tableCellWindIcon,
            s[`iconWind--${day.wind_direction.toLowerCase()}`],
          )}
        />
        <span>{day.wind_speed} м/с</span>
      </div>
    </div>
  )

  getDay = (day, page) => {
    const {monthName} = this.props;
    const currentYear = moment().year();
    const dataDate = moment(day.date);
    const isMuted = !dataDate.isSame(moment(`1 ${monthName} ${currentYear}`), 'month');
    const isDayTemperatureSet = typeof day.day_temperature !== 'undefined';
    return (
      <div
        className={cn(
          s.tableLink,
          {[s.muted]: isMuted},
        )}
        data-weather={`${page}::calendar::nolink`}
      >
        <span className={s.tableCellDay}>
          <span className={s.tableCellDayDate}>
            <span className={s.tableCellDayNumber}>
              {new Date(day.date).getDate()}&nbsp;
            </span>
            {dataDate.date() === 1 &&
              <span>
                <span className={s.tableCellMonthWide}>{MONTHS_GEN[dataDate.month()]}</span>
                <span className={s.tableCellMonthNarrow}>{MONTHS_ABBR_GEN[dataDate.month()]}</span>
              </span>
            }
          </span>
        </span>
        {isDayTemperatureSet && this.getDayGuts(day)}
        {!isDayTemperatureSet && <span className={s.tableCellNoData}>Нет данных</span>}
      </div>
    );
  }

  renderDays = () => {
    const {
      weeks,
      page,
    } = this.props;
    if (!weeks.length) return null;

    return weeks.map((week, weekIndex) => (
      <div
        className={s.tableRow}
        // eslint-disable-next-line
        key={`monthWeek-${weekIndex}`}
      >
        {week.map((day, dayIndex) => (
          <div
            key={`dayWeek-${day ? day.date : dayIndex}`}
            className={cn(
              s.tableCell,
              {[s.tableHover]: day && day.detailed},
            )}
          >
            {day && day.date && this.getDay(day, page)}
          </div>
        ))}
      </div>
    ));
  }

  render() {
    return (
      <div className={s.root}>
        <div className={s.month}>
          <div className={s.title}><h2>Данные на основе статистики за 10 лет</h2></div>
          <div className={s.table}>
            <div className={s.tableHead}>
              {WEEKDAYS_ABBR.map(weekday => (
                <div
                  key={weekday}
                  className={s.tableHeader}
                >
                  {weekday}
                </div>
              ))}
            </div>
            <div className={s.tableBody}>
              {this.renderDays()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const WeatherMonthNameTableWithoutHocs = WeatherMonthNameTable;
export default withStyles(s)(WeatherMonthNameTable);
