import {connect} from 'react-redux';

import ChartGeomagneticAsTable from './index';

const mapStateToProps = state => ({
  data: state.geomagnetic.chartsData,
  town: state.town.town,
});

export default connect(mapStateToProps)(ChartGeomagneticAsTable);
