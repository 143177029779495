import MoonDay1Icon from './i/header_moon_day_1.svg';
import MoonDay2Icon from './i/header_moon_day_2.svg';
import MoonDay3Icon from './i/header_moon_day_3.svg';
import MoonDay4Icon from './i/header_moon_day_4.svg';
import MoonDay5Icon from './i/header_moon_day_5.svg';
import MoonDay6Icon from './i/header_moon_day_6.svg';
import MoonDay7Icon from './i/header_moon_day_7.svg';
import MoonDay8Icon from './i/header_moon_day_8.svg';
import MoonDay9Icon from './i/header_moon_day_9.svg';
import MoonDay10Icon from './i/header_moon_day_10.svg';
import MoonDay11Icon from './i/header_moon_day_11.svg';
import MoonDay12Icon from './i/header_moon_day_12.svg';
import MoonDay13Icon from './i/header_moon_day_13.svg';
import MoonDay14Icon from './i/header_moon_day_14.svg';
import MoonDay15Icon from './i/header_moon_day_15.svg';
import MoonDay16Icon from './i/header_moon_day_16.svg';
import MoonDay17Icon from './i/header_moon_day_17.svg';
import MoonDay18Icon from './i/header_moon_day_18.svg';
import MoonDay19Icon from './i/header_moon_day_19.svg';
import MoonDay20Icon from './i/header_moon_day_20.svg';
import MoonDay21Icon from './i/header_moon_day_21.svg';
import MoonDay22Icon from './i/header_moon_day_22.svg';
import MoonDay23Icon from './i/header_moon_day_23.svg';
import MoonDay24Icon from './i/header_moon_day_24.svg';
import MoonDay25Icon from './i/header_moon_day_25.svg';
import MoonDay26Icon from './i/header_moon_day_26.svg';
import MoonDay27Icon from './i/header_moon_day_27.svg';
import MoonDay28Icon from './i/header_moon_day_28.svg';
import MoonDay29Icon from './i/header_moon_day_29.svg';
import MoonDay30Icon from './i/header_moon_day_30.svg';

export default {
  moon_day_1:  MoonDay1Icon,
  moon_day_2:  MoonDay2Icon,
  moon_day_3:  MoonDay3Icon,
  moon_day_4:  MoonDay4Icon,
  moon_day_5:  MoonDay5Icon,
  moon_day_6:  MoonDay6Icon,
  moon_day_7:  MoonDay7Icon,
  moon_day_8:  MoonDay8Icon,
  moon_day_9:  MoonDay9Icon,
  moon_day_10: MoonDay10Icon,
  moon_day_11: MoonDay11Icon,
  moon_day_12: MoonDay12Icon,
  moon_day_13: MoonDay13Icon,
  moon_day_14: MoonDay14Icon,
  moon_day_15: MoonDay15Icon,
  moon_day_16: MoonDay16Icon,
  moon_day_17: MoonDay17Icon,
  moon_day_18: MoonDay18Icon,
  moon_day_19: MoonDay19Icon,
  moon_day_20: MoonDay20Icon,
  moon_day_21: MoonDay21Icon,
  moon_day_22: MoonDay22Icon,
  moon_day_23: MoonDay23Icon,
  moon_day_24: MoonDay24Icon,
  moon_day_25: MoonDay25Icon,
  moon_day_26: MoonDay26Icon,
  moon_day_27: MoonDay27Icon,
  moon_day_28: MoonDay28Icon,
  moon_day_29: MoonDay29Icon,
  moon_day_30: MoonDay30Icon,
};
