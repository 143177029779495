import {connect} from 'react-redux';
import Day from './index';

const mapStateToProps = state => ({
  windowType:        state.runtime.screen,
  screenTypeReady:   state.runtime.screenTypeReady,
  page:              state.runtime.page,
  tableData:         state.day.tableData,
  currentDay:        state.day.currentDay,
  weatherDetail:     state.day.day.detail,
  roadwayVisibility: state.day.day.roadwayVisibility,
});

export default connect(mapStateToProps)(Day);
