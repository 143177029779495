import {connect} from 'react-redux';
import NowBlock from './index';

const mapStateToProps = state => ({
  now:          state.now.now,
  periods:      state.now.periods,
  today:        state.now.today,
  town:         state.town.town,
  page:         state.runtime.page,
  isFirstVisit: state.runtime.pageNumber === 1,
});

export default connect(mapStateToProps)(NowBlock);

