import captureException from 'core/captureException';

// http://redux.js.org/docs/advanced/Middleware.html#seven-examples
const crashReporter = store => next => (action) => {
  try {
    return next(action);
  } catch (err) {
    captureException(err, {
      extra: {
        action,
        caller: 'Middleware<CrashReporter>',
        state:  store.getState(),
      },
    });
    throw err;
  }
};

export default crashReporter;
