import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import Link from 'components/Link';

import s from './index.scss';

class Countries extends React.Component {
  static propTypes = {
    countries: pt.objectOf(pt.arrayOf(pt.shape({
      name:          pt.string.isRequired,
      url_path:      pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
    })).isRequired).isRequired,
    page: pt.string.isRequired,
  }

  render() {
    const {
      countries,
      page,
    } = this.props;

    return (
      <div className={s.countries} data-block="weather_sbj">
        <h1 className={s.countriesTitle}>Погода в мире</h1>
        <div className={s.countriesList}>
          {countries && Object.keys(countries).map(letter => (
            <div className={s.countriesListItem} key={letter}>
              <h3 className={s.countriesListItemTitle}>{letter}</h3>
              <div className={s.countriesListItemCountries}>
                {countries[letter].map(item => (
                  <div className={s.countriesListItemCountryWrapper}>
                    <Link
                      to={`/${item.url_path}/`}
                      key={item.url_path}
                      className={s.countriesListItemCountry}
                      data-weather={`${page}::items::${item.name}`}
                    >
                      <span className={s.countriesListItemCountryTitle}>{item.name}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const CountriesWithoutHocs = Countries;
export default withStyles(s)(Countries);
