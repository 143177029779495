/* eslint-disable no-restricted-syntax */
import zoneColors from '../zoneColors.json';

const getZonesColors = (min, max, currentTab) => {
  let roundValue = 5;

  if (currentTab === 'precipitation') {
    roundValue = 0.5;
  } else if (currentTab === 'windSpeed' || currentTab === 'geomagnetic') {
    roundValue = 1;
  }

  // Round down the min and round up the max to the nearest multiple of "roundValue"
  const minRounded = currentTab === 'geomagnetic' ? min : Math.floor(min / roundValue) * roundValue;
  const maxRounded = currentTab === 'geomagnetic' ? max : Math.ceil(max / roundValue) * roundValue;


  let filteredData = [];
  const zoneColorsLength = zoneColors.length - 1;
  const returnedValue = currentTab === 'geomagnetic' ? 'staticColor' : 'color';

  /**
   * If minRounded is < first object's param and maxRounded >= last object's param,
   * return objects colors from first object to object with param equals to maxRounded
   */
  if (
    minRounded < zoneColors[0].value[currentTab]
    && maxRounded >= zoneColors[0].value[currentTab]
    && maxRounded <= zoneColors[zoneColorsLength].value[currentTab]
  ) {
    for (const obj of zoneColors) {
      if (obj.value[currentTab] === maxRounded) {
        filteredData.push(obj[returnedValue]);
        break;
      }

      if (obj.value[currentTab] >= minRounded && obj.value[currentTab] <= maxRounded) {
        filteredData.push(obj[returnedValue]);
      }
    }
  /**
   * If minRounded is < first object's param and maxRounded >= last object's param,
   * return all objects colors
   */
  } else if (
    minRounded < zoneColors[0].value[currentTab]
    && maxRounded >= zoneColors[zoneColorsLength].value[currentTab]
  ) {
    filteredData.push(...zoneColors.map(obj => obj[returnedValue]));
  /**
   * If minRounded is < first object's param and maxRounded <= first object's param,
   * return only the first object color
   */
  } else if (
    minRounded < zoneColors[0].value[currentTab]
    && maxRounded <= zoneColors[0].value[currentTab]
  ) {
    filteredData.push(zoneColors[0][returnedValue]);
  /**
   * If minRounded is >= first object's param and maxRounded >= last object's param,
   * return objects colors from object with precipitation equals to minRounded to last object
   */
  } else if (
    minRounded >= zoneColors[0].value[currentTab]
    && maxRounded >= zoneColors[zoneColorsLength].value[currentTab]
  ) {
    let shouldPush = false;
    for (const obj of zoneColors) {
      if (obj.value[currentTab] === minRounded) {
        shouldPush = true;
      }

      if (shouldPush) {
        filteredData.push(obj[returnedValue]);
      }
    }
  /**
   * If minRounded and maxRounded are both >= last object's param,
   * return only the last object color
   */
  } else if (
    minRounded >= zoneColors[zoneColorsLength].value[currentTab]
    && maxRounded >= zoneColors[zoneColorsLength].value[currentTab]
  ) {
    filteredData.push(zoneColors[zoneColorsLength][returnedValue]);
  /**
   * Return objects colors from minRounded to maxRounded in zoneColors
   */
  } else {
    filteredData = zoneColors
      .filter(
        ({value}) => value[currentTab] >= minRounded && value[currentTab] <= maxRounded,
      )
      .map(obj => obj[returnedValue]);
  }

  return filteredData;
};

export default getZonesColors;
