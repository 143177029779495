import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import NewChart from 'components/NewChart';

import s from './index.scss';

class WeatherMonthChart extends React.Component {
  static propTypes = {
    className:      pt.string,
    chartClassName: pt.string,
  }

  static defaultProps = {
    className:      '',
    chartClassName: '',
  }

  render() {
    const {
      className,
      chartClassName,
    } = this.props;

    return (
      <div className={cn(s.chart, className)} data-block="main">
        <NewChart rootClassName={chartClassName} />
      </div>
    );
  }
}

export const WeatherMonthChartWithoutHocs = WeatherMonthChart;
export default withStyles(s)(WeatherMonthChart);
