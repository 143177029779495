import React from 'react';

import {
  setRuntimeVariable,
  setRuntimeVariables,
} from 'actions/runtime';
import fetchCountriesData from 'actions/countries';

async function action({store, path}, params) {
  const geoid = +store.getState().runtime.geoid || null;
  const isMobile = store.getState().runtime.isMobile || false;

  store.dispatch(setRuntimeVariables({
    page:                  'list_cities',
    weatherText:           '',
    weatherAdditionalText: '',
    isNewDesign:           false,
  }));

  await store.dispatch(fetchCountriesData(geoid, params.country, params.region));

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let Cities;
  let StaticText;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    Cities = require('Desktop/pages/Countries/Cities').default;
    StaticText = require('Desktop/components/StaticText').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    Cities = require('Mobile/pages/Countries/Cities').default;
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.countries.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={path}>
        <Cities />
        {StaticText && <StaticText text={meta.seoText} />}
      </Layout>
    ),
  };
}

export default {
  path: '/:region',
  action,
};
