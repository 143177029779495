import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import Button from 'rambler-ui/Button';

import moment from 'moment';

import WeatherIcon from 'components/WeatherIcon';
import WeatherDetailParams from 'components/WeatherDetailParams';
import Link from 'components/Link';

import YandexLogo from 'components/YandexLogo';

import s from './index.scss';

class WeatherDayCards extends React.Component {
  static propTypes = {
    page:       pt.string.isRequired,
    currentDay: pt.string.isRequired,
    town:       pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    data: pt.array,
  };

  static defaultProps = {
    data: null,
  }

  getDateState = (currentDay, dataDay) => {
    const currentDate = moment(currentDay);
    const dataDate = moment(dataDay);
    let state = '';

    if (currentDate.isSame(dataDate, 'day')) {
      state = 'today';
    } else if (currentDate.add(1, 'days').isSame(dataDate, 'day')) {
      state = 'tomorrow';
    } else {
      state = 'date';
    }

    return state;
  }

  getLink = (currentDay, dataDay) => {
    const dateState = this.getDateState(currentDay, dataDay);
    const dataDate = moment(dataDay);

    let link = dateState;

    if (dateState === 'date') {
      link = dataDate.locale('en').format('D-MMMM').toLowerCase();
    }

    return link;
  }

  getDayTitle = (currentDay, dataDay) => {
    const dateState = this.getDateState(currentDay, dataDay);
    const dataDate = moment(dataDay);
    let title = '';

    if (dateState === 'today') {
      title = `Сегодня, ${dataDate.format('D MMMM')}`;
    } else if (dateState === 'tomorrow') {
      title = `Завтра, ${dataDate.format('D MMMM')}`;
    } else {
      title = dataDate.format('dddd, D MMMM');
    }

    return title;
  }

  render() {
    const {
      page,
      town,
      currentDay,
      data,
    } = this.props;

    if (!data) return null;

    return (
      <div className={s.root}>
        {data.map((day, dayIndex) => {
          const key = `${page}-WeatherDayCards-day-${dayIndex}`;
          const title = this.getDayTitle(currentDay, day.date);

          return (
            <div className={s.day} key={key} data-block={`main_${dayIndex + 1}`}>
              <span className={s.dayTitle}>{title}</span>
              <div className={s.dayPeriods}>
                {day.periods.map((period, periodIndex) => {
                  const periodKey = `${key}-period-${periodIndex}`;

                  return (
                    <div
                      className={s.dayPeriodsItem}
                      key={periodKey}
                    >
                      <span className={s.dayPeriodsTitle}>{period.name}</span>
                      <div className={s.dayPeriodsParameters}>
                        <WeatherIcon
                          size={40}
                          type={period.icon}
                          className={s.dayPeriodsIcon}
                        />
                        <span
                          className={s.dayPeriodsTemperature}
                        >
                          {period.temperature < 0 ? `−${Math.abs(period.temperature)}` : period.temperature}&deg;
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <WeatherDetailParams
                className={s.params}
                data={day.params}
                alwaysShowPrecipitationProbability
              />
              <Button
                className={s.button}
                size="small"
                type="outline"
                iconPosition="right"
                container={
                  <Link
                    to={`/${town.url_path}/${this.getLink(currentDay, day.date)}/`}
                    data-weather={`${page}::day_cards::day_${dayIndex + 1}`}
                  />
                }
              >
                Подробно
              </Button>
              {dayIndex === 0 && <YandexLogo isAbsolute />}
            </div>
          );
        })}
      </div>
    );
  }
}

export const WeatherDayCardsWithoutHocs = WeatherDayCards;
export default withStyles(s)(WeatherDayCards);
