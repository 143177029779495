import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import {
  AD_SUPERFOOTER_ID,
  AD_CONTENT_1_ID,
} from 'constants/ad';
import {MONTHS_PREP, MONTHS_ENG, MONTHS_ABBR} from 'constants/months';

import getDateRange from 'core/getDateRange';

import Link from 'components/Link';
import Ad from 'components/Ad';
import WeatherMonthNameChart from 'components/WeatherMonthNameChart';
import GeoModal from 'components/GeoModal';

import YandexLogo from 'components/YandexLogo';

import ChangeUserGeo from 'Desktop/components/ChangeUserGeo';
import ChangeCity from 'Desktop/components/ChangeCity';
import WeatherMonthNameTable from 'Desktop/components/WeatherMonthNameTable';
import WeatherReport from 'Desktop/components/WeatherReport';
import ChartMonthAsTable from 'Desktop/components/ChartMonthAsTable';
import ResortCitiesWeather from 'Desktop/components/ResortCitiesWeather';
import NearestCities from 'Desktop/components/NearestCities';

import s from './index.scss';

class MonthNamePage extends React.Component {
  static propTypes = {
    town: pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    windowType:        pt.string.isRequired,
    screenTypeReady:   pt.bool.isRequired,
    monthName:         pt.string.isRequired,
    page:              pt.string.isRequired,
    chunkedChartsData: pt.array,
    currentDate:       pt.string.isRequired,
  }

  static defaultProps = {
    chunkedChartsData: null,
  }

  render() {
    const {
      town,
      windowType,
      screenTypeReady,
      monthName,
      page,
      chunkedChartsData,
      currentDate,
    } = this.props;

    const monthIndex = MONTHS_ENG.indexOf(monthName);
    const month = MONTHS_PREP[monthIndex];
    const currentYear = new Date().getFullYear();
    const {todayText, monthFromTodayText} = getDateRange(currentDate);

    const hasDataInChunkedChartsData = chunkedChartsData && chunkedChartsData[0].length > 0;

    return (
      <div className={s.month}>
        <div className={s.monthBlock}>
          <div className={s.monthHead}>
            <div className={s.monthTop}>
              <h1 className={s.monthTitle}>{`Погода ${town.loc_case_name} в ${month} ${currentYear}`}</h1>
              <ChangeUserGeo />
            </div>
            <div className={s.monthLocationContainer}>
              <ChangeCity className={s.monthLocation} />
            </div>
            <div className={s.monthDates}>
              <Link
                className={s.monthDatesItem}
                to={`/${town.url_path}/30-days/`}
                data-weather={`${page}::30days`}
              >
                {todayText} – {monthFromTodayText}
              </Link>
              {MONTHS_ABBR.map((item, i) => {
                const link = `/${town.url_path}/${MONTHS_ENG[i]}/`;

                return (
                  <Link
                    to={link}
                    key={link}
                    data-weather={`${page}::month_change::${i + 1}`}
                    className={cn(s.monthDatesItem, monthIndex === i && s.monthDatesItemActive)}
                  >
                    {item}
                  </Link>
                );
              })}
            </div>
          </div>
          {hasDataInChunkedChartsData &&
            <noscript
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `<style>
                  .${s.monthChart} {display: none}
                  .${s.monthNoJsTable} {
                    visibility: visible;
                    height: auto;
                    min-height: 200px !important;
                    padding-top: 20px !important;
                  }
                  .${s.monthBlock} {
                    height: auto;
                  }
                </style>`,
              }}
            />
          }
          <WeatherMonthNameChart className={s.monthChart} />
          {hasDataInChunkedChartsData &&
            chunkedChartsData.map((data, index) => {
              const key = `ChartMonthAsTable-chunk${index}`;

              return (
                <ChartMonthAsTable
                  className={s.monthNoJsTable}
                  chartsData={data}
                  key={key}
                />
              );
            })
          }
          <YandexLogo
            underChart
            withBottomPadding
          />
        </div>
        <WeatherReport />
        <WeatherMonthNameTable />
        <NearestCities />
        {windowType !== 'mobile' && screenTypeReady &&
          <Ad
            id={AD_SUPERFOOTER_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isLazy
          />
        }
        {windowType === 'mobile' && screenTypeReady &&
          <Ad
            id={AD_CONTENT_1_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isMobInDesk
            isLazy
          />
        }
        <ResortCitiesWeather />

        <GeoModal />
      </div>
    );
  }
}

export const MonthNameWithoutHocs = MonthNamePage;
export default withStyles(s)(MonthNamePage);
