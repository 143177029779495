import {connect} from 'react-redux';
import SimpleNowBlock from './index';

const mapStateToProps = ({runtime, now, town}) => ({
  isNewDesign:  runtime.isNewDesign,
  now:          now.now,
  town:         town.town,
  page:         runtime.page,
  isFirstVisit: runtime.pageNumber === 1,
});

export default connect(mapStateToProps)(SimpleNowBlock);

