import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import Link from 'components/Link';
import {MONTHS_PREP, MONTHS_ENG} from 'constants/months';

import s from './index.scss';

class SimplePopularRequests extends React.Component {
  static propTypes = {
    isNewDesign: pt.bool.isRequired,
    page:        pt.string.isRequired,
    town:        pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
  }

  getLinks = () => {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const {town} = this.props;

    return [{
      title: `Погода ${town.loc_case_name} сегодня`,
      url:   `/${town.url_path}/today/`,
    }, {
      title: `Погода ${town.loc_case_name} на завтра`,
      url:   `/${town.url_path}/tomorrow/`,
    }, {
      title: `Погода ${town.loc_case_name} в ${MONTHS_PREP[month]} ${year}`,
      url:   `/${town.url_path}/${MONTHS_ENG[month]}/`,
    }, {
      title: `Погода ${town.loc_case_name} на выходные`,
      url:   `/${town.url_path}/weekend/`,
    }, {
      title: `Погода ${town.loc_case_name} на неделю`,
      url:   `/${town.url_path}/7-days/`,
    }];
  }

  render() {
    const {
      isNewDesign,
      page,
    } = this.props;

    return (
      <div
        className={cn(s.popular, isNewDesign ? s.popularNewDesign : s.popularOldDesign)}
        data-block="popular"
      >
        <div className={cn(
          s.popularTitle,
          isNewDesign ? s.popularTitleNewDesign : s.popularTitleOldDesign,
        )}
        >
          Популярные запросы
        </div>
        <div className={cn(
          s.popularList,
          isNewDesign ? s.popularListNewDesign : s.popularListOldDesign,
        )}
        >
          {this.getLinks().map((item, index) => (
            <div
              className={cn(
                s.popularListItemWrapper,
                isNewDesign ? s.popularListItemWrapperNewDesign : s.popularListItemWrapperOldDesign,
              )}
              key={item.url}
            >
              <Link
                to={item.url}
                className={cn(
                  s.popularListItem,
                  isNewDesign ? s.popularListItemNewDesign : s.popularListItemOldDesign,
                )}
                data-weather={`${page}::link_now_requests::links::${index + 1}`}
                title={item.title}
              >
                {item.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const SimplePopularRequestsWithoutHocs = SimplePopularRequests;
export default withStyles(s)(SimplePopularRequests);
