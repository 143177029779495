import {connect} from 'react-redux';
import WeatherDetailParams from './index.js';

const mapStateToProps = ({runtime, town}) => ({
  isNewDesign: runtime.isNewDesign,
  page:        runtime.page,
  isMobile:    runtime.isMobile,
  town:        town.town,
});

export default connect(mapStateToProps)(WeatherDetailParams);
