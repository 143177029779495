import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import equal from 'deep-equal';

import Link from 'components/Link';

import s from './index.scss';

class BreadcrumbsNewDesign extends React.Component {
  static propTypes = {
    isMobile:    pt.bool.isRequired,
    page:        pt.string.isRequired,
    breadcrumbs: pt.arrayOf(pt.shape({
      name: pt.string.isRequired,
      href: pt.string,
    })),
    isWithMicrodata: pt.bool,
    isNewDesign:     pt.bool.isRequired,
  };

  static defaultProps = {
    breadcrumbs:     [],
    isWithMicrodata: false,
  };

  shouldComponentUpdate(nextProps) {
    return !equal(this.props.breadcrumbs, nextProps.breadcrumbs, {strict: true});
  }

  render() {
    const {
      isMobile,
      page,
      breadcrumbs,
      isWithMicrodata,
      isNewDesign,
    } = this.props;

    let top100Text = `${page}::breadcrumbs::world`;
    let microData = {
      breadcrumbList: {},
      listItem:       {},
      thing:          {},
      name:           {},
      position:       {},
    };
    if (isWithMicrodata) {
      microData = {
        breadcrumbList: {
          itemScope: true,
          itemType:  'http://schema.org/BreadcrumbList',
        },
        listItem: {
          itemProp:  'itemListElement',
          itemScope: true,
          itemType:  'https://schema.org/ListItem',
        },
        thing: {
          itemType: 'https://schema.org/Thing',
          itemProp: 'item',
        },
        name: {
          itemProp: 'name',
        },
        position: {
          itemProp: 'position',
        },
      };
    }

    return (
      <div
        className={cn(
          s.root,
          isMobile ? s.rootMobile : s.rootDesktop,
          isMobile && !isNewDesign && s.rootMobileNotNewDesign,
        )}
        data-block="crumbs"
      >
        <div
          className={cn(
            s.container,
            isMobile ? s.containerMobile : s.containerDesktop,
          )}
          {...microData.breadcrumbList}
        >
          {breadcrumbs.map((breadrumb, i) => {
            const link = breadrumb.href;
            const key = `breadrumb-${i}`;

            if (link) {
              if (i > 0) {
                top100Text += `::${breadrumb.name}`;
              }

              return (
                <div
                  key={key}
                  {...microData.listItem}
                >
                  <Link
                    className={cn(
                      s.menuLink,
                      isMobile ? s.menuLinkMobile : s.menuLinkDesktop,
                      isMobile && !isNewDesign && s.menuLinkNotNewDesign,
                    )}
                    to={`/${link}/`}
                    data-weather={top100Text}
                    {...microData.thing}
                  >
                    <span {...microData.name}>{breadrumb.name}</span>
                  </Link>
                  <meta {...microData.position} content={i + 1} />
                </div>
              );
            }

            return (
              <span
                className={cn(
                  s.menuLink,
                  isMobile ? s.menuLinkMobile : s.menuLinkDesktop,
                  isMobile && !isNewDesign && s.menuLinkNotNewDesign,
                )}
                key={key}
                {...microData.name}
              >
                {breadrumb.name}
                <meta {...microData.position} content={i + 1} />
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

export const BreadcrumbsNewDesignWithoutHocs = BreadcrumbsNewDesign;
export default withStyles(s)(BreadcrumbsNewDesign);
