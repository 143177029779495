import {incrementRuntimeVariable, setRuntimeVariable} from 'actions/runtime';
import {brandingCleaner} from 'core/brandingCleaner';

import home from './home';
import city from './city/city';
import locations from './locations/locations';
import notFound from './notFound';

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    home,
    ...(
      __DESKTOP__
        ? [
          /* eslint-disable global-require */
          require('./redirects').default,
          /* eslint-enable global-require */
        ]
        : []
    ),
    city,
    locations,

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    notFound,
  ],

  async action(ctx) {
    const {next, store} = ctx;
    const {pageIsLoaded} = store.getState().runtime;

    const route = await next();

    if (route) {
      // Provide default values for title, description etc.
      route.title = `${route.title || (route.meta && route.meta.title) || 'Untitled Page'}`;
      route.description = route.description || (route.meta && route.meta.description) || '';
      route.state = store.getState();
    }

    if (__CLIENT__ && pageIsLoaded) {
      await store.dispatch(setRuntimeVariable({
        name:  'routingEnded',
        value: false,
      }));

      brandingCleaner();
      await store.dispatch(incrementRuntimeVariable('pageNumber'));
    }

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    /* eslint-disable global-require */
    error: require('./error').default,
    /* eslint-enable global-require */
  });
}

export default routes;
