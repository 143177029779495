import {connect} from 'react-redux';

import getMonthNoJSData from 'selectors/month/noJSData';

import MonthName from './index';

const mapStateToProps = state => ({
  page:              state.runtime.page,
  windowType:        state.runtime.screen,
  screenTypeReady:   state.runtime.screenTypeReady,
  monthName:         state.monthName.monthName,
  currentDate:       state.monthName.currentDate,
  chunkedChartsData: getMonthNoJSData(state.monthName.monthList),
  town:              state.town.town,
});

export default connect(mapStateToProps)(MonthName);
