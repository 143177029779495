import React, {Fragment} from 'react';
import * as pt from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/withStyles';

import {AD_TOPLINE_BANNER_ID} from 'constants/ad';

import Ad from 'components/Ad';
import Link from 'components/Link';

import Topline from '@rambler-components/topline';
import toplineStyles from '@rambler-components/topline/styles.css';
import Header from '@rambler-components/header';
import headerStyles from '@rambler-components/header/styles.css';

import top100Wrapper from 'core/top100Wrapper';

import icons from './icons';
import SunriseIcon from './i/sunrise.svg';
import SunsetIcon from './i/sunset.svg';

import s from './index.scss';

class TopPartComponent extends React.Component {
  static propTypes = {
    page:                  pt.string.isRequired,
    header:                pt.object.isRequired,
    toggleModalVisibility: pt.func.isRequired,
    reloadGeoKey:          pt.number.isRequired,
    isNeedBanner:          pt.bool,
    urlPath:               pt.string.isRequired,
  }

  static defaultProps = {
    isNeedBanner: false,
  }

  state = {
    userFetched: false,
    userProfile: null,
  }

  onUserId = (userId) => {
    top100Wrapper.sendUserId(userId);
  }

  onUserFetched = () => {
    this.setState({userFetched: true});
  }

  onUserInfo = (userInfo) => {
    this.setState({userProfile: userInfo});
  }

  render() {
    const {
      isNeedBanner,
      header,
      page,
      reloadGeoKey,
      toggleModalVisibility,
      urlPath,
    } = this.props;

    const withIcon = Boolean(header && header.icon);

    const Element = withIcon ? icons[header.icon] : Fragment;

    return (
      <Fragment>
        <Topline
          zIndex={1001}
          projectCode="weather"
          scrollSticky
          redirectToMain={false}
          promoAd={isNeedBanner ? <Ad id={AD_TOPLINE_BANNER_ID} /> : null}
          onUserFetched={this.onUserFetched}
          onUserId={this.onUserId}
          onUserInfo={this.onUserInfo}
          onOpenGeo={() => toggleModalVisibility(true)}
          breakpointsClassName={s.breakpoints}
          reloadGeoKey={reloadGeoKey}
        />
        <div className={s.headerWrapper}>
          <Header
            projectCode="weather"
            userFetched={this.state.userFetched}
            userProfile={this.state.userProfile}
            breakpointsClassName={s.breakpoints}
            withoutShadow
          >
            <Fragment>
              <a
                href="https://horoscopes.rambler.ru/moon/calendar/"
                target="_blank"
                className={s.moonContainer}
                data-weather={`${page}::header_moon`}
                title={header.title}
              >
                <span className={s.moon}>
                  <span className={s.moonIconContainer}>
                    {withIcon && <Element className={s.moonIcon} />}
                  </span> <span className={s.moonText}>{header.title}</span>
                </span>
              </a>
              <Link
                to={`/${urlPath}/today/`}
                className={s.statusContainer}
                data-weather={`${page}::header_sunrise_sunset`}
              >
                <span className={s.status} title="Восход">
                  <SunriseIcon className={s.icon} /> {moment(header.sunrise).format('HH:mm')}
                </span>
                <span className={s.status} title="Закат">
                  <SunsetIcon className={s.icon} /> {moment(header.sunset).format('HH:mm')}
                </span>
              </Link>
            </Fragment>
          </Header>
        </div>
      </Fragment>
    );
  }
}

export const TopPartWithoutHocs = TopPartComponent;
export default withStyles(s, toplineStyles, headerStyles)(TopPartComponent);
