import clear100 from './i/OldDesign/icons/100/clear.svg';
import clearNight100 from './i/OldDesign/icons/100/clear-night.svg';
import cloudy100 from './i/OldDesign/icons/100/cloudy.svg';
import fog100 from './i/OldDesign/icons/100/fog.svg';
import lightRain100 from './i/OldDesign/icons/100/light-rain.svg';
import lightRainNight100 from './i/OldDesign/icons/100/light-rain-night.svg';
import occRain100 from './i/OldDesign/icons/100/occ-rain.svg';
import occSnow100 from './i/OldDesign/icons/100/occ-snow.svg';
import lightSnowNight100 from './i/OldDesign/icons/100/light-snow-night.svg';
import partlyCloudy100 from './i/OldDesign/icons/100/partly-cloudy.svg';
import partlyCloudyNight100 from './i/OldDesign/icons/100/partly-cloudy-night.svg';
import rain100 from './i/OldDesign/icons/100/rain.svg';
import rainNight100 from './i/OldDesign/icons/100/rain-night.svg';
import sleet100 from './i/OldDesign/icons/100/sleet.svg';
import snow100 from './i/OldDesign/icons/100/snow.svg';
import snowNight100 from './i/OldDesign/icons/100/snow-night.svg';
import thunder100 from './i/OldDesign/icons/100/thunder.svg';

import clear40 from './i/OldDesign/icons/40/clear.svg';
import clearNight40 from './i/OldDesign/icons/40/clear-night.svg';
import cloudy40 from './i/OldDesign/icons/40/cloudy.svg';
import fog40 from './i/OldDesign/icons/40/fog.svg';
import lightRain40 from './i/OldDesign/icons/40/light-rain.svg';
import lightRainNight40 from './i/OldDesign/icons/40/light-rain-night.svg';
import occRain40 from './i/OldDesign/icons/40/occ-rain.svg';
import occSnow40 from './i/OldDesign/icons/40/occ-snow.svg';
import lightSnowNight40 from './i/OldDesign/icons/40/light-snow-night.svg';
import partlyCloudy40 from './i/OldDesign/icons/40/partly-cloudy.svg';
import partlyCloudyNight40 from './i/OldDesign/icons/40/partly-cloudy-night.svg';
import rain40 from './i/OldDesign/icons/40/rain.svg';
import rainNight40 from './i/OldDesign/icons/40/rain-night.svg';
import sleet40 from './i/OldDesign/icons/40/sleet.svg';
import snow40 from './i/OldDesign/icons/40/snow.svg';
import snowNight40 from './i/OldDesign/icons/40/snow-night.svg';
import thunder40 from './i/OldDesign/icons/40/thunder.svg';

import clear30 from './i/OldDesign/icons/30/clear.svg';
import clearNight30 from './i/OldDesign/icons/30/clear-night.svg';
import cloudy30 from './i/OldDesign/icons/30/cloudy.svg';
import fog30 from './i/OldDesign/icons/30/fog.svg';
import lightRain30 from './i/OldDesign/icons/30/light-rain.svg';
import lightRainNight30 from './i/OldDesign/icons/30/light-rain-night.svg';
import occRain30 from './i/OldDesign/icons/30/occ-rain.svg';
import occSnow30 from './i/OldDesign/icons/30/occ-snow.svg';
import lightSnowNight30 from './i/OldDesign/icons/30/light-snow-night.svg';
import partlyCloudy30 from './i/OldDesign/icons/30/partly-cloudy.svg';
import partlyCloudyNight30 from './i/OldDesign/icons/30/partly-cloudy-night.svg';
import rain30 from './i/OldDesign/icons/30/rain.svg';
import rainNight30 from './i/OldDesign/icons/30/rain-night.svg';
import sleet30 from './i/OldDesign/icons/30/sleet.svg';
import snow30 from './i/OldDesign/icons/30/snow.svg';
import snowNight30 from './i/OldDesign/icons/30/snow-night.svg';
import thunder30 from './i/OldDesign/icons/30/thunder.svg';

import clear20 from './i/OldDesign/icons/20/clear.svg';
import clearNight20 from './i/OldDesign/icons/20/clear-night.svg';
import cloudy20 from './i/OldDesign/icons/20/cloudy.svg';
import fog20 from './i/OldDesign/icons/20/fog.svg';
import lightRain20 from './i/OldDesign/icons/20/light-rain.svg';
import lightRainNight20 from './i/OldDesign/icons/20/light-rain-night.svg';
import occRain20 from './i/OldDesign/icons/20/occ-rain.svg';
import occSnow20 from './i/OldDesign/icons/20/occ-snow.svg';
import lightSnowNight20 from './i/OldDesign/icons/20/light-snow-night.svg';
import partlyCloudy20 from './i/OldDesign/icons/20/partly-cloudy.svg';
import partlyCloudyNight20 from './i/OldDesign/icons/20/partly-cloudy-night.svg';
import rain20 from './i/OldDesign/icons/20/rain.svg';
import rainNight20 from './i/OldDesign/icons/20/rain-night.svg';
import sleet20 from './i/OldDesign/icons/20/sleet.svg';
import snow20 from './i/OldDesign/icons/20/snow.svg';
import snowNight20 from './i/OldDesign/icons/20/snow-night.svg';
import thunder20 from './i/OldDesign/icons/20/thunder.svg';

import geo from './i/OldDesign/alerts/weather-alert-geo.svg';
import sleet from './i/OldDesign/alerts/weather-alert-sleet.svg';
import snow from './i/OldDesign/alerts/weather-alert-snow.svg';
import storm from './i/OldDesign/alerts/weather-alert-storm.svg';
import thunderbolt from './i/OldDesign/alerts/weather-alert-thunderbolt.svg';
import wind20 from './i/OldDesign/wind/weather-wind-20x20.svg';
import wind15 from './i/OldDesign/wind/weather-wind-15x15.svg';

export default {
  100: {
    clear:                 clear100,
    'clear-night':         clearNight100,
    cloudy:                cloudy100,
    fog:                   fog100,
    'light-rain':          lightRain100,
    'light-rain-night':    lightRainNight100,
    'occ-rain':            occRain100,
    'occ-snow':            occSnow100,
    'light-snow-night':    lightSnowNight100,
    'partly-cloudy':       partlyCloudy100,
    'partly-cloudy-night': partlyCloudyNight100,
    rain:                  rain100,
    'rain-night':          rainNight100,
    sleet:                 sleet100,
    snow:                  snow100,
    'snow-night':          snowNight100,
    thunder:               thunder100,
  },
  //
  40: {
    clear:                 clear40,
    'clear-night':         clearNight40,
    cloudy:                cloudy40,
    fog:                   fog40,
    'light-rain':          lightRain40,
    'light-rain-night':    lightRainNight40,
    'occ-rain':            occRain40,
    'occ-snow':            occSnow40,
    'light-snow-night':    lightSnowNight40,
    'partly-cloudy':       partlyCloudy40,
    'partly-cloudy-night': partlyCloudyNight40,
    rain:                  rain40,
    'rain-night':          rainNight40,
    sleet:                 sleet40,
    snow:                  snow40,
    'snow-night':          snowNight40,
    thunder:               thunder40,
  },
  //
  30: {
    clear:                 clear30,
    'clear-night':         clearNight30,
    cloudy:                cloudy30,
    fog:                   fog30,
    'light-rain':          lightRain30,
    'light-rain-night':    lightRainNight30,
    'occ-rain':            occRain30,
    'occ-snow':            occSnow30,
    'light-snow-night':    lightSnowNight30,
    'partly-cloudy':       partlyCloudy30,
    'partly-cloudy-night': partlyCloudyNight30,
    rain:                  rain30,
    'rain-night':          rainNight30,
    sleet:                 sleet30,
    snow:                  snow30,
    'snow-night':          snowNight30,
    thunder:               thunder30,
  },
  //
  20: {
    clear:                 clear20,
    'clear-night':         clearNight20,
    cloudy:                cloudy20,
    fog:                   fog20,
    'light-rain':          lightRain20,
    'light-rain-night':    lightRainNight20,
    'occ-rain':            occRain20,
    'occ-snow':            occSnow20,
    'light-snow-night':    lightSnowNight20,
    'partly-cloudy':       partlyCloudy20,
    'partly-cloudy-night': partlyCloudyNight20,
    rain:                  rain20,
    'rain-night':          rainNight20,
    sleet:                 sleet20,
    snow:                  snow20,
    'snow-night':          snowNight20,
    thunder:               thunder20,
    wind:                  wind20,
  },
  //
  15: {
    geo,
    sleet,
    snow,
    storm,
    thunderbolt,
    wind: wind15,
  },
};
