import {connect} from 'react-redux';
import SimplePopularRequests from './index';

const mapStateToProps = ({runtime, town}) => ({
  isNewDesign: runtime.isNewDesign,
  page:        runtime.page,
  town:        town.town,
});

export default connect(mapStateToProps)(SimplePopularRequests);
