import {connect} from 'react-redux';

import setUIState from 'actions/ui';

import ChangeCity from './index.js';

const mapStateToProps = ({runtime, ui, town}) => ({
  page:            runtime.page,
  isNewDesign:     runtime.isNewDesign,
  isSearchVisible: ui.geoSearch.isSearchVisible,
  town:            town.town,
});

const mapDispatchToProps = dispatch => ({
  toggleSearchVisibility(value) {
    dispatch(setUIState({
      component: 'geoSearch',
      name:      'isSearchVisible',
      value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCity);
