import {connect} from 'react-redux';

import WeatherWeek from './index.js';

const mapStateToProps = ({runtime, home, town}) => ({
  page:         runtime.page,
  isFirstVisit: runtime.pageNumber === 1,
  data:         home.rangeWeather,
  url:          town.town ? town.town.url_path : '',
});

export default connect(mapStateToProps)(WeatherWeek);
