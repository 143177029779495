import {
  FETCH_NOW_DATA_SUCCESS,
  FETCH_NOW_DATA_FAILURE,
} from 'constants/actions/now';

import {FETCH_TOWN_DATA_SUCCESS} from 'constants/actions/town';
import {FETCH_NEAREST_CITIES_DATA_SUCCESS} from 'constants/actions/cities';
import FETCH_SEO_DATA_SUCCESS from 'constants/actions/seo';
import FETCH_BREADCRUMBS_DATA_SUCCESS from 'constants/actions/breadcrumbs';
import {SET_RUNTIME_VARIABLE} from 'constants/redux/index';

import {
  DEFAULT_API_URL,
  NOW_URL,
} from 'constants/index';

import captureException from 'core/captureException';

export default function fetchNowData(geoID, urlPath, onlyCurrent) {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      let url = `${DEFAULT_API_URL}${NOW_URL}`;

      if (geoID && !urlPath) {
        url += `?geo_id=${geoID}${onlyCurrent ? '&only_current=1' : ''}`;
      } else if ((geoID && urlPath) || (!geoID && urlPath)) {
        url += `?url_path=${urlPath}${onlyCurrent ? '&only_current=1' : ''}`;
      } else if (onlyCurrent) {
        url += '?only_current=1';
      }

      const req = await fetch(url);
      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось получить данные о погоде на сейчас: ${url}`);

        err.status = req.status;
        throw err;
      }

      if (__SERVER__) {
        statsd.timing('api_request.fetchNowData.timer', startTime);
        statsd.timing('api_request.fetchGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.fetchNowData.timer', startTime);
      }

      await dispatch({
        type:    FETCH_NOW_DATA_SUCCESS,
        payload: {data},
      });
      if (!onlyCurrent) {
        await dispatch({
          type:    FETCH_TOWN_DATA_SUCCESS,
          payload: {town: data.town},
        });
        await dispatch({
          type:    FETCH_NEAREST_CITIES_DATA_SUCCESS,
          payload: {cities: data.cities_nearby},
        });
        await dispatch({
          type:    FETCH_SEO_DATA_SUCCESS,
          payload: {seo: data.seo},
        });
        await dispatch({
          type:    FETCH_BREADCRUMBS_DATA_SUCCESS,
          payload: {breadcrumbs: data.breadcrumbs, isWithMicrodata: true},
        });
        await dispatch({
          type:    SET_RUNTIME_VARIABLE,
          payload: {
            name:  'warning',
            value: data.current_weather.alert_text_short || '',
          },
        });
      }
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.fetchNowData.counter');
      }

      await dispatch({
        type:    FETCH_NOW_DATA_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}
