import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import {scroller} from 'react-scroll';

import {
  AD_SUPERFOOTER_ID,
  AD_LISTING_1_ID,
} from 'constants/ad';

import Ad from 'components/Ad';
import GeoModal from 'components/GeoModal';

import YandexLogo from 'components/YandexLogo';

import ChangeUserGeo from 'Desktop/components/ChangeUserGeo';
import ChangeCity from 'Desktop/components/ChangeCity';
import CitiesWeather from 'Desktop/components/CitiesWeather';
import NearestCities from 'Desktop/components/NearestCities';
import WeatherDayLines from 'Desktop/components/WeatherDayLines';
import WeatherDayCards from 'Desktop/components/WeatherDayCards';
import TableDataBlock from 'Desktop/components/TableDataBlock';

import s from './index.scss';

class Weekend extends React.Component {
  static propTypes = {
    town: pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    windowType:      pt.string.isRequired,
    screenTypeReady: pt.bool.isRequired,
    rangeData:       pt.array,
    tableData:       pt.array,
    currentDay:      pt.string,
  }

  static defaultProps = {
    rangeData:  null,
    tableData:  null,
    currentDay: null,
  }

  scrollToTable = () => {
    scroller.scrollTo('table', {
      duration: 250,
      smooth:   true,
    });
  }

  render() {
    const {
      town,
      windowType,
      screenTypeReady,
      rangeData,
      tableData,
      currentDay,
    } = this.props;

    return (
      <div className={s.weekend}>
        <div className={s.weekendBlock}>
          <div className={s.weekendTop}>
            <h1 className={s.weekendTitle}>{`Погода на выходные ${town.loc_case_name}`}</h1>
            <ChangeUserGeo />
          </div>
          <ChangeCity className={s.weekendLocation} />
          <WeatherDayLines
            currentDay={currentDay}
            rangeData={rangeData}
            tableData={tableData}
          />
          <YandexLogo className={s.yandex} />
        </div>
        <WeatherDayCards
          currentDay={currentDay}
          rangeData={rangeData}
          tableData={tableData}
        />
        {windowType !== 'mobile' && screenTypeReady &&
          <Ad
            id={AD_SUPERFOOTER_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isLazy
          />
        }
        {windowType === 'mobile' && screenTypeReady &&
          <Ad
            id={AD_LISTING_1_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isMobInDesk
            isLazy
          />
        }
        <TableDataBlock
          period="weekend"
          tableData={tableData}
          currentDay={currentDay}
        />
        <NearestCities />
        <CitiesWeather />

        <GeoModal />
      </div>
    );
  }
}

export const WeekendWithoutHocs = Weekend;
export default withStyles(s)(Weekend);
