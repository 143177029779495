import {
  FETCH_MONTH_NAME_DATA_SUCCESS,
  FETCH_MONTH_NAME_DATA_FAILURE,
} from 'constants/actions/monthName';

export default function monthName(state = {}, action) {
  switch (action.type) {
    case FETCH_MONTH_NAME_DATA_SUCCESS: {
      const {data} = action.payload;

      return {
        ...state,
        isDataFetched: true,
        monthList:     data.range_weather,
        monthStat:     {
          rainyDays:     data.month_stat.rainy_days,
          sunnyDays:     data.month_stat.sunny_days,
          overcastDays:  data.month_stat.overcast_days,
          weatherDetail: {
            precipitation:  data.month_stat.prec_mm,
            windSpeed:      data.month_stat.wind_speed,
            minTemperature: data.month_stat.min_night_t,
            maxTemperature: data.month_stat.max_day_t,
            humidity:       data.month_stat.humidity,
            pressure:       data.month_stat.pressure_mm,
          },
        },
        monthName:   action.payload.monthName,
        currentDate: data.local_now,
        town:        data.town,
        error:       null,
      };
    }
    case FETCH_MONTH_NAME_DATA_FAILURE:
      return {
        ...state,
        isDataFetched: false,
        error:         action.payload,
        data:          {},
      };
    default:
      return state;
  }
}
