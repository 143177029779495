import {
  FETCH_WEATHER_CITIES,
  FETCH_WEATHER_CITIES_FAILURE,
  FETCH_CITIES_LIST,
  FETCH_CITIES_LIST_FAILURE,
  FETCH_RESORT_CITIES_LIST,
  FETCH_RESORT_CITIES_LIST_FAILURE,
} from 'constants/actions/weatherCities';

import {
  DEFAULT_API_URL,
  SEARCH_API_URLS,
  CITIES_LIST_URL,
  RESORT_CITIES_LIST_URL,
} from 'constants/index';

import captureException from 'core/captureException';

export function fetchWeatherCities(query, count = 7) {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      const url = `${DEFAULT_API_URL}${SEARCH_API_URLS.suggest}${encodeURI(query)}&count=${count}`;
      const req = await fetch(url);
      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось получить список городов с погодой: ${url}`);

        err.status = req.status;
        throw err;
      }

      if (__SERVER__) {
        statsd.timing('api_request.fetchWeatherCities.timer', startTime);
        statsd.timing('api_request.fetchGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.fetchWeatherCities.timer', startTime);
      }

      await dispatch({
        type:    FETCH_WEATHER_CITIES,
        payload: {data},
      });
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.fetchWeatherCities.counter');
      }

      await dispatch({
        type:    FETCH_WEATHER_CITIES_FAILURE,
        payload: {
          error: {
            stack:   error.stack,
            message: error.message,
            status:  500,
          },
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}

export function fetchCitiesList() {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      const url = `${DEFAULT_API_URL}${CITIES_LIST_URL}`;
      const req = await fetch(url);
      if (req.ok === false) {
        throw new Error(`${new Date()} Не удалось получить список городов с погодой: ${url}`);
      }

      if (__SERVER__) {
        statsd.timing('api_request.fetchCitiesList.timer', startTime);
        statsd.timing('api_request.fetchGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.fetchCitiesList.timer', startTime);
      }

      await dispatch({
        type:    FETCH_CITIES_LIST,
        payload: {data},
      });
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.fetchCitiesList.counter');
      }

      await dispatch({
        type:    FETCH_CITIES_LIST_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}

export function fetchResortCitiesList(monthName = 'january') {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      const url = `${DEFAULT_API_URL}${RESORT_CITIES_LIST_URL}?month=${monthName}`;
      const req = await fetch(url);
      if (req.ok === false) {
        throw new Error(`${new Date()} Не удалось получить список курортных городов с погодой: ${url}`);
      }

      if (__SERVER__) {
        statsd.timing('api_request.fetchResortCitiesList.timer', startTime);
        statsd.timing('api_request.fetchGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.fetchResortCitiesList.timer', startTime);
      }

      await dispatch({
        type:    FETCH_RESORT_CITIES_LIST,
        payload: {data},
      });
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.fetchResortCitiesList.counter');
      }

      await dispatch({
        type:    FETCH_RESORT_CITIES_LIST_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}
