import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import Logo from './i/logo.svg';

import s from './index.scss';

class YandexLogo extends React.Component {
  static propTypes = {
    isMobile:          pt.bool.isRequired,
    className:         pt.string,
    isAbsolute:        pt.bool,
    withBackground:    pt.bool,
    withBottomPadding: pt.bool,
    underChart:        pt.bool,
  }

  static defaultProps = {
    className:         '',
    isAbsolute:        false,
    withBackground:    false,
    withBottomPadding: false,
    underChart:        false,
  }

  render() {
    const {
      isMobile,
      className,
      isAbsolute,
      withBackground,
      withBottomPadding,
      underChart,
    } = this.props;

    return (
      <div
        className={cn(
          s.logo,
          isMobile ? s.logoMobile : s.logoDesktop,
          !isMobile && isAbsolute && s.logoDesktopAbsolute,
          withBackground && s.logoBackground,
          withBottomPadding && s.logoBottomPadding,
          underChart && s.logoUnderChart,
          className,
        )}
      >
        {isMobile && <span className={s.text}>Источник данных</span>}
        <Logo className={s.icon} />
      </div>
    );
  }
}

export const YandexLogoWithoutHocs = YandexLogo;
export default withStyles(s)(YandexLogo);
