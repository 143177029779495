/* eslint-disable react/prop-types */
import React, {useRef, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import moment from 'moment';

import AdComponent from '@rambler-components/ad';

import {
  MOBILE_PADID,
  AD_ID_TO_NAME,
  AD_PLACEHOLDERS_LIST,
} from 'constants/ad';

import {safeGet} from 'core/safeGet';

import {useAdvObserver} from './hooks/useAdvObserver';
// import {useViewType} from './hooks/useViewType';
import s from './index.scss';

const selectRuntimeData = createSelector(
  [
    (state) => state.runtime.isMobile,
    (state) => state.runtime.pageNumber,
    (state) => state.runtime.weatherText, // puid6. только для главной
    (state) => state.runtime.weatherAdditionalText, // puid18. для страницы "погода сейчас"
    (state) => state.runtime.weatherTemp, // puid29
    (state) => state.runtime.weatherIcon, // puid30
    (state) => state.breadcrumbs.breadcrumbs,
    (state) => state.runtime.isNewDesign,
    (state) => state.runtime.billboardSplit,
    (state) => state.runtime.isRussia,
    (state) => state.runtime.adminData.timers.promovidzhet_visibility,
    (state) => state.runtime.adminData.variables.promovidzhet_isrussian,
  ],
  (
    isMobile,
    pageNumber,
    puid6,
    puid18,
    puid29,
    puid30,
    breadcrumbs,
    isNewDesign,
    billboardSplit,
    isRussia,
    promovidzhetShowTimer,
    promovidzhetIsRussian,
  ) => ({
    isMobile,
    pageNumber,
    puid6,
    puid18,
    puid29,
    puid30,
    breadcrumbs,
    isNewDesign,
    billboardSplit,
    isRussia,
    promovidzhetShowTimer,
    promovidzhetIsRussian,
  }),
);

const Ad = ({
  id = '',
  className = '',
  wrapperClassName = '',
  onRenderStyle = {},
  wrapperOnRenderClassName = '',
  onRenderWrapperStyle = {},
  children = null,
  isLazy = null,
  onRender = () => {},
  isReloaded = false,
  isMobInDesk = false,
  isSticky = false,
}) => {
  const {
    isMobile,
    pageNumber,
    puid6,
    puid18,
    puid29,
    puid30,
    breadcrumbs,
    isNewDesign,
    billboardSplit,
    isRussia,
    promovidzhetShowTimer,
    promovidzhetIsRussian,
  } = useSelector(selectRuntimeData);
  const adRef = useRef(null);
  const isBannerReallyRendered = useAdvObserver(adRef);

  const adName = AD_ID_TO_NAME[id];
  const isBillboard = adName === 'billboard';
  const isTopBanner = adName === 'top_banner';

  const dontRenderBanner = billboardSplit === 'without_main_banners'
    && (isBillboard || isTopBanner);

  if (!id || dontRenderBanner) return null;

  // use if you need something that depends on viewType
  // and don't foget to add isBranding in runtime
  // useViewType(adName);

  const withPlaceholder = AD_PLACEHOLDERS_LIST.includes(adName)
    && safeGet(() => !window.isAdblockEnabled, true);

  const isPromoWidget = adName.includes('Promovidzhet');
  const isPromoVisible = useMemo(() => {
    if (!isPromoWidget || !promovidzhetShowTimer?.enabled) return false;
    if (promovidzhetIsRussian && !isRussia) return false;

    const {enabled_from: start, enabled_to: end} = promovidzhetShowTimer;

    const currentDate = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    return moment(currentDate).isBetween(startDate, endDate);
  }, [isRussia, promovidzhetShowTimer, promovidzhetIsRussian, isPromoWidget]);

  if (isPromoWidget && !isPromoVisible) return null;

  const puid25 = breadcrumbs && breadcrumbs.length >= 2 ? breadcrumbs[1].href.replace('world/', '') : null;

  const options = {
    puid31: 'weather.rambler',
  };

  if (puid6) {
    options.puid6 = puid6;
  }
  if (puid18) {
    options.puid18 = puid18;
  }
  if (puid25) {
    options.puid25 = puid25;
  }
  if (puid29) {
    options.puid29 = puid29;
  }
  if (puid30) {
    options.puid30 = puid30;
  }

  if (isPromoWidget) {
    options.puid6 = 'WEATHERRAMBLERRU_MAIN';
    options.puid18 = 'WEATHERRAMBLERRU_MAIN_MAIN';
  }

  const misc = {
    without_main_banners: 2,
    with_main_banners:    1,
    main:                 null,
  }[billboardSplit];

  return (
    <div
      className={cn(
        s.root,
        isMobile ? s.rootMobile : s.rootDesktop,
        isSticky && s.sticky,
        isSticky && isNewDesign && s.stickyNewDesign,
        isSticky && !isNewDesign && s.stickyOldDesign,
        s[adName],
        wrapperClassName,
        isBannerReallyRendered && wrapperOnRenderClassName,
      )}
      style={isBannerReallyRendered || withPlaceholder ? onRenderWrapperStyle : {}}
    >
      <div
        className={cn(
          s.wrapper,
          isBillboard && s.wrapperBillboard,
          AD_PLACEHOLDERS_LIST.includes(adName) && s.placeholder,
          withPlaceholder && s.placeholderVisible,
          withPlaceholder && s[`placeholder_${adName}`],
        )}
      >
        <div ref={adRef}>
          <AdComponent
            id={id}
            padId={isMobInDesk ? MOBILE_PADID : null}
            idPrefix="fabex"
            className={className}
            loadedStyle={onRenderStyle}
            onRender={onRender}
            isLazy={isLazy}
            reloadKey={isReloaded ? pageNumber : null}
            options={options}
            misc={misc}
          >
            {children}
          </AdComponent>
        </div>
      </div>
    </div>
  );
};

export default withStyles(s)(Ad);
