import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import WeatherIcon from 'components/WeatherIcon';
import periodTitles from 'static/periodTitles';

import s from './index.scss';

class WeatherPeriods extends React.Component {
  static propTypes = {
    isNewDesign: pt.bool.isRequired,
    data:        pt.object.isRequired,
    className:   pt.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const {
      isNewDesign,
      data,
      className,
    } = this.props;
    const periodsData = Object.keys(data);

    return (
      <div className={cn(
        s.period,
        isNewDesign ? s.periodNewDesign : s.periodOldDesign,
        className,
      )}
      >
        {periodsData.map(key => (
          <div
            className={cn(
              s[`periodItem${periodsData.length}`],
              isNewDesign ? s.periodItemNewDesign : s.periodItemOldDesign,
            )}
            key={`periods-${key}`}
          >
            <div className={cn(
              s.periodTitle,
              isNewDesign ? s.periodTitleNewDesign : s.periodTitleOldDesign,
            )}
            >
              {periodTitles[key]}
            </div>
            <div className={s.periodParameters}>
              <WeatherIcon
                size={40}
                type={data[key].icon}
                className={s.periodIcon}
              />
              <span
                className={cn(
                  s.periodTemperature,
                  isNewDesign ? s.periodTemperatureNewDesign : s.periodTemperatureOldDesign,
                )}
              >
                {data[key].temperature < 0 ? `−${Math.abs(data[key].temperature)}` : data[key].temperature}&deg;
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export const WeatherPeriodsWithoutHocs = WeatherPeriods;
export default withStyles(s)(WeatherPeriods);
