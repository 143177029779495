import {connect} from 'react-redux';
import WeatherInCity from './index.js';

const mapStateToProps = ({runtime, town, home}) => ({
  isFirstVisit: runtime.pageNumber === 1,
  page:         runtime.page,
  pagePrefix:   runtime.pagePrefix,
  data:         home.currentWeather,
  today:        home.today,
  town:         town.town,
});

export default connect(mapStateToProps)(WeatherInCity);
