function action() {
  return {
    redirect: true,
    status:   404,
  };
}

export default {
  path: '(.*)',
  action,
};
