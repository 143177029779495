import {connect} from 'react-redux';

import BreadcrumbsNewDesign from './index.js';

const mapStateToProps = ({breadcrumbs, runtime}) => ({
  isMobile:        runtime.isMobile,
  page:            runtime.page,
  isNewDesign:     runtime.isNewDesign,
  breadcrumbs:     breadcrumbs.breadcrumbs,
  isWithMicrodata: breadcrumbs.isWithMicrodata,
});

export default connect(mapStateToProps)(BreadcrumbsNewDesign);
