import React, {Fragment} from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import sendMetrics from 'core/metrics/metrics';

import Link from 'components/Link';
import ButtonComponent from 'components/Button';
import BigWeatherIconTemper from 'components/BigWeatherIconTemper';
import WeatherIcon from 'components/WeatherIcon';

import YandexLogo from 'components/YandexLogo';

import ChangeUserGeo from 'Desktop/components/ChangeUserGeo';
import ChangeCity from 'Desktop/components/ChangeCity';
import WeatherPeriods from 'Desktop/components/WeatherPeriods';

import {MONTHS_GEN, WEEKDAYS} from 'constants/months';

import Geomagnetic from 'static/geomagnetic.json';

import s from './index.scss';

class WeatherInCity extends React.Component {
  static propTypes = {
    data: pt.shape({
      description_text: pt.string.isRequired,
      temperature:      pt.number.isRequired,
      icon:             pt.string.isRequired,
      periods:          pt.object,
      temp_feels:       pt.number,
      geomagnetic:      pt.number,
    }),
    town: pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }),
    today:        pt.string,
    pagePrefix:   pt.string,
    page:         pt.string.isRequired,
    isFirstVisit: pt.bool.isRequired,
  };

  static defaultProps = {
    data:       null,
    town:       null,
    today:      '',
    pagePrefix: '',
  };

  componentDidMount() {
    if (window.performance) {
      const mountMoment = performance.now();
      const renderTime = Math.round(mountMoment - window.startTime);

      if (this.props.isFirstVisit) {
        sendMetrics({name: 'client_desktop_index_city_time', value: renderTime});
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const {
      page,
      town,
    } = this.props;

    return ((page !== 'main') && (nextProps.page === 'main'))
      || (town.url_path !== nextProps.town.url_path);
  }

  getTitle = (pagePrefix, town) => {
    if (pagePrefix.length) {
      return `<h1>Погода ${town.loc_case_name}</h1>`;
    }

    return `<h1>Погода</h1> ${town.loc_case_name}`;
  }

  renderFeelsTempAndGeo = (position) => {
    const {
      data: {
        temp_feels: tempFeels,
        geomagnetic,
      },
      town,
      page,
    } = this.props;

    if (tempFeels !== null || geomagnetic !== null) {
      return (
        <div
          className={cn(
            s.info,
            s[`info${position}`],
          )}
        >
          {tempFeels !== null &&
            // eslint-disable-next-line max-len
            <span className={s.infoTemp}>Ощущается как <span className={s.infoTempValue}>{tempFeels < 0 ? `−${Math.abs(tempFeels)}` : tempFeels}&deg;</span></span>
          }
          {geomagnetic !== null &&
            <Link
              className={s.infoGeomagnetic}
              to={`/${town.url_path}/geomagnetic/`}
              data-weather={`${page}::geomagnetic`}
            >
              <WeatherIcon
                className={s.infoGeomagneticIcon}
                type="geo"
                isAzure
              />
              {Geomagnetic[geomagnetic].fullTitle}
            </Link>
          }
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      data,
      town,
      today,
      pagePrefix,
      page,
    } = this.props;
    const currentDate = new Date(today);

    const dateText = `${WEEKDAYS[currentDate.getDay()]}, ${currentDate.getDate()} ${MONTHS_GEN[currentDate.getMonth()]}`;

    return (
      <div className={s.root}>
        {data &&
          <Fragment>
            <div data-block="location">
              <div className={s.top}>
                <div
                  className={s.title}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{__html: this.getTitle(pagePrefix, town)}}
                />
                <ChangeUserGeo />
              </div>
              <ChangeCity />
            </div>
            <div data-block="main">
              <div className={s.date}>
                {dateText}
              </div>
              <div className={s.textLine}>
                <div className={s.text}>
                  {data.description_text}
                </div>
              </div>
              <div className={s.todayLine}>
                <div className={s.firstLine}>
                  <Link
                    to={`/${town.url_path}/now/`}
                    data-weather={`${page}::detail_icon`}
                  >
                    <BigWeatherIconTemper icon={data.icon} temperature={data.temperature} />
                  </Link>
                </div>
                {this.renderFeelsTempAndGeo('top')}
                <div className={s.secondLine}>
                  {data.periods && <WeatherPeriods data={data.periods} />}
                </div>
              </div>
              <div
                className={s.hiddenComment}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{__html: '<!--noindex-->'}}
              />
              {this.renderFeelsTempAndGeo('bottom')}
              <div
                className={s.hiddenComment}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{__html: '<!--/noindex-->'}}
              />
              <ButtonComponent
                className={s.link}
                href={`/${town.url_path}/today/`}
                data-weather={`${page}::detail_button`}
              >
                Подробный прогноз
              </ButtonComponent>
            </div>
            <YandexLogo isAbsolute />
          </Fragment>
        }
        {!data &&
          <div className={s.noData}>
            Извините, нет подходящих данных.<br />
            Попробуйте обновить страницу
          </div>
        }
      </div>
    );
  }
}

export const WeatherInCityWithoutHocs = WeatherInCity;
export default withStyles(s)(WeatherInCity);
