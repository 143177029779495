import React, {Fragment} from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import WeatherIcon from 'components/WeatherIcon';

import s from './index.scss';

class BigWeatherIconTemper extends React.Component {
  static propTypes = {
    isNewDesign:        pt.bool.isRequired,
    icon:               pt.string.isRequired,
    temperature:        pt.number.isRequired,
    className:          pt.string,
    needFeel:           pt.bool,
    tempFeels:          pt.number,
    tempFeelsClassname: pt.string,
    withoutTopFeels:    pt.bool,
  };

  static defaultProps = {
    className:          '',
    needFeel:           false,
    tempFeels:          null,
    tempFeelsClassname: '',
    withoutTopFeels:    false,
  }

  shouldComponentUpdate(nextProps) {
    return (this.props.icon !== nextProps.icon)
      || (this.props.temperature !== nextProps.temperature)
      || (this.props.tempFeels !== nextProps.tempFeels);
  }

  getFeelsTemp = (position) => {
    const {
      tempFeels,
      tempFeelsClassname,
    } = this.props;

    return (
      <span
        className={cn(
          s.tempFeels,
          [s[`tempFeels${position}`]],
          tempFeelsClassname,
        )}
      >
        Ощущается как&nbsp;<span className={s.tempFeelsValue}>{tempFeels < 0 ? `−${Math.abs(tempFeels)}` : tempFeels}&deg;</span>
      </span>
    );
  }

  render() {
    const {
      isNewDesign,
      icon,
      temperature,
      className,
      needFeel,
      withoutTopFeels,
    } = this.props;

    const temperatureText = `${temperature < 0 ? `−${Math.abs(temperature)}` : temperature}`;

    if (needFeel && !isNewDesign) {
      return (
        <Fragment>
          <div className={cn(s.root, className)}>
            <WeatherIcon
              size={100}
              type={icon}
              className={cn(s.icon, isNewDesign ? s.iconNewDesign : s.iconOldDesign)}
            />
            <div className={s.temperatureWrapper}>
              <div
                className={cn(
                  s.temperature,
                  isNewDesign ? s.temperatureNewDesign : s.temperatureOldDesign,
                )}
              >
                {temperatureText}&deg;
              </div>
              {!withoutTopFeels && this.getFeelsTemp('top')}
            </div>
          </div>
          {this.getFeelsTemp('bottom')}
        </Fragment>
      );
    }

    return (
      <div className={cn(s.root, className)}>
        <WeatherIcon
          size={100}
          type={icon}
          className={cn(s.icon, isNewDesign ? s.iconNewDesign : s.iconOldDesign)}
        />
        <div
          className={cn(
            s.temperature,
            isNewDesign ? s.temperatureNewDesign : s.temperatureOldDesign,
          )}
        >
          {temperatureText}&deg;
        </div>
      </div>
    );
  }
}

export const BigWeatherIconTemperWithoutHocs = BigWeatherIconTemper;
export default withStyles(s)(BigWeatherIconTemper);
