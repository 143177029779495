import React from 'react';
import cn from 'classnames';

const fakeAdClassnames = [
  'ad',
  'ad-banner',
  'adbanner',
  'nav-ad',
  'pub_300x250',
  'pub_300x250m',
  'pub_728x90',
  'text-ad',
  'textAd',
  'text_ad',
  'text_ads',
  'text-ads',
  'text-ad-links',
];

class AdblockDetect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setFakeAdNodeRef = node => this.fakeAdNode = node;
  }

  componentDidMount() {
    const styles = window.getComputedStyle(this.fakeAdNode, null);

    window.isAdblockEnabled = styles.getPropertyValue('display') === 'none'
      || styles.getPropertyValue('visibility') === 'hidden';
  }

  render() {
    return (
      <div
        className={cn(fakeAdClassnames)}
        ref={this.setFakeAdNodeRef}
        style={{
          width:    1,
          height:   1,
          position: 'absolute',
          left:     -100000,
        }}
      />
    );
  }
}

export default AdblockDetect;
