import {combineReducers} from 'redux';
import runtime from './runtime';
import ui from './ui';
import breadcrumbs from './breadcrumbs';
import home from './home';
import day from './day';
import now from './now';
import ndays from './ndays';
import weekend from './weekend';
import weatherCities from './weatherCities';
import month from './month';
import monthName from './monthName';
import geomagnetic from './geomagnetic';
import config from './config';
import geo from './geo';
import town from './town';
import seo from './seo';
import countries from './countries';
import nearestCities from './nearestCities';
import agroprognoz from './agroprognoz';
import map from './map';
import header from './header';

export default combineReducers({
  runtime,
  config,
  ui,
  header,

  breadcrumbs,
  home,
  day,
  now,
  ndays,
  weekend,
  weatherCities,
  geo,
  month,
  monthName,
  geomagnetic,
  town,
  seo,
  countries,
  nearestCities,
  agroprognoz,
  map,
});
