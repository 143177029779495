export const DELTAS = {
  // Разница между левым нижним и правым верхним углом с центром в 0 0

  // 0:  {lat: 178.59256650309743, lng: 808.5937500000001},
  // 1:  {lat: 162.07690216086783, lng: 404.29687500000006},
  // Пока что нет смысла использовать данные 0го и 1го зумов
  2:  {lat: 117.44495835913258, lng: 202.14843750000003},
  3:  {lat: 68.46896201751841, lng: 101.07421875000001},
  4:  {lat: 35.8738473993053, lng: 50.53710937500001},
  5:  {lat: 18.16079889511858, lng: 25.268554687500004},
  6:  {lat: 9.109043830082038, lng: 12.634277343750002},
  7:  {lat: 4.5581236826155855, lng: 6.317138671875001},
  8:  {lat: 2.279512730170462, lng: 3.1585693359375004},
  9:  {lat: 1.139812747103211, lng: 1.5792846679687502},
  10: {lat: 0.569913421957589, lng: 0.7896423339843751},
  11: {lat: 0.2849575920499775, lng: 0.39482116699218756},
  12: {lat: 0.14247890615953784, lng: 0.19741058349609378},
  13: {lat: 0.0712394668465963, lng: 0.09870529174804689},
  14: {lat: 0.03561973514414998, lng: 0.049352645874023444},
  15: {lat: 0.017809867787182264, lng: 0.024676322937011722},
  16: {lat: 0.008904933920485903, lng: 0.012338161468505861},
  17: {lat: 0.004452466963588895, lng: 0.0061690807342529306},
  18: {lat: 0.0022262334822270033, lng: 0.0030845403671264653},
};

export const DEFAULT_ZOOM = 10;
export const MIN_ZOOM = 1;

export const MAP_BOUNDS = [
  [-90, -200],
  [90, 200],
];

export const MIN_LAT_LNG_STEP = 0.0000000000000000001;
