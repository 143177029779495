import {
  FETCH_NEAREST_CITIES_DATA_SUCCESS,
  FETCH_NEAREST_CITIES_DATA_FAILURE,
} from 'constants/actions/cities';

export default function nearestCities(state = [], action) {
  switch (action.type) {
    case FETCH_NEAREST_CITIES_DATA_SUCCESS: {
      return {
        ...state,
        cities: action.payload.cities,
      };
    }
    case FETCH_NEAREST_CITIES_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
