import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import Link from 'components/Link';

import popular from 'static/popular.json';

import s from './index.scss';

class Popular extends React.Component {
  static propTypes = {
    page:        pt.string.isRequired,
    isCountries: pt.bool,
    isRegions:   pt.bool,
    country:     pt.shape({
      name:          pt.string,
      url_path:      pt.string.isRequired,
      loc_case_name: pt.string,
    }).isRequired,
  }

  static defaultProps = {
    isCountries: false,
    isRegions:   false,
  }

  getTitle = () => {
    const {
      isCountries,
      isRegions,
    } = this.props;

    if (isCountries) {
      return 'Популярные страны';
    } else if (isRegions) {
      return 'Популярные регионы России';
    }
  }

  render() {
    const {
      page,
      isCountries,
      isRegions,
      country,
    } = this.props;

    if (
      (!isCountries && !isRegions)
      || (isCountries && isRegions)
      || (isRegions && country.url_path !== 'world/rossiya')
    ) {
      return null;
    }

    const data = isCountries ? popular.countries : popular.regions;

    return (
      <div className={s.popular} data-block="popular_sbj">
        <span className={s.popularTitle}>{this.getTitle()}</span>
        <div className={s.popularList}>
          {data.map(item => (
            <div className={s.popularListItemWrapper}>
              <Link
                to={`/world${isRegions ? '/rossiya' : ''}/${item.slug}/`}
                key={item.slug}
                className={s.popularListItem}
                data-weather={`${page}::items_popular${isRegions ? '::Россия' : ''}::${item.title}`}
              >
                {item.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const PopularWithoutHocs = Popular;
export default withStyles(s)(Popular);
