import {connect} from 'react-redux';
import WeatherNdaysTable from './index';

const mapStateToProps = state => ({
  ndaysData:  state.ndays.data,
  currentDay: state.ndays.currentDay,
  town:       state.town.town,
  page:       state.runtime.page,
});

export default connect(mapStateToProps)(WeatherNdaysTable);
