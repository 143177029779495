import {
  FETCH_GEOMAGNETIC_DATA_SUCCESS,
  FETCH_GEOMAGNETIC_DATA_FAILURE,
} from 'constants/actions/geomagnetic';

export default function geomagnetic(state = [], action) {
  switch (action.type) {
    case FETCH_GEOMAGNETIC_DATA_SUCCESS: {
      const data = {...action.payload.data};

      return {
        ...state,
        title:      data.name,
        chartsData: data.geomagnetic,
      };
    }
    case FETCH_GEOMAGNETIC_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
