import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './index.scss';

class RoadwayVisibility extends React.Component {
  static propTypes = {
    points:      pt.number.isRequired,
    page:        pt.string.isRequired,
    description: pt.string.isRequired,
    isMobile:    pt.bool.isRequired,
  }

  shouldComponentUpdate(nextProps) {
    return ['now', 'today', 'tomorrow'].includes(nextProps.page);
  }

  getTitle() {
    let title = 'Видимость на дорогах ';
    switch (this.props.page) {
      case 'now':
        title += 'сейчас';
        break;
      case 'today':
        title += 'сегодня';
        break;
      case 'tomorrow':
        title += 'завтра';
        break;
      // eslint-disable-next-line no-case-declarations
      default:
        break;
    }
    return title;
  }

  getIndicatorColor() {
    switch (this.props.points) {
      case 1:
        return '#F44336';
      case 2:
        return '#FF9800';
      case 3:
        return '#FFC107';
      case 4:
      case 5:
        return '#CDDC39';
      default:
        return null;
    }
  }

  render() {
    const indicatorColor = this.getIndicatorColor();
    const {
      isMobile,
      description,
    } = this.props;

    return (
      <div
        className={cn(
          s.roadwayVisibility,
          isMobile && s.mobile,
        )}
        data-block="road"
      >
        <div className={s.header}>
          <h2
            className={cn(
              s.title,
              isMobile && s.mobile,
            )}
          >
            {this.getTitle()}
          </h2>
          {
            indicatorColor ?
              (
                <div
                  className={s.indicator}
                  style={{backgroundColor: indicatorColor}}
                />
              ) :
              null
          }
        </div>
        <p
          className={cn(
            s.description,
            isMobile && s.mobile,
          )}
        >
          {description}
        </p>
      </div>
    );
  }
}

export default withStyles(s)(RoadwayVisibility);
