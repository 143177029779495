import React from 'react';
import * as pt from 'prop-types';
import compose from 'recompose/compose';
import withStyles from 'isomorphic-style-loader/withStyles';

import Popup from '@rambler-components/popup';
import Geo from '@rambler-components/geo';

import popupStyles from '@rambler-components/popup/styles.css';
import geoStyles from '@rambler-components/geo/styles.css';

import clientSideOnly from 'core/HOCs/clientSideOnly';
import toggleBodyScroll from 'core/toggleBodyScroll';

import s from './index.scss';

class GeoModal extends React.Component {
  static propTypes = {
    isModalVisible:  pt.bool.isRequired,
    reloadGeoKey:    pt.number.isRequired,
    closeModal:      pt.func.isRequired,
    setCityChanging: pt.func.isRequired,
    setGeoLocation:  pt.func.isRequired,
  };

  onSave = (geo) => {
    const {
      setCityChanging,
      setGeoLocation,
      reloadGeoKey,
    } = this.props;

    setCityChanging(true);
    setGeoLocation(geo.id, reloadGeoKey + 1);
  }

  onClose = () => {
    const {closeModal} = this.props;

    toggleBodyScroll(false);
    closeModal();
  }

  render() {
    const {
      isModalVisible,
      reloadGeoKey,
      closeModal,
    } = this.props;

    return (
      <Popup
        width={560}
        zIndex={5005}
        isOpen={isModalVisible}
        onClose={() => closeModal()}
      >
        <Geo reloadKey={reloadGeoKey} onChange={this.onSave} onClose={() => this.onClose()} />
      </Popup>
    );
  }
}

const enhance = compose(
  withStyles(s, popupStyles, geoStyles),
  clientSideOnly,
);

export const GeoModalWithoutHocs = GeoModal;
export default enhance(GeoModal);
