import daylight from './i/NewDesign/daylight.svg';
import moonPhase1 from './i/NewDesign/moonPhase-1.svg';
import moonPhase2 from './i/NewDesign/moonPhase-2.svg';
import moonPhase3 from './i/NewDesign/moonPhase-3.svg';
import moonPhase4 from './i/NewDesign/moonPhase-4.svg';
import moonPhase5 from './i/NewDesign/moonPhase-5.svg';
import moonPhase6 from './i/NewDesign/moonPhase-6.svg';
import moonPhase7 from './i/NewDesign/moonPhase-7.svg';
import moonPhase8 from './i/NewDesign/moonPhase-8.svg';
import precipitationProbability from './i/NewDesign/precipitationProbability.svg';
import pressure from './i/NewDesign/pressure.svg';
import rise from './i/NewDesign/rise.svg';
import set from './i/NewDesign/set.svg';
import uv from './i/NewDesign/uv.svg';
import waterTemperature from './i/NewDesign/waterTemperature.svg';
import humidity from './i/NewDesign/humidity.svg';
import wind from './i/NewDesign/wind.svg';
import sadovod from './i/NewDesign/sadovod.svg';
import minTemperature from './i/NewDesign/t-min.svg';
import maxTemperature from './i/NewDesign/t-max.svg';
import geomagnetic from './i/NewDesign/geomagnetic.svg';
import soilMoisture from './i/NewDesign/soilMoisture.svg';

export default {
  daylight,
  moonPhase1,
  moonPhase2,
  moonPhase3,
  moonPhase4,
  moonPhase5,
  moonPhase6,
  moonPhase7,
  moonPhase8,
  precipitation:   precipitationProbability,
  precipitationProbability,
  pressure,
  rise,
  set,
  uv,
  waterTemperature,
  humidity,
  wind,
  windSpeed:       wind,
  sadovod,
  minTemperature,
  maxTemperature,
  geomagnetic,
  soilTemperature: minTemperature,
  soilMoisture,
};
