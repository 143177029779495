import {
  FETCH_GEO_AUTODETECT_DATA,
  FETCH_GEO_CURRENT_DATA,
  FETCH_GEO_SUGGEST_DATA,
  GEO_SUGGEST_LIMIT,
  CLEAR_GEO_SUGGEST_DATA,
  FETCH_GEO_AUTODETECT_DATA_FAILURE,
  FETCH_GEO_CURRENT_DATA_FAILURE,
} from 'constants/actions/geo';
import defaultGeo from 'static/geo.json';

const initialState = {
  auto:         defaultGeo,
  current:      defaultGeo,
  suggest:      [],
  suggestQuery: '',
};

export default function geo(state = initialState, action) {
  switch (action.type) {
    case FETCH_GEO_AUTODETECT_DATA:
      return {
        ...state,
        auto: action.payload,
      };
    case FETCH_GEO_CURRENT_DATA:
      return {
        ...state,
        current: action.payload,
      };
    case FETCH_GEO_SUGGEST_DATA:
      return {
        ...state,
        suggest: Object.values(action.payload.data)
          .sort((a, b) => a.weight - b.weight)
          .slice(0, GEO_SUGGEST_LIMIT),
        suggestQuery: action.payload.query,
      };
    case CLEAR_GEO_SUGGEST_DATA:
      return {...state, suggest: [], suggestQuery: ''};
    case FETCH_GEO_AUTODETECT_DATA_FAILURE:
    case FETCH_GEO_CURRENT_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
