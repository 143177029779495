import {
  FETCH_MONTH_DATA_SUCCESS,
  FETCH_MONTH_DATA_FAILURE,
} from 'constants/actions/month';

export default function month(state = {}, action) {
  switch (action.type) {
    case FETCH_MONTH_DATA_SUCCESS:
      return {
        ...state,
        isDataFetched:  true,
        monthList:      action.payload.data.range_weather,
        currentDate:    action.payload.data.local_now,
        town:           action.payload.data.town,
        hasClimateData: action.payload.data.is_climate_available,
      };
    case FETCH_MONTH_DATA_FAILURE:
      return {
        ...state,
        isDataFetched: false,
        error:         action.payload,
        data:          {},
      };
    default:
      return state;
  }
}
