import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import Link from 'components/Link';

import s from './index.scss';

class Regions extends React.Component {
  static propTypes = {
    country: pt.shape({
      name:          pt.string.isRequired,
      url_path:      pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
    }).isRequired,
    regions: pt.objectOf(pt.arrayOf(pt.shape({
      name:     pt.string.isRequired,
      url_path: pt.string.isRequired,
    })).isRequired).isRequired,
    page: pt.string.isRequired,
  }

  render() {
    const {
      country,
      regions,
      page,
    } = this.props;

    if (!country) return null;

    return (
      <div className={s.regions} data-block="weather_sbj">
        <h1 className={s.regionsTitle}>Погода {country.loc_case_name} сегодня</h1>
        <div className={s.regionsList}>
          {regions && Object.keys(regions).map(letter => (
            <div className={s.regionsListItem} key={letter}>
              <h3 className={s.regionsListItemTitle}>{letter}</h3>
              <div className={s.regionsListItemRegions}>
                {regions[letter].map(item => (
                  <div className={s.regionsListItemRegionWrapper}>
                    <Link
                      to={`/${item.url_path}/`}
                      key={item.url_path}
                      className={s.regionsListItemRegion}
                      data-weather={`${page}::items::${country.name}::${item.name}`}
                    >
                      <span className={s.regionsListItemRegionTitle}>{item.name}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const RegionsWithoutHocs = Regions;
export default withStyles(s)(Regions);
