import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './index.scss';

class StaticText extends React.Component {
  static propTypes = {
    isNewDesign: pt.bool.isRequired,
    text:        pt.string.isRequired,
    urlPath:     pt.string,
  };

  static defaultProps = {
    urlPath: null,
  }

  render() {
    const {
      isNewDesign,
      text,
      urlPath,
    } = this.props;

    if (urlPath === 'v-omske') {
      return (
        <div className={s.fake} />
      );
    }

    return (
      <div className={cn(s.root, isNewDesign ? s.rootNewDesign : s.rootOldDesign)} data-block="seo">
        <p>{text}</p>
      </div>
    );
  }
}

export const StaticTextWithoutHocs = StaticText;
export default withStyles(s)(StaticText);
