export default (count, type) => {
  let dayPrefix = '';
  if (type === 'cloudy') {
    dayPrefix = 'Облачны';
  } else if (type === 'clear') {
    dayPrefix = 'Солнечны';
  }
  let dayText = dayPrefix;
  const dayCount = count % 10;

  if (count === 1 || (dayCount === 1 && count > 20)) {
    dayText += `${type !== 'rain' ? 'й ' : ''}день`;
  } else if (dayCount > 1 && dayCount < 5 && (count - dayCount !== 10)) {
    dayText += `${type !== 'rain' ? 'х ' : ''}дня`;
  } else {
    dayText += `${type !== 'rain' ? 'х ' : ''}дней`;
  }

  if (type === 'rain') {
    dayText += ' с осадками';
  }

  return dayText.charAt(0).toUpperCase() + dayText.slice(1);
};
