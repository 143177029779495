import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import 'moment/locale/ru';

import {MONTHS_GEN, WEEKDAYS} from 'constants/months';

import sendMetrics from 'core/metrics/metrics';

import BigWeatherIconTemper from 'components/BigWeatherIconTemper';
import WeatherDetailParams from 'components/WeatherDetailParams';

import YandexLogo from 'components/YandexLogo';

import ChangeUserGeo from 'Desktop/components/ChangeUserGeo';
import ChangeCity from 'Desktop/components/ChangeCity';
import WeatherPeriodsLine from 'Desktop/components/WeatherPeriodsLine';

import s from './index.scss';

class NowBlock extends React.Component {
  static propTypes = {
    now:     pt.object.isRequired,
    periods: pt.object.isRequired,
    town:    pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    // page:         pt.string.isRequired,
    today:        pt.string,
    isFirstVisit: pt.bool.isRequired,
  }

  static defaultProps = {
    today: '',
  };

  componentDidMount() {
    if (window.performance) {
      const {
        isFirstVisit,
      } = this.props;
      const mountMoment = performance.now();
      const renderTime = Math.round(mountMoment - window.startTime);

      if (isFirstVisit) {
        sendMetrics({
          name:  'client_desktop_now_data_time',
          value: renderTime,
        });
      }
    }
  }

  render() {
    const {
      town,
      now,
      today,
      periods,
    } = this.props;
    const weatherDetail = now && now.detail;
    const currentDate = new Date(today);

    return (
      <div className={s.nowBlock}>
        <div className={s.nowHead} data-block="location">
          <div
            className={s.nowTitle}
          >
            <h1>Погода {town.loc_case_name} сейчас</h1>
          </div>
          <ChangeUserGeo />
          <ChangeCity className={s.nowLocation} />
        </div>
        <div data-block="main">
          <div className={s.date}>
            {WEEKDAYS[currentDate.getDay()]},
            {' '}
            {currentDate.getDate()} {MONTHS_GEN[currentDate.getMonth()]},
            {' '}
            {currentDate.getHours()}:
            {currentDate.getMinutes() < 10 ? '0' : ''}{currentDate.getMinutes()}
          </div>
          <div className={s.nowInfo}>
            <div className={s.nowShort}>
              <BigWeatherIconTemper
                icon={now.icon}
                temperature={now.temperature}
                className={s.nowTemperature}
                needFeel
                tempFeels={now.tempFeels}
                tempFeelsClassname={s.nowFeels}
                withoutTopFeels
              />
            </div>
            <div className={s.nowDetail}>
              <div className={s.nowParamsWrapper}>
                <WeatherDetailParams
                  className={s.nowParams}
                  paramClassName={s.nowParam}
                  data={weatherDetail}
                />
              </div>
              <WeatherPeriodsLine data={periods} className={s.nowPeriods} />
            </div>
          </div>
        </div>
        <YandexLogo />
      </div>
    );
  }
}

export const NowBlockWithoutHocs = NowBlock;
export default withStyles(s)(NowBlock);
