// PADIDs
export const DESKTOP_PADID = 432345462;
export const MOBILE_PADID = 461191676;

// Desktop
export const AD_TOPLINE_BANNER_ID = 542324117;
export const AD_FULLSCREEN_ID = 432345478;
export const AD_BILLBOARD_ID = 432345474; // isReloaded
export const AD_SUPERFOOTER_ID = 432345466; // isLazy
export const AD_240X400_ID = 432345472; // isReloaded
export const AD_240X400_2_ID = 434989514; // isLazy, isReloaded
export const AD_PROMOVIDZHET_ID = 579498250;
export const AD_SHOW_UP_ID = 579503428;
// 140x100 на месте информации о магнитных бурях на главной и странице города. Не используется
// export const AD_140X100_ID = 475619600;

// Mobile
export const AD_RICH = 461192382;
export const AD_FULLSCREEN_MOBILE_ID = 461192412;
export const AD_TOP_BANNER_ID = 461192368; // isReloaded
export const AD_LISTING_1_ID = 461192446; // isLazy
export const AD_LISTING_2_ID = 461192472; // isLazy
export const AD_LISTING_3_ID = 461192482; // isLazy
export const AD_LISTING_4_ID = 461192508; // isLazy
export const AD_LISTING_SPEC_ID = 461192552; // isLazy
export const AD_CONTENT_1_ID = 461192510; // isLazy
export const AD_CONTENT_2_ID = 461192530; // isLazy
export const AD_CONTENT_3_ID = 461192540; // isLazy
export const AD_CONTENT_4_ID = 461192542; // isLazy
export const AD_CONTENT_SPEC_ID = 461192554; // isLazy
export const AD_INPAGE_ID = 579332357; // isLazy
export const AD_FOOTER_ID = 461192546; // isLazy, isReloaded
export const AD_PROMOVIDZHET_MOB_ID = 579498252;

export const AD_ID_TO_NAME = {
  [AD_TOPLINE_BANNER_ID]:    'topline_banner',
  [AD_FULLSCREEN_ID]:        'fullscreen',
  [AD_BILLBOARD_ID]:         'billboard',
  [AD_SUPERFOOTER_ID]:       'superfooter',
  [AD_240X400_ID]:           'ban_240x400',
  [AD_240X400_2_ID]:         'ban_240x400_2',
  [AD_PROMOVIDZHET_ID]:      'Promovidzhet',
  [AD_RICH]:                 'rich',
  [AD_FULLSCREEN_MOBILE_ID]: 'fullscreen_mob',
  [AD_TOP_BANNER_ID]:        'top_banner',
  [AD_LISTING_1_ID]:         'listing1',
  [AD_LISTING_2_ID]:         'listing2',
  [AD_LISTING_3_ID]:         'listing3',
  [AD_LISTING_4_ID]:         'listing4',
  [AD_LISTING_SPEC_ID]:      'listing_spec',
  [AD_CONTENT_1_ID]:         'content1',
  [AD_CONTENT_2_ID]:         'content2',
  [AD_CONTENT_3_ID]:         'content3',
  [AD_CONTENT_4_ID]:         'content4',
  [AD_CONTENT_SPEC_ID]:      'content_spec',
  [AD_INPAGE_ID]:            'inpage_m',
  [AD_FOOTER_ID]:            'footer',
  [AD_PROMOVIDZHET_MOB_ID]:  'Promovidzhetmob',
  [AD_SHOW_UP_ID]:           'showUp',
};

export const AD_PLACEHOLDERS_LIST = [
  'billboard',
  'superfooter',
  'ban_240x400',
  'ban_240x400_2',
  'top_banner',
  'listing1',
  'listing2',
  'listing3',
  'listing4',
  'listing_spec',
  'content1',
  'content2',
  'content3',
  'content4',
  'content_spec',
  'inpage_m',
  'footer',
  'Promovidzhet',
  'Promovidzhetmob',
];

export const PUB_SCOPE = '.rambler.ru';
