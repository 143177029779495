import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import moment from 'moment';
import 'moment/locale/ru';

import GeomagneticData from 'static/geomagnetic.json';

import s from './index.scss';

class ChartGeomagneticAsTable extends React.Component {
  static propTypes = {
    data: pt.object.isRequired,
    town: pt.shape({
      name:          pt.string,
      loc_case_name: pt.string,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    className: pt.string.isRequired,
  }

  render() {
    const {
      data,
      className,
      town,
    } = this.props;

    return (
      <div className={cn(s.chartAsTable, className)}>
        <table>
          <thead>
            <tr>
              {data.dates.map((item, index) => {
                const key = `ChartGeomagneticAsTable-head-${town.url_path}-${index}-${item.date}`;

                return (
                  <th key={key}>{moment(item.date).format('DD MMMM')}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {data.rates.map((val, index) => {
                const key = `ChartGeomagneticAsTable-body-${town.url_path}-${index}-${val}`;

                return (
                  <td key={key}>{GeomagneticData[val].fullTitle}</td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export const ChartGeomagneticAsTableWithoutHocs = ChartGeomagneticAsTable;
export default withStyles(s)(ChartGeomagneticAsTable);
