import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import crashReporter from '../middleware/crashReporter';
import rootReducer from '../reducers';
import createHelpers from './createHelpers';

export default function configureStore(initialState, helpersData) {
  const helpers = createHelpers(helpersData);
  const middleware = [thunk.withExtraArgument(helpers)];
  if (process.env.BROWSER && !__DEV__) {
    middleware.push(crashReporter);
  }
  let enhancer;

  if (__DEV__) {
    // eslint-disable-next-line global-require
    const createLogger = require('./logger').default;
    middleware.push(createLogger());

    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    let devToolsExtension = f => f;
    /* eslint-disable no-underscore-dangle */
    if (process.env.BROWSER && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__();
    }
    /* eslint-enable */

    enhancer = compose(
      applyMiddleware(...middleware),
      devToolsExtension,
    );
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(rootReducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('../reducers', () => (
      // eslint-disable-next-line global-require
      store.replaceReducer(require('../reducers').default)
    ));
  }

  return store;
}
