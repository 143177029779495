import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';

import moment from 'moment';
import 'moment/locale/ru';

import Link from 'components/Link';
import WeatherIcon from 'components/WeatherIcon';
import {WEEKDAYS_ABBR, MONTHS_ENG, MONTHS, MONTHS_GEN, MONTHS_ABBR_GEN} from 'constants/months';

import s from './index.scss';

class WeatherMonthTable extends React.Component {
  static propTypes = {
    weeks: pt.array,
    town:  pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }),
    currentDate:    pt.string.isRequired,
    page:           pt.string.isRequired,
    hasClimateData: pt.bool,
  };

  static defaultProps = {
    weeks:          [],
    town:           null,
    hasClimateData: false,
  };

  getDateState = (currentDay, dataDay) => {
    const currentDate = moment(currentDay);
    const dataDate = moment(dataDay);
    let state = '';

    if (currentDate.isSame(dataDate, 'day')) {
      state = 'today';
    } else if (currentDate.add(1, 'days').isSame(dataDate, 'day')) {
      state = 'tomorrow';
    } else {
      state = 'date';
    }

    return state;
  }

  getLink = (dataDay) => {
    const {
      town,
      currentDate,
    } = this.props;
    const dateState = this.getDateState(currentDate, dataDay);
    const dataDate = moment(dataDay);

    let link = dateState;

    if (dateState === 'date') {
      link = dataDate.locale('en').format('D-MMMM').toLowerCase();
    }

    return `/${town.url_path}/${link}/`;
  }

  getDayGuts = day => (
    <div className={s.tableCellInfo}>
      <WeatherIcon size={40} type={day.icon} className={s.tableCellIcon} />
      <div>
        <span className={s.tableCellTemperatureDay}>
          {day.day_temperature < 0 ? `−${Math.abs(day.day_temperature)}` : day.day_temperature}&deg;
        </span>
        <span className={s.tableCellTemperatureNight}>
          {day.night_temperature < 0 ? `−${Math.abs(day.night_temperature)}` : day.night_temperature}&deg;
        </span>
      </div>
      <div className={s.tableCellWind}>
        <WeatherIcon
          type="wind"
          size={15}
          className={cn(
            s.tableCellWindIcon,
            s[`iconWind--${day.wind_direction.toLowerCase()}`],
          )}
        />
        <span>{day.wind_speed} м/с</span>
      </div>
    </div>
  )

  getMonthLink = (monthIndex) => {
    const {
      town,
      hasClimateData,
    } = this.props;

    if (hasClimateData) {
      return (
        <Link
          className={cn(s.tableCellFirstText, 'isLink')}
          to={`/${town.url_path}/${MONTHS_ENG[monthIndex]}/`}
        >
          Погода на {MONTHS[monthIndex]}
        </Link>
      );
    }

    return (<span className={s.tableCellFirstText}>Погода на {MONTHS[monthIndex]}</span>);
  }

  getDay = (day, page) => {
    const {currentDate} = this.props;
    const currentMomentDate = moment(currentDate);
    const dataDate = moment(day.date);
    const isDayTemperatureSet = typeof day.day_temperature !== 'undefined';

    if (day.detailed) {
      return (
        <Link
          to={this.getLink(day.date)}
          className={cn(
            s.tableLink,
            {[s.muted]: day.isMuted},
            {[s.firstWeek]: day.isFirstWeek},
          )}
          data-weather={`${page}::calendar::${day.isMuted ? 'link_past' : 'link_future'}`}
        >
          <span className={s.tableCellDay}>
            {day.isFirstWeek &&
              <div className={s.tableCellFirst}>
                {dataDate.date() === 1 ? this.getMonthLink(dataDate.month()) : ''}
              </div>
            }
            <span className={s.tableCellDayDate}>
              <span className={s.tableCellDayNumber}>
                {new Date(day.date).getDate()}&nbsp;
              </span>
              {currentMomentDate.isSame(dataDate, 'day') && dataDate.date() !== 1 &&
                <span>
                  <span className={s.tableCellMonthWide}>{MONTHS_GEN[dataDate.month()]}</span>
                  <span className={s.tableCellMonthNarrow}>
                    {MONTHS_ABBR_GEN[dataDate.month()]}
                  </span>
                </span>
              }
            </span>
          </span>
          {isDayTemperatureSet && this.getDayGuts(day)}
          {!isDayTemperatureSet && <span className={s.tableCellNoData}>Нет данных</span>}
        </Link>
      );
    }

    return (
      <div
        className={cn(
          s.tableLink,
          {[s.muted]: day.isMuted},
          {[s.firstWeek]: day.isFirstWeek},
        )}
        data-weather={`${page}::calendar::nolink`}
      >
        <span className={s.tableCellDay}>
          {day.isFirstWeek &&
            <div className={s.tableCellFirst}>
              {dataDate.date() === 1 ? this.getMonthLink(dataDate.month()) : ''}
            </div>
          }
          <span className={s.tableCellDayNumber}>{new Date(day.date).getDate()}</span>
        </span>
        {isDayTemperatureSet && this.getDayGuts(day)}
        {!isDayTemperatureSet && <span className={s.tableCellNoData}>Нет данных</span>}
      </div>
    );
  }

  renderDays = () => {
    const {
      weeks,
      page,
    } = this.props;
    if (!weeks.length) return null;

    return weeks.map((week, weekIndex) => (
      <div
        className={s.tableRow}
        // eslint-disable-next-line
        key={`monthWeek-${weekIndex}`}
      >
        {week.map((day, dayIndex) => (
          <div
            key={`dayWeek-${day ? day.date : dayIndex}`}
            className={cn(
              s.tableCell,
              {[s.tableHover]: day && day.detailed},
            )}
          >
            {day && day.date && this.getDay(day, page)}
          </div>
        ))}
      </div>
    ));
  }

  render() {
    return (
      <div className={s.root} data-block="calendar">
        <div className={s.month}>
          <div className={s.table}>
            <div className={s.tableHead}>
              {WEEKDAYS_ABBR.map(weekday => (
                <div
                  key={weekday}
                  className={s.tableHeader}
                >
                  {weekday}
                </div>
              ))}
            </div>
            <div className={s.tableBody}>
              {this.renderDays()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const WeatherMonthTableWithoutHocs = WeatherMonthTable;
export default withStyles(s)(WeatherMonthTable);
