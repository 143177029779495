import {connect} from 'react-redux';
import WeatherHours from './index.js';

const mapStateToProps = state => ({
  data:           state.now.hoursList,
  isFirstVisit:   state.runtime.pageNumber === 1,
  seoDescription: state.seo.seoDescription,
});

export default connect(mapStateToProps)(WeatherHours);
