import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import moment from 'moment';
import 'moment/locale/ru';

import WeatherIcon from 'components/WeatherIcon';
import Link from 'components/Link';

import {MONTHS_GEN} from 'constants/months';

import s from './index.scss';

class WeatherDayLines extends React.Component {
  static propTypes = {
    page:       pt.string.isRequired,
    currentDay: pt.string.isRequired,
    town:       pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    data: pt.array,
  };

  static defaultProps = {
    data: null,
  }

  getDateState = (currentDay, dataDay) => {
    const currentDate = moment(currentDay);
    const dataDate = moment(dataDay);
    let state = '';

    if (currentDate.isSame(dataDate, 'day')) {
      state = 'today';
    } else if (currentDate.add(1, 'days').isSame(dataDate, 'day')) {
      state = 'tomorrow';
    } else {
      state = 'date';
    }

    return state;
  }

  getLink = (currentDay, dataDay) => {
    const dateState = this.getDateState(currentDay, dataDay);
    const dataDate = moment(dataDay);

    let link = dateState;

    if (dateState === 'date') {
      link = dataDate.locale('en').format('D-MMMM').toLowerCase();
    }

    return link;
  }

  getDayTitle = (currentDay, dataDay) => {
    const dateState = this.getDateState(currentDay, dataDay);
    const dataDate = moment(dataDay);
    let title = '';

    if (dateState === 'today') {
      title = 'Сегодня';
    } else if (dateState === 'tomorrow') {
      title = 'Завтра';
    } else {
      title = dataDate.format('dddd');
    }

    return title;
  }

  render() {
    const {
      page,
      town,
      data,
      currentDay,
    } = this.props;

    if (!data) return null;

    return (
      <div className={s.root}>
        <table className={s.table}>
          <thead>
            <tr className={s.theadLine}>
              <th>День</th>
              <th>Температура</th>
              <th>Осадки</th>
              <th>Ветер</th>
            </tr>
          </thead>
          <tbody className={s.tbodyLine}>
            {data.map((item, i) => {
              const key = `${page}-WeatherDayLines-day-${i}`;
              const nightTemp = item.periods[0].temperature;
              const dayTemp = item.periods[1].temperature;
              const link = `/${town.url_path}/${this.getLink(currentDay, item.date)}/`;
              const dayDate = new Date(item.date);

              return (
                <tr
                  className={cn(
                    s.item,
                    item.isHoliday && s.weekend,
                  )}
                  key={key}
                  data-weather={`${page}::day_lines::day_${i + 1}`}
                >
                  <td>
                    <Link
                      to={link}
                      className={s.link}
                    >
                      <span className={s.itemTitle}>
                        {this.getDayTitle(currentDay, item.date)}
                      </span>
                      <span className={s.itemDate}>
                        {dayDate.getDate()}
                        {' '}
                        {MONTHS_GEN[dayDate.getMonth()]}
                      </span>
                    </Link>
                  </td>
                  <td className={s.itemTemperature}>
                    <Link
                      to={link}
                      className={s.link}
                    >
                      <WeatherIcon
                        size={30}
                        // Берем иконку дня т.к. пользователю явно интереснее день, а не ночь
                        type={item.periods[1].icon}
                        className={s.itemTemperatureIcon}
                      />
                      <span className={s.itemTemperatureMax}>
                        {dayTemp < 0 ? `−${Math.abs(dayTemp)}` : dayTemp}&deg;
                      </span>
                      <span className={s.itemTemperatureMin}>
                        {nightTemp < 0 ? `−${Math.abs(nightTemp)}` : nightTemp}&deg;
                      </span>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={link}
                      className={s.link}
                    >
                      <span className={s.itemPrecipitation}>
                        {item.params.precipitationProbability}%
                      </span>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={link}
                      className={s.link}
                    >
                      <div className={s.itemWind}>
                        <WeatherIcon
                          type="wind"
                          size={15}
                          className={cn(
                            s.itemWindIcon,
                            s[`itemWindIcon--${item.params.wind.direction.toLowerCase()}`],
                          )}
                        />
                        <span className={s.itemWindText}>{item.params.wind.speed} м/с</span>
                      </div>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export const WeatherDayLinesWithoutHocs = WeatherDayLines;
export default withStyles(s)(WeatherDayLines);
