import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import s from './index.scss';

class ChartNdaysAsTable extends React.Component {
  static propTypes = {
    chartsData: pt.array.isRequired,
    className:  pt.string,
  }

  static defaultProps = {
    className: '',
  }

  getRowName = (name) => {
    switch (name) {
      case 'temperature': {
        return 'Температура, °C';
      }
      case 'humidity': {
        return 'Влажность, %';
      }
      case 'pressure': {
        return 'Давление, мм';
      }
      case 'wind': {
        return 'Ветер, м/с';
      }
      case 'precipitation': {
        return 'Осадки, мм';
      }
      default:
        return '';
    }
  }

  render() {
    const {
      chartsData,
      className,
    } = this.props;

    const params = Object.keys(chartsData[0].data[0]);

    return (
      <div className={cn(s.chartAsTable, className)}>
        <table className={s.chartAsTableTable}>
          <thead>
            <tr>
              <th></th>
              {chartsData.map((item, index) => {
                const key = `ChartNdaysAsTable-head-1th-${index}`;

                return (
                  <th
                    className={s.chartAsTableHeadTop}
                    key={key}
                    colSpan={item.data.length}
                  >
                    {item.date}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th></th>
              {chartsData.map(item => (
                item.dayParts.map((part, index) => {
                  const blockKey = `ChartNdaysAsTable-head-2th-${index}-${part}`;

                  return (
                    <th
                      className={s.chartAsTableHead}
                      key={blockKey}
                    >
                      {part}
                    </th>
                  );
                })
              ))}
            </tr>
          </thead>
          <tbody>
            {params.map((paramName, index) => {
              const dayKey = `ChartNdaysAsTable-body-${index}-${paramName}`;

              return (
                <tr key={dayKey}>
                  <td>{this.getRowName(paramName)}</td>
                  {chartsData.map((day, dataIndex) => (
                    day.data.map((paramsData, paramIndex) => {
                      const dateKey = `ChartNdaysAsTable-${index}-${dataIndex}-${paramIndex}-${paramName}-${paramsData[paramName]}`;

                      // eslint-disable-next-line max-len
                      return (<td className={s.chartAsTableValue} key={dateKey}>{paramsData[paramName]}</td>);
                    })
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export const ChartNdaysAsTableWithoutHocs = ChartNdaysAsTable;
export default withStyles(s)(ChartNdaysAsTable);
