import _reduce from 'lodash.reduce';

import {
  FETCH_MAP_ARIA_DATA_SUCCESS,
  FETCH_MAP_ARIA_DATA_FAILURE,
  FETCH_MAP_DATA_FAILURE,
  FETCH_MAP_CITY_DATA_SUCCESS,
  MAP_DATA_TILES_RESERVE,
} from 'constants/actions/map';
import {DELTAS} from 'constants/map';

export default function map(state = {}, action) {
  const {payload} = action;
  const data = (payload && payload.data) || {};

  switch (action.type) {
    case FETCH_MAP_CITY_DATA_SUCCESS: {
      const urlPath = data.current_town.url_path;
      const {lat, lng} = data.current_town.geo_location;

      const tiles = {...(state.tiles || {})};

      return {
        ...state,
        currentTown:       urlPath,
        citiesWeatherData: {
          ...(state.citiesWeatherData || {}),
          [urlPath]: data.current_town,
        },
        // Для того чтобы на карте отображался хотя бы текущий город
        // И чтобы не было такого, что при смене города предыдущий не отображается,
        // так как он приходит в не общей выдаче
        tiles: _reduce(DELTAS, (memo, {lat: latDelta, lng: lngDelta}, zoom) => {
          const y = Math.floor(lat / latDelta);
          const x = Math.floor(lng / lngDelta);

          if (
            memo[zoom]
            && memo[zoom][y]
            && memo[zoom][y][x]
            && memo[zoom][y][x].includes(urlPath)
          ) return memo;

          memo[zoom] = {...(memo[zoom] || {})};
          memo[zoom][y] = {...(memo[zoom][y] || {})};
          memo[zoom][y][x] = [...(memo[zoom][y][x] || []), urlPath];

          return memo;
        }, tiles),
      };
    }
    case MAP_DATA_TILES_RESERVE: {
      const {zoom} = payload;
      const reserved = {...(state.reserved || {})};
      reserved[zoom] = data.reduce((memo, {y, x}) => {
        memo[y] = {...(memo[y] || {})};
        memo[y][x] = 'reserved';

        return memo;
      }, reserved[zoom] || {});

      return {
        ...state,
        reserved,
      };
    }
    case FETCH_MAP_ARIA_DATA_SUCCESS: {
      const {y, x, zoom} = payload;

      const slug = state.currentTown;
      const {lat: latDelta, lng: lngDelta} = DELTAS[zoom];
      const {lat, lng} = state.citiesWeatherData[slug].geo_location;

      const currentCityY = Math.floor(lat / latDelta);
      const currentCityX = Math.floor(lng / lngDelta);

      const tileData = data.cities.map(city => city.url_path);

      if (y === currentCityY && x === currentCityX) {
        tileData.push(slug);
      }

      const tiles = {...(state.tiles || {})};
      tiles[zoom] = {...(tiles[zoom] || {})};
      tiles[zoom][y] = {...(tiles[zoom][y] || {}), [x]: tileData};
      const citiesWeatherData = {...(state.citiesWeatherData || {})};
      data.cities.forEach(city => citiesWeatherData[city.url_path] = city);

      return {
        ...state,
        tiles,
        citiesWeatherData,
      };
    }
    case FETCH_MAP_ARIA_DATA_FAILURE: {
      const {zoom, y, x} = data;
      const reserved = {...state.reserved};
      reserved[zoom] = {...reserved[zoom]};
      reserved[zoom][y] = {...reserved[zoom][y]};
      reserved[zoom][y][x] = null;

      return {
        ...state,
        reserved,
      };
    }
    case FETCH_MAP_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
