import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import moment from 'moment';
import 'moment/locale/ru';

import Button from 'rambler-ui/Button';

import Link from 'components/Link';
// import WeatherIcon from 'components/WeatherIcon';
import WeatherPeriods from 'Desktop/components/WeatherPeriods';
import WeatherDetailParams from 'components/WeatherDetailParams';

// import DropdownIcon from './i/dropdown.svg';
import s from './index.scss';

class WeatherNdaysTable extends React.Component {
  static propTypes = {
    ndaysData: pt.arrayOf(pt.shape({
      date:                      pt.string,
      sunset:                    pt.string,
      sunrise:                   pt.string,
      moon_phase:                pt.number,
      is_holiday:                pt.bool,
      detailed:                  pt.bool,
      temp_water:                pt.number,
      precipitation_probability: pt.number,
      polar:                     pt.string,
      forecast:                  pt.object,
    })),
    className:  pt.string,
    currentDay: pt.string.isRequired,
    town:       pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    page: pt.string.isRequired,
  }

  static defaultProps = {
    ndaysData: [],
    className: '',
  }

  // POGODA-1355
  // state = {
  //   expanded: '',
  // }

  getDateState = (currentDay, dataDay) => {
    const currentDate = moment(currentDay);
    const dataDate = moment(dataDay);
    let state = '';

    if (currentDate.isSame(dataDate, 'day')) {
      state = 'today';
    } else if (currentDate.add(1, 'days').isSame(dataDate, 'day')) {
      state = 'tomorrow';
    } else {
      state = 'date';
    }

    return state;
  }

  getDay = (currentDay, dataDay, isHoliday) => {
    const dateState = this.getDateState(currentDay, dataDay);
    const dataDate = moment(dataDay);
    let dayText = '';

    if (dateState === 'today') {
      dayText = 'сегодня';
    } else if (dateState === 'tomorrow') {
      dayText = 'завтра';
    } else {
      dayText = dataDate.format('dddd');
    }

    return `
      <span class="${s.tableBodyItemDay} ${isHoliday ? s.tableBodyItemHoliday : ''}">${dayText}</span>
      <span class="${s.tableBodyItemDate} ${isHoliday ? s.tableBodyItemHoliday : ''}">${dataDate.format('D MMMM')}</span>
    `;
  }

  getLink = (currentDay, dataDay) => {
    const dateState = this.getDateState(currentDay, dataDay);
    const dataDate = moment(dataDay);

    let link = dateState;

    if (dateState === 'date') {
      link = dataDate.locale('en').format('D-MMMM').toLowerCase();
    }

    return link;
  }

  getParamsData = data => ({
    precipitationProbability: data.precipitation_probability,
    wind:                     {
      speed:     data.forecast.day.wind_speed,
      direction: data.forecast.day.wind_direction,
    },
    pressure: data.forecast.day.pressure,
    sun:      {
      rise: data.sunrise,
      set:  data.sunset,
    },
    daylight:         data.daylight,
    moonPhase:        data.moon_phase,
    waterTemperature: data.water_temp || null,
    uv:               data.uv || null,
    geomagnetic:      data.geomagnetic,
  })

  // POGODA-1355
  // expandDay = (name) => {
  //   this.setState({
  //     expanded: name,
  //   });
  // }

  render() {
    const {
      className,
      ndaysData,
      currentDay,
      town,
      page,
    } = this.props;
    // POGODA-1355
    // const {
    //   expanded,
    // } = this.state;

    return (
      <div className={cn(s.table, className)} name="table">
        {page === 'city_2weeks'
          && <h2 className={s.title}>Погода {town.loc_case_name} на 14 дней</h2>
        }
        {/* POGODA-1355 */}
        {/* <div className={s.tableHead}>
          <span className={cn(s.tableHeadItem, s.tableDay)}>День</span>
          <span className={s.tableIcon} />
          <span className={cn(s.tableHeadItem, s.tableTemp)}>Температура</span>
          <span className={cn(s.tableHeadItem, s.tablePrecipitation)}>Осадки, мм</span>
          <span className={cn(s.tableHeadItem, s.tableWind)}>Ветер, м/с</span>
          <span className={cn(s.tableHeadItem, s.tableHumidity)}>Влажность, %</span>
          <span className={cn(s.tableHeadItem, s.tablePressure, s.tableUnimportant)}>
            Давление, мм
          </span>
          <span className={cn(s.tableHeadItem, s.tableSun, s.tableUnimportant)}>Восход</span>
          <span className={cn(s.tableHeadItem, s.tableSun, s.tableUnimportant)}>Закат</span>
        </div> */}
        {ndaysData.map((item, i) => {
          const key = `weatherTableBodyLine-${i}`;
          // const top100Index = i + 1 < 10 ? `0${i + 1}` : i + 1;
          // const day = {...item.forecast.day};
          // const night = {...item.forecast.night};
          // const sunRise = moment(item.sunrise).format('HH:mm');
          // const sunSet = moment(item.sunset).format('HH:mm');

          return (
            <div key={key} data-block={`detail_${i + 1}`}>
              {/* <div
                className={
                  cn(
                    s.tableBodyLine,
                    item.is_holiday && s.tableBodyLineHoliday,
                    expanded === key && s.hidden,
                  )
                }
                onClick={() => this.expandDay(key)}
                data-weather={`${page}::day_${top100Index}::open`}
              >
                <div
                  className={cn(s.tableBodyItem, s.tableDay)}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={
                    {__html: this.getDay(currentDay, item.date, item.is_holiday)}
                  }
                />
                <div className={cn(s.tableBodyItem, s.tableBodyItemIcon, s.tableIcon)}>
                  <WeatherIcon size={30} type={day.icon} />
                </div>
                <div className={cn(s.tableBodyItem, s.tableTemp, s.tableBodyItemTemp)}>
                  <span className={s.tableBodyItemTempDay}>{day.temperature}°</span>
                  <span className={s.tableBodyItemTempNight}>{night.temperature}°</span>
                </div>
                <div
                  className={cn(
                    s.tableBodyItem,
                    s.tablePrecipitation,
                    s.tableBodyItemPrecipitation,
                  )}
                >
                  {day.precipitation !== null
                    ? day.precipitation
                    : <span className={s.tableBodyItemEmpty}>Нет<br />данных</span>
                  }
                </div>
                <div className={cn(s.tableBodyItem, s.tableWind)}>
                  <WeatherIcon
                    type="wind"
                    size={20}
                    className={
                      cn(
                        s.tableBodyItemWind,
                        [s[`tableBodyItemWind${day.wind_direction}`]]
                      )
                    }
                  />
                  <span className={s.tableBodyItemWindText}>{day.wind_speed}</span>
                </div>
                <div className={cn(s.tableBodyItem, s.tableHumidity, s.tableBodyItemData)}>
                  {day.humidity !== null &&
                    <span className={s.tableBodyItemDataDay}>{day.humidity}</span>
                  }
                  {day.humidity !== null &&
                    <span className={s.tableBodyItemDataNight}>{night.humidity}</span>
                  }
                  {day.humidity === null &&
                    <span className={s.tableBodyItemEmpty}>Нет<br />данных</span>
                  }
                </div>
                <div
                  className={
                    cn(
                      s.tableBodyItem,
                      s.tablePressure,
                      s.tableBodyItemData,
                      s.tableUnimportant
                    )
                  }
                >
                  {day.pressure !== null &&
                    <span className={s.tableBodyItemDataDay}>{day.pressure}</span>
                  }
                  {day.pressure !== null &&
                    <span className={s.tableBodyItemDataNight}>{night.pressure}</span>
                  }
                  {day.pressure === null &&
                    <span className={s.tableBodyItemEmpty}>Нет<br />данных</span>
                  }
                </div>
                <div className={
                  cn(
                    s.tableBodyItem,
                    s.tableSun,
                    s.tableBodyItemData,
                    s.tableBodyItemDataDay,
                    s.tableUnimportant
                  )
                }
                >
                  {item.sunrise !== null && sunRise}
                  {item.sunrise === null &&
                    <span className={s.tableBodyItemEmpty}>Нет<br />данных</span>
                  }
                </div>
                <div className={
                  cn(
                    s.tableBodyItem,
                    s.tableSun,
                    s.tableBodyItemData,
                    s.tableBodyItemDataDay,
                    s.tableUnimportant
                  )
                }
                >
                  {item.sunset !== null && sunSet}
                  {item.sunset === null &&
                    <span className={s.tableBodyItemEmpty}>Нет<br />данных</span>
                  }
                </div>
              </div> */}
              <div
                className={
                  cn(
                    s.tableBodyLine,
                    s.tableBodyLineExpanded,
                    item.is_holiday && s.tableBodyLineHoliday,
                    // POGODA-1355
                    // expanded !== key && s.hidden,
                  )
                }
              >
                <div
                  className={cn(s.tableBodyItem, s.tableDay)}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={
                    {__html: this.getDay(currentDay, item.date, item.is_holiday)}
                  }
                />
                {/* POGODA-1355 */}
                {/* <DropdownIcon
                  className={cn(s.tableBodyItem, s.tableBodyItemArrow)}
                  onClick={() => this.expandDay('')}
                  data-weather={`${page}::day_${top100Index}::close`}
                /> */}
                <div className={cn(s.tableBodyItem, s.tableInfo)}>
                  <WeatherPeriods data={item.forecast} className={s.tableInfoPeriods} />
                  <div className={s.tableInfoParamsWrapper}>
                    <WeatherDetailParams
                      className={s.tableInfoParams}
                      paramClassName={s.tableInfoParam}
                      data={this.getParamsData(item)}
                    />
                  </div>
                  {item.detailed &&
                    <Button
                      className={s.tableInfoButton}
                      size="small"
                      type="outline"
                      iconPosition="right"
                      container={
                        <Link
                          to={`/${town.url_path}/${this.getLink(currentDay, item.date)}/`}
                          data-weather="button"
                        />
                      }
                    >
                      Подробно
                    </Button>
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export const WeatherNdaysTableWithoutHocs = WeatherNdaysTable;
export default withStyles(s)(WeatherNdaysTable);
