import {
  FETCH_AGROPROGNOZ_DATA_SUCCESS,
  FETCH_AGROPROGNOZ_DATA_FAILURE,
} from 'constants/actions/agroprognoz';

import {FETCH_TOWN_DATA_SUCCESS} from 'constants/actions/town';
import FETCH_SEO_DATA_SUCCESS from 'constants/actions/seo';
import FETCH_BREADCRUMBS_DATA_SUCCESS from 'constants/actions/breadcrumbs';
import {DEFAULT_API_URL} from 'constants/index';

import captureException from 'core/captureException';

export default function fetchAgroprognozData(geoID, urlPath) {
  return async(dispatch, getState, {fetch}) => {
    try {
      let url = `${DEFAULT_API_URL}/agroprognoz/`;

      if (geoID && !urlPath) {
        url += `?geo_id=${geoID}`;
      } else if ((geoID && urlPath) || (!geoID && urlPath)) {
        url += `?url_path=${urlPath}`;
      }

      const req = await fetch(url);

      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось получить данные о погоде для садовода: ${url}`);

        err.status = req.status;
        throw err;
      }

      const data = await req.json();

      await dispatch({
        type:    FETCH_AGROPROGNOZ_DATA_SUCCESS,
        payload: {data},
      });
      await dispatch({
        type:    FETCH_TOWN_DATA_SUCCESS,
        payload: {town: data.town},
      });
      await dispatch({
        type:    FETCH_SEO_DATA_SUCCESS,
        payload: {seo: data.seo},
      });
      await dispatch({
        type:    FETCH_BREADCRUMBS_DATA_SUCCESS,
        payload: {breadcrumbs: data.breadcrumbs, isWithMicrodata: true},
      });
    } catch (error) {
      captureException(error);

      await dispatch({
        type:    FETCH_AGROPROGNOZ_DATA_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}
