import {createSelector} from 'reselect';

const getChartsData = state => state || [];

const get3DaysData = (data) => {
  const sectionOrder = ['night', 'morning', 'day', 'evening'];
  const defaultXAxisCategories = {
    morning: '06',
    day:     '12',
    evening: '18',
  };
  const chartsData = [];

  const params = {
    temperature:     [],
    humidity:        [],
    pressure:        [],
    wind_direction:  [],
    wind_speed:      [],
    precipitation:   [],
    icon:            [],
    xAxisCategories: [],
  };
  const paramsKeys = Object.keys(params);

  // eslint-disable-next-line
  for (const day of data) {
    // eslint-disable-next-line
    for (const section of sectionOrder) {
      const fr = day.forecast[section];
      params.xAxisCategories.push({
        dateTime:  section === 'night' ? day.date : defaultXAxisCategories[section],
        isNight:   section === 'night',
        isHoliday: day.is_holiday,
      });
      // eslint-disable-next-line
      for (const param of paramsKeys) {
        if (param === 'xAxisCategories') continue;
        params[param].push(fr[param]);
      }
    }
  }

  chartsData.push({
    temperature: params.temperature,
    humidity:    params.humidity,
    pressure:    params.pressure,
    wind:        {
      speed:     params.wind_speed,
      direction: params.wind_direction,
    },
    precipitation:   params.precipitation,
    icon:            params.icon,
    xAxisCategories: params.xAxisCategories,
  });

  return chartsData;
};

const getNDaysData = (data) => {
  const sectionOrder = ['day', 'night'];
  const chartsData = [];

  // eslint-disable-next-line
  for (const section of sectionOrder) {
    const params = {
      temperature:     [],
      humidity:        [],
      pressure:        [],
      wind_direction:  [],
      wind_speed:      [],
      precipitation:   [],
      icon:            [],
      xAxisCategories: [],
    };
    const paramsKeys = Object.keys(params);
    // eslint-disable-next-line
    for (const day of data) {
      params.xAxisCategories.push({
        date:      day.date,
        isHoliday: day.is_holiday,
      });
      const fr = day.forecast[section];
      // eslint-disable-next-line
      for (const param of paramsKeys) {
        if (param === 'xAxisCategories') continue;
        params[param].push(fr[param]);
      }
    }

    chartsData.push({
      temperature: params.temperature,
      humidity:    params.humidity,
      pressure:    params.pressure,
      wind:        {
        speed:     params.wind_speed,
        direction: params.wind_direction,
      },
      precipitation:   params.precipitation,
      icon:            params.icon,
      xAxisCategories: params.xAxisCategories,
    });
  }

  return chartsData;
};

export default createSelector(
  [getChartsData],
  data => data.length === 3 ? get3DaysData(data) : getNDaysData(data),
);
