import FETCH_BREADCRUMBS_DATA_SUCCESS from 'constants/actions/breadcrumbs';

export default function town(state = {}, action) {
  switch (action.type) {
    case FETCH_BREADCRUMBS_DATA_SUCCESS: {
      return {
        ...state,
        breadcrumbs:     action.payload.breadcrumbs,
        isWithMicrodata: action.payload.isWithMicrodata || false,
      };
    }
    default:
      return state;
  }
}
