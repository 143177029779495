import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import {
  AD_SUPERFOOTER_ID,
  AD_LISTING_1_ID,
} from 'constants/ad';
import {MONTHS_ENG, MONTHS_ABBR} from 'constants/months';

import getDateRange from 'core/getDateRange';

import Ad from 'components/Ad';
import Link from 'components/Link';
import GeoModal from 'components/GeoModal';

import YandexLogo from 'components/YandexLogo';

import ChangeUserGeo from 'Desktop/components/ChangeUserGeo';
import ChangeCity from 'Desktop/components/ChangeCity';
import WeatherMonthChart from 'components/WeatherMonthChart';
import WeatherMonthTable from 'Desktop/components/WeatherMonthTable';
import ChartMonthAsTable from 'Desktop/components/ChartMonthAsTable';
import CitiesWeather from 'Desktop/components/CitiesWeather';
import NearestCities from 'Desktop/components/NearestCities';

import s from './index.scss';

class MonthPage extends React.Component {
  static propTypes = {
    page: pt.string.isRequired,
    town: pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    windowType:        pt.string.isRequired,
    screenTypeReady:   pt.bool.isRequired,
    hasClimateData:    pt.bool,
    chunkedChartsData: pt.array,
    currentDate:       pt.string.isRequired,
  }

  static defaultProps = {
    hasClimateData:    false,
    chunkedChartsData: null,
  }

  render() {
    const {
      page,
      town,
      windowType,
      screenTypeReady,
      hasClimateData,
      chunkedChartsData,
      currentDate,
    } = this.props;

    const {todayText, monthFromTodayText} = getDateRange(currentDate);

    const hasDataInChunkedChartsData = chunkedChartsData && chunkedChartsData[0].length > 0;

    return (
      <div className={s.month}>
        <div className={s.monthBlock}>
          <div className={s.monthHead} data-block="location">
            <div className={s.monthTop}>
              <h1 className={s.monthTitle}>{`Погода ${town.loc_case_name} на месяц (30 дней)`}</h1>
              <ChangeUserGeo />
            </div>
            <div className={s.monthLocationContainer}>
              <ChangeCity className={s.monthLocation} />
            </div>
            <div className={s.monthDates}>
              <Link
                className={cn(s.monthDatesItem, s.monthDatesItemActive)}
                to={`/${town.url_path}/30-days/`}
                data-weather={`${page}::30days`}
              >
                {todayText} — {monthFromTodayText}
              </Link>
              {hasClimateData && MONTHS_ABBR.map((item, i) => {
                const link = `/${town.url_path}/${MONTHS_ENG[i]}/`;

                return (
                  <Link
                    key={link}
                    to={link}
                    data-weather={`${page}::month_change::${i + 1}`}
                    className={s.monthDatesItem}
                  >
                    {item}
                  </Link>
                );
              })}
            </div>
          </div>
          {hasDataInChunkedChartsData &&
            <noscript
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `<style>
                  .${s.monthChart} {display: none}
                  .${s.monthNoJsTable} {
                    visibility: visible;
                    height: auto;
                    min-height: 200px !important;
                    padding-top: 20px !important;
                  }
                </style>`,
              }}
            />
          }
          <WeatherMonthChart className={s.monthChart} />
          {hasDataInChunkedChartsData &&
            chunkedChartsData.map((data, index) => {
              const key = `ChartMonthAsTable-chunk${index}`;

              return (
                <ChartMonthAsTable
                  className={s.monthNoJsTable}
                  chartsData={data}
                  key={key}
                />
              );
            })
          }
          <YandexLogo underChart />
        </div>
        <WeatherMonthTable />
        <NearestCities />
        {windowType !== 'mobile' && screenTypeReady &&
          <Ad
            id={AD_SUPERFOOTER_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isLazy
          />
        }
        {windowType === 'mobile' && screenTypeReady &&
          <Ad
            id={AD_LISTING_1_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isMobInDesk
            isLazy
          />
        }
        <CitiesWeather />

        <GeoModal />
      </div>
    );
  }
}

export const MonthWithoutHocs = MonthPage;
export default withStyles(s)(MonthPage);
