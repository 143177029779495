import {
  FETCH_WEATHER_CITIES,
  FETCH_WEATHER_CITIES_FAILURE,
  FETCH_CITIES_LIST,
  FETCH_CITIES_LIST_FAILURE,
  FETCH_RESORT_CITIES_LIST,
  FETCH_RESORT_CITIES_LIST_FAILURE,
} from 'constants/actions/weatherCities';

export default function weatherCities(state = {}, action) {
  switch (action.type) {
    case FETCH_WEATHER_CITIES:
      return {
        ...state,
        isDataFetched: true,
        searchData:    Object.values(action.payload.data.items).sort((a, b) => b.weight - a.weight),
      };
    case FETCH_WEATHER_CITIES_FAILURE:
      return {
        ...state,
        isDataFetched: false,
        error:         action.payload,
        searchData:    [],
      };
    case FETCH_CITIES_LIST:
      return {
        ...state,
        isDataFetched: true,
        listData:      action.payload.data,
      };
    case FETCH_CITIES_LIST_FAILURE:
      return {
        ...state,
        isDataFetched: false,
        error:         action.payload,
        listData:      {},
      };
    case FETCH_RESORT_CITIES_LIST:
      return {
        ...state,
        isDataFetched:  true,
        resortListData: action.payload.data,
      };
    case FETCH_RESORT_CITIES_LIST_FAILURE:
      return {
        ...state,
        isDataFetched:  false,
        error:          action.payload,
        resortListData: {},
      };
    default:
      return state;
  }
}
