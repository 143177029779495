import {connect} from 'react-redux';
import WeatherReport from './index.js';

const mapStateToProps = state => ({
  data:         state.monthName.monthStat,
  monthName:    state.monthName.monthName,
  town:         state.monthName.town,
  isFirstVisit: state.runtime.pageNumber === 1,
  isNewDesign:  state.runtime.isNewDesign,
});

export default connect(mapStateToProps)(WeatherReport);
