import 'polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import StyleContext from 'isomorphic-style-loader/StyleContext';

import 'core/HTMLNodeRemove.polyfill.js';
import queryString from 'query-string';
import {ThemeProvider, createSheetsRegistry, globalJss} from 'rambler-ui/theme';

import {AdProvider} from '@rambler-components/ad';

import {
  TOP100_DESKTOP_ID,
  TOP100_COMMON_ID,
  TOP100_RAMBLER_ID,
  TOP100_DATASET_DESKTOP,
  YANDEX_METRICS,
} from 'constants/counters';
import {DESKTOP_PADID, PUB_SCOPE} from 'constants/ad';

import initSentry from 'core/sentry/client';

import App from './components/App';
import configureStore from './store/configureStore';
import history from './core/history';
import {updateAds} from './core/updater';
import {updateMeta, updateCustomMeta, updateLink} from './core/DOMUtils';
import router from './router';
import {ErrorReporter, deepForceUpdate} from './core/devUtils';
import top100Wrapper from './core/top100Wrapper';
import YandexEvent from './core/YandexEvent';
import captureException from './core/captureException';
import {setRuntimeVariable} from './actions/runtime';
import {fetchMoonData} from './actions/header';

// Пропатчим начальный стейт, если адблок
// Антиадблок не сразу детектит блокировщики рекламы, поэтому на клиенте на всякий
// случай проверяем сами
if (!window.QW50aS1BZEJsb2Nr) {
  window.APP_STATE.runtime.antiadblockEnabled = false;
}

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const insertCss = __DEV__
  ? (...styles) => {
    // eslint-disable-next-line no-underscore-dangle
    const removeCss = styles.map(x => x._insertCss());
    return () => { removeCss.forEach(f => f()); };
  }
  : () => {};
const context = {
  // Initialize a new Redux store
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store:             configureStore(window.APP_STATE, {history}),
  storeSubscription: null,
};

const {
  runtime,
  config,
} = context.store.getState();

if (!__DEV__) {
  initSentry(runtime, config);
}

// eslint-disable-next-line no-underscore-dangle
window._config_aab = {
  publisherFirstParty: {
    // eslint-disable-next-line no-underscore-dangle
    uid:   runtime.ruid,
    scope: PUB_SCOPE,
  },
};

const container = document.getElementById('app');
let currentLocation = history.location;
let appInstance;

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const sendAntiAdblockInfo = (isAntiadblockEnabled) => {
  top100Wrapper.push(() => {
    window.top100Counter.sendCustomVars({
      antiadblock: isAntiadblockEnabled ? 'on' : 'off',
    });
  });

  (new YandexEvent(YANDEX_METRICS.DESKTOP_UNIQUE)).send({
    type: 'params',
    data: {
      antiadblock: isAntiadblockEnabled ? 'on' : 'off',
      AdBlock:     window.isAdblockEnabled ? 'yes' : 'no',
    },
  });
};

// Re-render the app when window.location changes
async function onLocationChange({location, action}) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve({
      ...context,
      pathname: location.pathname,
      query:    queryString.parse(location.search),
    });

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      if ([404, 500, 502].includes(route.status)) {
        window.location.reload();
        return;
      }

      history.replace(route.redirect);
      return;
    }

    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    // https://rambler-digital-solutions.github.io/rambler-ui/#/install?_k=qdtobk
    const sheetsRegistry = createSheetsRegistry();
    globalJss.setup({insertionPoint: document.getElementById('rui-styles')});

    appInstance = renderReactApp(
      <ThemeProvider sheetsRegistry={sheetsRegistry}>
        <Provider store={context.store}>
          <StyleContext.Provider value={{insertCss}}>
            <AdProvider
              padId={DESKTOP_PADID}
              publisherId={runtime.ruid}
              publisherScope=".rambler.ru"
            >
              <App>{route.component}</App>
            </AdProvider>
          </StyleContext.Provider>
        </Provider>
      </ThemeProvider>,
      container,
      () => {
        context.store.dispatch(setRuntimeVariable({
          name:  'routingEnded',
          value: true,
        }));

        context.store.dispatch(fetchMoonData(config.ENV === 'production'));

        if (isInitialRender) {
          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);

          // создаем счетчик топ100
          top100Wrapper.init({
            projectIds: [
              TOP100_DESKTOP_ID,
              TOP100_COMMON_ID,
              TOP100_RAMBLER_ID,
            ],
            pub_id:             runtime.ruid,
            rambler_id:         runtime.ramblerId,
            attributes_dataset: TOP100_DATASET_DESKTOP,
            splits:             [
              'split_new',
            ],
          });

          sendAntiAdblockInfo(runtime.antiadblockEnabled);

          const eventComplete = 'reactDomComplete';
          try {
            document.dispatchEvent(new Event(eventComplete, {
              bubbles:    false,
              cancelable: false,
            }));
          } catch (e) {
            const ev = document.createEvent('HTMLEvents');
            ev.initEvent(eventComplete, false, false);
            document.dispatchEvent(ev);
          }

          return;
        }

        const {pageIsLoaded} = route.state.runtime;

        document.title = route.meta.title;

        updateMeta('keywords', route.meta.keywords);
        updateMeta('description', route.meta.description);
        updateCustomMeta('og:title', route.meta.title);
        updateCustomMeta('og:description', route.meta.description);
        updateCustomMeta('og:url', route.meta.canonical);
        updateLink('canonical', route.meta.canonical);
        // Update necessary tags in <head> at runtime here, ie:
        // updateMeta('keywords', route.keywords);
        // updateCustomMeta('og:url', route.canonicalUrl);
        // updateCustomMeta('og:image', route.imageUrl);
        // updateLink('canonical', route.canonicalUrl);
        // etc.

        if (pageIsLoaded) {
          updateAds('desktop');
        }

        let scrollX = 0;
        let scrollY = 0;
        const pos = scrollPositionsHistory[location.key];
        if (pos) {
          // eslint-disable-next-line
          scrollX = pos.scrollX;
          // eslint-disable-next-line
          scrollY = pos.scrollY;
        } else {
          const targetHash = location.hash.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }

        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        window.scrollTo(scrollX, scrollY);
      },
    );
  } catch (error) {
    if (__DEV__) {
      appInstance = null;
      document.title = `Error: ${error.message}`;
      ReactDOM.hydrate(<ErrorReporter error={error} />, container);
      throw error;
    }

    console.error(`${new Date()} ${error}`); // eslint-disable-line no-console
    captureException(error);

    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange({location: currentLocation});

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      try {
        // Force-update the whole tree, including components that refuse to update
        deepForceUpdate(appInstance);
      } catch (error) {
        appInstance = null;
        document.title = `Hot Update Error: ${error.message}`;
        ReactDOM.render(<ErrorReporter error={error} />, container);
        return;
      }
    }

    onLocationChange({location: currentLocation});
  });
}
