import {
  FETCH_AGROPROGNOZ_DATA_SUCCESS,
  FETCH_AGROPROGNOZ_DATA_FAILURE,
} from 'constants/actions/agroprognoz';

export default function geomagnetic(state = {}, action) {
  switch (action.type) {
    case FETCH_AGROPROGNOZ_DATA_SUCCESS: {
      const {
        /* eslint-disable camelcase */
        table_data,
        local_now,
        date_weather,
        /* eslint-enable camelcase */
      } = action.payload.data;
      return {
        ...state,
        tableData:   table_data,
        currentDay:  local_now,
        dateWeather: {
          soilTemperature:          date_weather.soil_temp,
          soilMoisture:             date_weather.soil_moisture,
          precipitationProbability: date_weather.precipitation_probability,
          wind:                     {
            speed:     date_weather.wind_speed,
            direction: date_weather.wind_direction,
          },
          pressure: date_weather.pressure_mm,
          sun:      {
            rise: date_weather.sunrise,
            set:  date_weather.sunset,
          },
          moonPhase: date_weather.moon_phase,
        },
      };
    }
    case FETCH_AGROPROGNOZ_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
