import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import ChevronRightIcon from 'rambler-ui/icons/forms/ChevronRightIcon';
import Button from 'rambler-ui/Button';

import Link from 'components/Link';
import ButtonComponent from 'components/Button';
import CitiesSearchNewDesign from 'components/CitiesSearch';
import CitiesSearch from 'Desktop/components/CitiesSearch';

import s from './index.scss';

class CitiesWeather extends React.Component {
  static propTypes = {
    isNewDesign: pt.bool.isRequired,
    listData:    pt.shape({
      country: pt.object.isRequired,
      world:   pt.object.isRequired,
      resorts: pt.object.isRequired,
    }),
    page:     pt.string.isRequired,
    notIndex: pt.bool,
  }

  static defaultProps = {
    listData: null,
    notIndex: false,
  }

  shouldComponentUpdate(nextProps) {
    return this.props.page !== nextProps.page
      || this.props.notIndex !== nextProps.notIndex;
  }

  render() {
    const {
      isNewDesign,
      listData,
      page,
      notIndex,
    } = this.props;

    if (
      !listData
      || !listData.country
      || !listData.world
      || !listData.resorts
    ) {
      return null;
    }

    const Search = isNewDesign ? CitiesSearchNewDesign : CitiesSearch;

    return (
      <div
        className={cn(s.cities, isNewDesign ? s.citiesNewDesign : s.citiesOldDesign)}
        data-block="weather_by"
      >
        {(page !== 'main' || notIndex) &&
          <div
            className={s.hiddenComment}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: '<!--noindex-->'}}
          />
        }
        <h2
          className={cn(
            s.citiesTitle,
            isNewDesign ? s.citiesTitleNewDesign : s.citiesTitleOldDesign,
          )}
        >
          Погода по городам
        </h2>
        <Search top100Attr="weather_by_cities::search" />
        <div className={cn(
          s.citiesList,
          isNewDesign ? s.citiesListNewDesign : s.citiesListOldDesign,
        )}
        >
          <div className={s.citiesListItem}>
            <div className={s.citiesListItemBlock}>
              <h3
                className={cn(
                  s.citiesListItemTitle,
                  isNewDesign ? s.citiesListItemTitleNewDesign : s.citiesListItemTitleOldDesign,
                )}
              >
                <Link
                  className={cn(
                    s.citiesListItemTitleLink,
                    isNewDesign
                      ? s.citiesListItemTitleLinkNewDesign
                      : s.citiesListItemTitleLinkOldDesign,
                  )}
                  to={listData.country.href}
                  data-weather={`${page}::weather_by_cities::russia`}
                >
                  {listData.country.name}
                </Link>
              </h3>
              <div className={s.citiesListItemTowns}>
                {listData.country.towns.map((item, i) => (
                  <Link
                    className={cn(
                      s.citiesListItemTown,
                      isNewDesign ? s.citiesListItemTownNewDesign : s.citiesListItemTownOldDesign,
                    )}
                    to={`/${item.href}/`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`country-${i}`}
                    data-weather={`${page}::weather_by_cities::russia::${item.name}`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            {isNewDesign
              ? (
                <ButtonComponent
                  href={listData.country.href}
                  data-weather={`${page}::weather_by_cities::russia::all_cities_rus`}
                >
                  Все города
                </ButtonComponent>
              )
              : (
                <Button
                  size="small"
                  type="outline"
                  icon={<ChevronRightIcon />}
                  iconPosition="right"
                  container={<Link to={listData.country.href} />}
                  data-weather={`${page}::weather_by_cities::russia::all_cities_rus`}
                >
                  Все города
                </Button>
              )}
          </div>
          <div className={s.citiesListItem}>
            <div className={cn(s.citiesListItemBlock, s.citiesListItemBlockHalf)}>
              <h3
                className={cn(
                  s.citiesListItemTitle,
                  isNewDesign ? s.citiesListItemTitleNewDesign : s.citiesListItemTitleOldDesign,
                )}
              >
                <Link
                  className={cn(
                    s.citiesListItemTitleLink,
                    isNewDesign
                      ? s.citiesListItemTitleLinkNewDesign
                      : s.citiesListItemTitleLinkOldDesign,
                  )}
                  to={listData.world.href}
                  data-weather={`${page}::weather_by_cities::world`}
                >
                  {listData.world.name}
                </Link>
              </h3>
              <div className={s.citiesListItemTowns}>
                {listData.world.towns.map((item, i) => (
                  <Link
                    className={cn(
                      s.citiesListItemTown,
                      isNewDesign ? s.citiesListItemTownNewDesign : s.citiesListItemTownOldDesign,
                    )}
                    to={`/${item.href}/`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`world-${i}`}
                    data-weather={`${page}::weather_by_cities::world::${item.name}`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className={cn(s.citiesListItemBlock, s.citiesListItemBlockHalf)}>
              <h3
                className={cn(
                  s.citiesListItemTitle,
                  isNewDesign ? s.citiesListItemTitleNewDesign : s.citiesListItemTitleOldDesign,
                  s.citiesListItemTitleNoLink,
                )}
              >
                {listData.resorts.name}
              </h3>
              <div className={s.citiesListItemTowns}>
                {listData.resorts.towns.map((item, i) => (
                  <Link
                    className={cn(
                      s.citiesListItemTown,
                      isNewDesign ? s.citiesListItemTownNewDesign : s.citiesListItemTownOldDesign,
                    )}
                    to={`/${item.href}/`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`resorts-${i}`}
                    data-weather={`${page}::weather_by_cities::resort::${item.name}`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            {isNewDesign
              ? (
                <ButtonComponent
                  href={listData.world.href}
                  data-weather={`${page}::weather_by_cities::world::all_countries`}
                >
                  Все страны
                </ButtonComponent>
              )
              : (
                <Button
                  size="small"
                  type="outline"
                  icon={<ChevronRightIcon />}
                  iconPosition="right"
                  container={<Link to={listData.world.href} />}
                  data-weather={`${page}::weather_by_cities::world::all_countries`}
                >
                  Все страны
                </Button>
              )}
          </div>
        </div>
        {(page !== 'main' || notIndex) &&
          <div
            className={s.hiddenComment}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: '<!--/noindex-->'}}
          />
        }
      </div>
    );
  }
}

export const CitiesWeatherWithoutHocs = CitiesWeather;
export default withStyles(s)(CitiesWeather);
