import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import ButtonComponent from '@rambler-components/button';
import ButtonStyles from '@rambler-components/button/styles.css';

import Link from 'components/Link';

import s from './index.scss';

class Button extends React.Component {
  static propTypes = {
    isMobile:  pt.bool,
    href:      pt.string,
    className: pt.string,
    size:      pt.string,
    style:     pt.object,
    children:  pt.oneOfType([
      pt.string,
      pt.node,
    ]).isRequired,
    onClick: pt.func,
  }

  static defaultProps = {
    isMobile:  false,
    href:      null,
    className: '',
    size:      null,
    style:     {},
    onClick:   null,
  }

  render() {
    const {
      isMobile,
      href,
      className,
      size,
      style,
      children,
      onClick,
      ...rest
    } = this.props;

    if (href) {
      return (
        <Link
          to={href}
          className={cn(s.button, className)}
          style={style}
          onClick={onClick}
          {...rest}
        >
          <ButtonComponent
            size={size}
            isMobile={isMobile}
            isRound
          >
            {children}
          </ButtonComponent>
        </Link>
      );
    }

    return (
      <ButtonComponent
        className={cn(s.button, className)}
        size={size}
        isRound
        style={style}
        onClick={onClick}
        isMobile={isMobile}
        {...rest}
      >
        {children}
      </ButtonComponent>
    );
  }
}

export const ButtonWithoutHocs = Button;
export default withStyles(s, ButtonStyles)(Button);
