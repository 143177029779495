import React from 'react';

import fetchMonthData from 'actions/month';
import {
  setRuntimeVariable,
  setRuntimeVariables,
} from 'actions/runtime';
import {
  current as fetchCurrentGeoData,
  autodetect as fetchAutodetectGeoData,
} from 'actions/geo';

async function action({store, path}, {v: cityPrefix, city, location: cityLocation}) {
  const geoid = +store.getState().runtime.geoid || null;
  const location = cityLocation ? `/${encodeURIComponent(cityLocation)}` : '';
  const urlPath = `${cityPrefix}-${encodeURIComponent(city)}${location}`;
  const isMobile = store.getState().runtime.isMobile || false;

  store.dispatch(setRuntimeVariables({
    page:                  'city_month',
    pagePrefix:            cityPrefix,
    weatherText:           '',
    weatherAdditionalText: '',
    isNewDesign:           true,
    cityPeriod:            '30-days',
  }));

  const workers = [
    store.dispatch(fetchMonthData(geoid, urlPath)),
    store.dispatch(fetchAutodetectGeoData()),
    store.dispatch(fetchCurrentGeoData()),
  ];

  await Promise.all(workers);

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let Month;
  let StaticText;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    Month = require('Desktop/pages/Month').default;
    StaticText = require('Desktop/components/StaticText').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    Month = require('Mobile/pages/Month').default;
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.month.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={path}>
        <Month />
        {StaticText && <StaticText text={meta.seoText} />}
      </Layout>
    ),
  };
}

export default {
  path: '/30-days',
  action,
};
