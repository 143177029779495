import {connect} from 'react-redux';
import Home from './index';

const mapStateToProps = ({runtime, home}) => ({
  windowType:      runtime.screen,
  screenTypeReady: runtime.screenTypeReady,
  data:            home.currentWeather,
  today:           home.today,
});

export default connect(mapStateToProps)(Home);
