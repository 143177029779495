import {connect} from 'react-redux';

import Weekend from './index';

const mapStateToProps = state => ({
  town:            state.town.town,
  windowType:      state.runtime.screen,
  screenTypeReady: state.runtime.screenTypeReady,
  rangeData:       state.weekend.rangeData,
  tableData:       state.weekend.tableData,
  currentDay:      state.weekend.currentDay,
});

export default connect(mapStateToProps)(Weekend);
