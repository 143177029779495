import {connect} from 'react-redux';

import Menu from './index.js';

const mapStateToProps = ({runtime, town}) => ({
  page: runtime.page,
  town: town.town,
});

export default connect(mapStateToProps)(Menu);
