import {createSelector} from 'reselect';

import _chunk from 'lodash.chunk';

import {MONTHS_ABBR_GEN} from 'constants/months';

const getData = state => state || [];

export default createSelector(
  [getData],
  (data) => {
    const readyData = [];

    data.forEach((item) => {
      const dayObj = {
        temperatureNight: item.night_temperature,
        temperatureDay:   item.day_temperature,
        humidity:         item.humidity !== null ? item.humidity : 'Нет данных',
        pressure:         item.pressure !== null ? item.pressure : 'Нет данных',
        wind:             item.wind_speed,
        precipitation:    item.precipitation !== null ? item.precipitation : 'Нет данных',
      };

      const day = new Date(item.date);
      const date = `${day.getDate()}`;
      const month = MONTHS_ABBR_GEN[day.getMonth()];

      dayObj.date = `${date} ${month}`;

      readyData.push(dayObj);
    });

    return _chunk(readyData, 5);
  },
);
