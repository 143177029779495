import {connect} from 'react-redux';
import Regions from './index';

const mapStateToProps = state => ({
  country: state.countries.country,
  regions: state.countries.regions,
  page:    state.runtime.page,
});

export default connect(mapStateToProps)(Regions);
