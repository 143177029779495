import {setRuntimeVariable} from 'actions/runtime';

import {fetchTownData} from 'actions/town';

import {
  // current as fetchCurrentGeoData,
  autodetect as fetchAutodetectGeoData,
} from 'actions/geo';

async function action({store, path}) {
  let geoid = +store.getState().runtime.geoid || null;

  if (!geoid) {
    await store.dispatch(fetchAutodetectGeoData());
    geoid = store.getState().geo.auto.id;
  }

  await store.dispatch(fetchTownData(null, geoid));

  const town = {...store.getState().town}.town.url_path;

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  return {
    redirect: `/${town}${path}`,
  };
}

export default {
  path:     '',
  children: [
    {
      path: '/today',
      action,
    },
    {
      path: '/tomorrow',
      action,
    },
    {
      path: '/weekend',
      action,
    },
    {
      path: '/7-days',
      action,
    },
    {
      path: '/14-days',
      action,
    },
  ],
};
