import {
  FETCH_HEADER_STATUS_DATA_SUCCESS,
  FETCH_HEADER_STATUS_DATA_FAILURE,
  FETCH_HEADER_MOON_DATA_SUCCESS,
  FETCH_HEADER_MOON_DATA_FAILURE,
} from 'constants/actions/header';

export default function header(state = [], action) {
  switch (action.type) {
    case FETCH_HEADER_STATUS_DATA_SUCCESS: {
      const data = {...action.payload.data};

      return {
        ...state,
        ...data,
      };
    }
    case FETCH_HEADER_STATUS_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case FETCH_HEADER_MOON_DATA_SUCCESS: {
      const data = {...action.payload.data};

      return {
        ...state,
        ...data,
      };
    }
    case FETCH_HEADER_MOON_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
