import {connect} from 'react-redux';

import Geomagnetic from './index';

const mapStateToProps = state => ({
  windowType:      state.runtime.screen,
  screenTypeReady: state.runtime.screenTypeReady,
  page:            state.runtime.page,
  title:           state.geomagnetic.title,
});

export default connect(mapStateToProps)(Geomagnetic);
