import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import Hint from './Hint';

import s from './index.scss';

class Cities extends React.Component {
  static propTypes = {
    country: pt.shape({
      name:          pt.string.isRequired,
      url_path:      pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
    }),
    cities: pt.objectOf(pt.arrayOf(pt.shape({
      town__name:     pt.string.isRequired,
      town__url_path: pt.string.isRequired,
      icon_name:      pt.string.isRequired,
      temp:           pt.number.isRequired,
    })).isRequired).isRequired,
  }

  static defaultProps = {
    country: null,
  }

  render() {
    const {
      country,
      cities,
    } = this.props;

    if (!country) return null;

    return (
      <div className={s.cities} data-block="weather_sbj">
        <h1 className={s.citiesTitle}>Погода {country.loc_case_name} сегодня</h1>
        <div className={s.citiesList}>
          {Object.keys(cities).map(letter => (
            <div className={s.citiesListItem} key={letter}>
              <h3 className={s.citiesListItemTitle}>{letter}</h3>
              <div className={s.citiesListItemTowns}>
                {cities[letter].map(item => (
                  <Hint city={item} key={item.town__url_path} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const CitiesWithoutHocs = Cities;
export default withStyles(s)(Cities);
