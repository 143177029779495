export const DEFAULT_API_URL = '/api/v3';
export const GEOID_DEFAULT = 54118936;

export const N_DAYS_PATTERN = /^city_(3days|7days|10days|2weeks)$/;

export const HOME_API_URLS = {
  home: '/index/',
};

export const MONTH_API_URLS = {
  home:  '/month/',
  stats: '/stat_monthly/',
};

export const SEARCH_API_URLS = {
  geo:     '/location/geo_list/?s=',
  suggest: '/suggest/?query=',
};

export const GEO_API_URLS = {
  autodetect:  '/location/autodetect',
  current:     '/location/current',
  setLocation: '/location/set.html',
};

export const COUNTRIES_API_URLS = {
  home: '/world/',
};

export const CITIES_LIST_URL = '/top_towns/';

export const RESORT_CITIES_LIST_URL = '/resort_towns/';

export const NOW_URL = '/now/';

export const DAY_URLS = {
  today:       '/today/',
  tomorrow:    '/tomorrow/',
  day:         '/detailed/',
  geomagnetic: '/geomagnetic/',
};

export const NDAYS_URL = '/ndays/';

export const WEEKEND_URL = '/weekend/';

export const INFORMER_URL = '/site_widget/';

export const TOWN_API_URL = '/town/';

export const URL_REGEXP = /(today|tomorrow|weekend|now|geomagnetic|agroprognoz|january|february|march|april|may|june|july|august|september|october|november|december|\d+-.*)/;
