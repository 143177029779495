import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import Tooltip from 'rambler-ui/Tooltip';

import _min from 'lodash.min';
import _max from 'lodash.max';
import _toPairs from 'lodash.topairs';
import _chunk from 'lodash.chunk';

import moment from 'moment';

import WeatherIcon from 'components/WeatherIcon';
import WIND_RUS from 'constants/wind';
import {MONTHS_ABBR} from 'constants/months';

import TableDataPeriods from 'static/tableDataPeriods.json';
import icons from 'static/icons.json';
import GeomagneticData from 'static/geomagnetic.json';
import UvData from 'static/uv.json';

import s from './index.scss';

const DAY_PARTS = ['00:00', '03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00', '24:00'];
const NDAYS_PARTS = ['00:00', '06:00', '12:00', '18:00'];

class TableDataBlock extends React.Component {
  static propTypes = {
    seoDescription: pt.string,
    tableData:      pt.array,
    currentDay:     pt.string.isRequired,
    town:           pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    period: pt.string.isRequired,
  }

  static defaultProps = {
    tableData:      null,
    seoDescription: null,
  }

  state = {
    activeFocusIndex: null,
  }

  onMouseEnterColumn = (index) => {
    this.setState({
      activeFocusIndex: index,
    });
  }

  onMouseLeaveColumn = () => {
    this.setState({
      activeFocusIndex: null,
    });
  }

  getTitle = (type) => {
    const {
      town,
      period,
    } = this.props;

    const townPeriod = `${town.loc_case_name}${period !== 'gardener' ?
      ` ${TableDataPeriods[period]}` :
      ''
    }`;

    switch (type) {
      case 'temperature':
        return `Температура ${townPeriod}`;
      case 'wind':
        return `Ветер ${townPeriod}, м/с`;
      case 'humidity':
        return `Влажность ${townPeriod}, %`;
      case 'pressure':
        return `Давление ${townPeriod}, мм рт. ст.`;
      case 'precipitation':
        return `Осадки ${townPeriod}, мм`;
      case 'geomagnetic':
        return `Магнитные бури ${townPeriod}`;
      case 'uv':
        return `UV-индекс ${townPeriod}`;
      case 'soil_temp':
        return `Температура почвы ${townPeriod}`;
      case 'soil_moisture':
        return `Влажность почвы ${townPeriod}, %`;
      default:
        return '';
    }
  }

  getColumnHeight = (type, data, index) => {
    const {tableData} = this.props;
    const isWind = type === 'wind';

    const dataArr = data.length > 1 ?
      tableData.reduce((memo, day) => {
        const dataType = isWind ? 'wind_speed' : type;
        let dayDataType = [];
        if (day[dataType]) {
          dayDataType = day[dataType];
        }
        return [...memo, ...dayDataType];
      }, []) :
      data;

    let minValue = isWind ? 0 : _min(dataArr);
    let maxValue = _max(dataArr);

    if (type === 'temperature' || type === 'soil_temp') {
      const maxAbsValue = Math.abs(minValue) > Math.abs(maxValue)
        ? Math.abs(minValue)
        : Math.abs(maxValue);
      minValue = -maxAbsValue;
      maxValue = maxAbsValue;
    }
    const diffValue = maxValue - minValue;

    let minHeight = 1;
    if (type === 'temperature' || type === 'soil_temp' || type === 'humidity') {
      minHeight = 10;
    } else if (type === 'pressure') {
      minHeight = 30;
    }
    const maxHeight = 50;
    const diffHeight = maxHeight - minHeight;

    const currValue = dataArr[index];

    if (diffValue === 0) {
      if (type === 'temperature' || type === 'soil_temp') {
        return 10;
      }

      return 1;
    }

    return Math.round(minHeight + (((currValue - minValue) * diffHeight) / diffValue));
  }

  renderTable = (type) => {
    const {
      tableData,
      currentDay,
      period,
      town,
    } = this.props;
    const {activeFocusIndex} = this.state;
    const dataType = type === 'wind' ? 'wind_speed' : type;

    if (tableData[tableData.length - 1][dataType] === null) return null;

    const isNotTodayPage = tableData.length > 1;

    const timeDivider = isNotTodayPage ? 6 : 3;
    const activeTimeIndex = Math.floor(moment(currentDay).hour() / timeDivider);
    const dayPartsTimes = tableData.length > 1 ? NDAYS_PARTS : DAY_PARTS;

    const isTableWithHint = ['geomagnetic', 'uv'].includes(type);
    const isGeomagnetic = type === 'geomagnetic';
    const hintData = isGeomagnetic ? _toPairs(GeomagneticData) : UvData;

    return (
      <div className={s.dataTable} key={`TableDataBlock-${town.geo_id}-${period}-${type}`}>
        <h2 className={s.dataTableTitle}>{this.getTitle(type)}</h2>
        <div className={s.dataTableColumns}>
          {tableData && tableData.map((day, dayIndex) => {
            const key = `${type}-${dayIndex}`;
            const dayDataArray = day[dataType];
            const date = day.date.split('-').reverse();
            const dayMoment = `${date[0]} ${MONTHS_ABBR[date[1] - 1]}`;

            if (dayDataArray && dayDataArray[0] === null) return null;

            return (
              <div
                className={cn(
                  s.dataTableColumnsDay,
                  isNotTodayPage && s.dataTableColumnsDayNdays,
                )}
                key={key}
              >
                {isNotTodayPage && <span className={s.dataTableColumnsDate}>{dayMoment}</span>}
                {dayDataArray && dayDataArray.map((dayData, dataIndex) => {
                  const dataKey = `${key}-${dataIndex}`;
                  const columnKey = `${dayIndex}-${dataIndex}`;

                  return (
                    <div
                      className={cn(
                        s.dataTableColumn,
                        s[`dataTableColumn${type}`],
                        tableData.length === 1 && s.dataTableColumnSingle,
                        dayIndex === 0 && dataIndex === activeTimeIndex && s.dataTableColumnActive,
                        columnKey === activeFocusIndex && s.dataTableColumnActive,
                      )}
                      onMouseEnter={() => this.onMouseEnterColumn(columnKey)}
                      onMouseLeave={this.onMouseLeaveColumn}
                      tabIndex="-1"
                      key={dataKey}
                    >
                      <div className={s.dataTableColumnValue}>
                        {dayData < 0 ? `−${Math.abs(dayData)}` : dayData}
                        {type === 'wind'
                          ? (
                            <span className={s.dataTableColumnWind}>
                              {WIND_RUS[day.wind_direction[dataIndex]]}
                            </span>
                          )
                          : ''
                        }
                        {type === 'temperature' || type === 'soil_temp' ? '°' : ''}
                      </div>
                      {type === 'temperature' &&
                        <Tooltip content={icons[day.icon[dataIndex]]}>
                          <WeatherIcon
                            size={30}
                            type={day.icon[dataIndex]}
                            className={s.dataTableColumnIcon}
                          />
                        </Tooltip>
                      }
                      <div
                        className={s.dataTableColumnGraph}
                        style={{height: `${this.getColumnHeight(type, dayDataArray, (dayIndex * 4) + dataIndex)}px`}}
                      />
                      <div className={s.dataTableColumnTime}>{dayPartsTimes[dataIndex]}</div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {isTableWithHint &&
          <div className={s.dataTableInfo}>
            {_chunk(hintData, 2).map((items, index) => {
              const colKey = `${type}DataTableInfo-col-${index}`;

              return (
                <div className={s.dataTableInfoColumn} key={colKey}>
                  {items && items.map((item, itemIndex) => {
                    const itemKey = `${colKey}-item-${itemIndex}`;

                    return (
                      <div className={s.dataTableInfoItem} key={itemKey}>
                        <span className={s.dataTableInfoItemValue}>
                          {isGeomagnetic ? item[0] : item.value}
                        </span>
                        <span className={s.dataTableInfoItemText}>
                          {isGeomagnetic ? item[1].title : item.title}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        }
      </div>
    );
  }

  render() {
    const {
      tableData,
      seoDescription,
      period,
    } = this.props;

    if (!tableData) return null;
    const isAgroprognoz = period === 'gardener';
    const TABLE_TYPES_LIST = isAgroprognoz ?
      ['temperature', 'soil_temp', 'soil_moisture', 'humidity', 'pressure'] :
      ['temperature', 'wind', 'humidity', 'pressure', 'precipitation', 'geomagnetic', 'uv'];

    return (
      <div id="tableDataBlock" className={s.dataBlock} data-block="table">
        <div className={s.dataTables}>
          {TABLE_TYPES_LIST.map(type => this.renderTable(type))}
        </div>
        {seoDescription && <p className={s.dataSeoText}>{seoDescription}</p>}
      </div>
    );
  }
}

export const TableDataBlockWithoutHocs = TableDataBlock;
export default withStyles(s)(TableDataBlock);
