import fetch from 'core/fetch';

export default function sendMetrics(args) {
  if (window.location.origin === 'https://weather.rambler.ru') {
    fetch('/metrics/', {
      method:      'POST',
      credentials: 'include',
      headers:     {
        Accept:         'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(args),
    });
  }
}
