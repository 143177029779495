import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import _debounce from 'lodash.debounce';

import {URL_REGEXP} from 'constants/index';

import {ComplexSearch, SuggestItem} from 'rambler-ui/ComplexSearch';

import history from 'core/history';
import WeatherIcon from 'components/WeatherIcon';

import SearchIcon from './i/search.svg';
import s from './index.scss';

class CitiesSearch extends React.Component {
  static propTypes = {
    getCities:  pt.func.isRequired,
    searchData: pt.arrayOf(pt.shape({
      city:     pt.string.isRequired,
      day:      pt.number,
      desc:     pt.string,
      region:   pt.string.isRequired,
      type:     pt.string.isRequired,
      url_path: pt.string.isRequired,
      weight:   pt.number.isRequired,
    })),
    page:                   pt.string.isRequired,
    toggleSearchVisibility: pt.func.isRequired,
    top100Attr:             pt.string.isRequired,
    defaultItem:            pt.string,
    autoFocus:              pt.bool,
    isNarrow:               pt.bool,
    routePage:              pt.string.isRequired,
  }

  static defaultProps = {
    searchData:  [],
    defaultItem: '',
    autoFocus:   false,
    isNarrow:    false,
  }

  state = {
    value: '',
  }

  componentDidMount() {
    const {
      defaultItem,
      autoFocus,
    } = this.props;

    if (autoFocus && this.searchWrapper) {
      const input = this.searchWrapper.querySelector('input');
      input && input.focus();
    }

    if (defaultItem) {
      this.requestCityData(defaultItem);
    }
  }

  onSelectItem = (value) => {
    this.setState({value});
  }

  onClickItem = (value) => {
    this.setState({value}, () => this.changeCityInput(value));
  }

  onSearch = _debounce((query) => {
    this.isFirstRequest = false;
    this.requestCityData(query);
  }, 200)

  isFirstRequest = true;

  changeCityInput = (defaultValue) => {
    const {value} = this.state;
    const {routePage} = this.props;

    if (value === 'all-cities') {
      this.props.toggleSearchVisibility(false);
      history.push('/world/');
    } else {
      const resultValue = defaultValue && defaultValue.url_path ? defaultValue : value;
      const shouldAddRoute = routePage.match(URL_REGEXP);

      if (resultValue) {
        history.push(`/${resultValue.url_path}${shouldAddRoute ? routePage : '/'}`);
        this.props.toggleSearchVisibility(false);
        this.setState({value: ''});

        if (this.searchWrapper) {
          const input = this.searchWrapper.querySelector('input');
          input && input.blur();
        }
      }
    }
  }

  requestCityData = (query) => {
    this.props.getCities(query);
  }

  inputValueRenderer = value => value && `${value.city || ''}${value.region ? ',' : ''} ${value.region || ''}`;

  render() {
    const {
      searchData,
      page,
      top100Attr,
      isNarrow,
    } = this.props;

    return (
      <div ref={node => this.searchWrapper = node}>
        <ComplexSearch
          value={this.inputValueRenderer(this.state.value)}
          className={cn(
            s.citiesSearch,
            {[s.citiesSearchWide]: !isNarrow},
          )}
          inputWrapperClassName={s.inputWrapperClassName}
          inputLeftIcon={<SearchIcon />}
          onSearch={this.onSearch}
          onSelectItem={this.onSelectItem}
          onClickItem={value => this.onClickItem(value)}
          onPressEnter={value => this.changeCityInput(value)}
          placeholder="Введите город или место"
          appendToBody={false}
        >
          {searchData && searchData.map((item, i) => (
            <SuggestItem
              className={s.citiesSearchItem}
              highlightedClassName={s.highlightedClassName}
              value={item}
              // eslint-disable-next-line react/no-array-index-key
              key={`${item.city}-${item.weight}-${i}`}
              data-weather={`${page}::${top100Attr}::suggest_${i + 1}`}
            >
              <span className={cn(
                s.citiesSearchItemText,
                s.citiesSearchItemTextNarrow,
              )}
              >
                <span className={s.citiesSearchItemCity}>{item.city}{item.region && ','}</span>
                <span className={s.citiesSearchItemCountry}>&nbsp;{item.region}</span>
              </span>
              <div className={s.citiesSearchItemWeather}>
                <WeatherIcon size={30} type={item.type} className={s.citiesSearchItemIco} />
                <span className={s.citiesSearchItemTemperature}>
                  {item.temperature < 0 ? `−${Math.abs(item.temperature)}` : item.temperature}&deg;
                </span>
              </div>
            </SuggestItem>
          ))}
          {this.isFirstRequest && isNarrow &&
            <SuggestItem
              className={s.citiesSearchItem}
              value="all-cities"
              key="all-cities"
              data-weather={`${page}::${top100Attr}::suggest_all_cities`}
            >
              <div className={s.citiesSearchItemText}>
                <span className={s.citiesSearchItemAll}>Все города</span>
              </div>
            </SuggestItem>
          }
        </ComplexSearch>
      </div>
    );
  }
}

export const CitiesSearchWithoutHocs = CitiesSearch;
export default withStyles(s)(CitiesSearch);
