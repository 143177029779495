import React from 'react';

import fetchNdaysData from 'actions/ndays';
import fetchMonthData from 'actions/month';
import fetchNowData from 'actions/now';
import {
  setRuntimeVariable,
  setRuntimeVariables,
} from 'actions/runtime';
import {
  current as fetchCurrentGeoData,
  autodetect as fetchAutodetectGeoData,
} from 'actions/geo';

async function action({store, path}, {v: cityPrefix, city, location: cityLocation, n: nDays}) {
  const days = parseInt(nDays, 10);
  const period = `${days}-days`;

  const geoid = +store.getState().runtime.geoid || null;
  const location = cityLocation ? `/${encodeURIComponent(cityLocation)}` : '';
  const urlPath = `${cityPrefix}-${encodeURIComponent(city)}${location}`;
  const isMobile = store.getState().runtime.isMobile || false;

  function getPageName() {
    let name = '';

    if (days < 14) {
      name = `city_${days}days`;
    } else if (days === 14) {
      name = 'city_2weeks';
    } else if (days === 30) {
      name = 'city_month';
    }

    return name;
  }

  store.dispatch(setRuntimeVariables({
    page:                  getPageName(),
    pagePrefix:            cityPrefix,
    weatherText:           '',
    weatherAdditionalText: '',
    isNewDesign:           false,
    cityPeriod:            period,
  }));

  const workers = [
    store.dispatch(fetchAutodetectGeoData()),
    store.dispatch(fetchCurrentGeoData()),
  ];

  if (days === 14) {
    workers.push(store.dispatch(fetchNowData(geoid, urlPath, true)));
  }

  if (days < 30) {
    workers.push(store.dispatch(fetchNdaysData(days, geoid, urlPath)));
  } else {
    workers.push(store.dispatch(fetchMonthData()));
  }

  await Promise.all(workers);

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let Ndays;
  let StaticText;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    Ndays = require('Desktop/pages/Ndays').default;
    StaticText = require('Desktop/components/StaticText').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    Ndays = require('Mobile/pages/Ndays').default;
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.ndays.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={path}>
        <Ndays />
        {StaticText && <StaticText text={meta.seoText} />}
      </Layout>
    ),
  };
}

export default {
  path: '/:n-days',
  action,
};
