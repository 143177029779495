import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import sendMetrics from 'core/metrics/metrics';

import Link from 'components/Link';
import WeatherIcon from 'components/WeatherIcon';
import {MONTHS_ENG, MONTHS_GEN, WEEKDAYS} from 'constants/months';

import s from './index.scss';

class WeatherWeek extends React.Component {
  static propTypes = {
    data: pt.arrayOf(pt.shape({
      date:       pt.string.isRequired,
      is_holiday: pt.bool,
      forecast:   pt.shape({
        min_temperature: pt.number.isRequired,
        max_temperature: pt.number.isRequired,
        icon:            pt.string.isRequired,
        wind_speed:      pt.number.isRequired,
        wind_direction:  pt.string.isRequired,
        precipitation:   pt.number.isRequired,
      }),
    })),
    url:          pt.string.isRequired,
    page:         pt.string.isRequired,
    isFirstVisit: pt.bool.isRequired,
  };

  static defaultProps = {
    data: null,
  };

  componentDidMount() {
    if (window.performance) {
      const mountMoment = performance.now();
      const renderTime = Math.round(mountMoment - window.startTime);

      if (this.props.isFirstVisit) {
        sendMetrics({name: 'client_desktop_index_week_time', value: renderTime});
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    return ((this.props.page !== 'main') && (nextProps.page === 'main'))
      || (this.props.url !== nextProps.url);
  }

  getLink = (i, date, url) => {
    if (i === 0) {
      return `/${url}/tomorrow/`;
    }

    return `/${url}/${new Date(date).getDate()}-${MONTHS_ENG[new Date(date).getMonth()]}/`;
  }

  render() {
    const {
      data,
      url,
      page,
    } = this.props;

    return (
      <div className={s.root} data-block="weekly">
        {data &&
          <div className={s.container}>
            <h3 className={s.title}>Погода на неделю</h3>
            <div className={s.week}>
              <div className={s.head}>
                <span className={cn(s.cellFirst, s.headTitle)}>День</span>
                <span className={cn(s.cellSecond, s.headTitle)}>Температура</span>
                <span className={cn(s.cellThird, s.headTitle)}>Осадки</span>
                <span className={cn(s.cellFourth, s.headTitle)}>Ветер</span>
              </div>
              {data.map((item, i) => (
                <Link
                  to={this.getLink(i, item.date, url)}
                  className={cn(
                    s.wrap,
                    i === 0 && s.wrapFirst,
                    item.is_holiday && s.weekend,
                  )}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`weatherWeekDay-${i}`}
                  data-weather={`${page}::week::day_plus_${i + 1}`}
                >
                  <div className={cn(s.cell, s.cellFirst)}>
                    <span className={s.itemTitle}>
                      {i === 0 ? 'Завтра' : WEEKDAYS[new Date(item.date).getDay()]}
                    </span>
                    <span className={s.itemDate}>
                      {new Date(item.date).getDate()}
                      {' '}
                      {MONTHS_GEN[new Date(item.date).getMonth()]}
                    </span>
                  </div>
                  <div className={cn(s.cell, s.cellSecond, s.itemTemperature)}>
                    <WeatherIcon
                      className={s.itemTemperatureIcon}
                      type={item.forecast.icon}
                    />
                    <span className={s.itemTemperatureMax}>
                      {item.forecast.max_temperature < 0 ? `−${Math.abs(item.forecast.max_temperature)}` : item.forecast.max_temperature}&deg;
                    </span>
                    <span className={s.itemTemperatureMin}>
                      {item.forecast.min_temperature < 0 ? `−${Math.abs(item.forecast.min_temperature)}` : item.forecast.min_temperature}&deg;
                    </span>
                  </div>
                  <div className={cn(s.cell, s.cellThird)}>
                    <span className={s.itemPrecipitation}>{item.forecast.precipitation}%</span>
                  </div>
                  <div className={cn(s.cell, s.cellFourth)}>
                    <div className={s.itemWind}>
                      <WeatherIcon
                        type="wind"
                        className={cn(
                          s.itemWindIcon,
                          s[`itemWindIcon--${item.forecast.wind_direction.toLowerCase()}`],
                        )}
                      />
                      <span className={s.itemWindText}>{item.forecast.wind_speed} м/с</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        }
        {!data &&
          <div className={s.noData}>
            Извините, нет подходящих данных.<br />
            Попробуйте обновить страницу
          </div>
        }
      </div>
    );
  }
}

export const WeatherWeekWithoutHocs = WeatherWeek;
export default withStyles(s)(WeatherWeek);
