import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import NewChart from 'components/NewChart';

import s from './index.scss';

class GeomagneticChart extends React.Component {
  static propTypes = {
    className: pt.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const {
      className,
    } = this.props;

    return (
      <div className={cn(s.chart, className)}>
        <div className={s.chartContainer}>
          <NewChart />
        </div>
      </div>
    );
  }
}

export const GeomagneticChartWithoutHocs = GeomagneticChart;
export default withStyles(s)(GeomagneticChart);
