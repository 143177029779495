import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import {URL_REGEXP} from 'constants/index';

import history from 'core/history';

import WeatherIcon from 'components/WeatherIcon';

import IconClear from './i/clear.svg';
import IconSearch from './i/search.svg';
import s from './index.scss';

class Search extends React.Component {
  static propTypes = {
    rootClassName:    pt.string,
    searchClassName:  pt.string,
    suggestClassname: pt.string,
    isBlue:           pt.bool,
    getCities:        pt.func.isRequired,
    searchData:       pt.arrayOf(pt.shape({
      city:     pt.string.isRequired,
      day:      pt.number,
      desc:     pt.string,
      region:   pt.string.isRequired,
      type:     pt.string.isRequired,
      url_path: pt.string.isRequired,
      weight:   pt.number.isRequired,
    })),
    page:                   pt.string.isRequired,
    toggleSearchVisibility: pt.func.isRequired,
    top100Attr:             pt.string.isRequired,
    defaultItem:            pt.string,
    autoFocus:              pt.bool,
    withAllCitiesLink:      pt.bool,
    routePage:              pt.string.isRequired,
  }

  static defaultProps = {
    rootClassName:     '',
    searchClassName:   '',
    suggestClassname:  '',
    isBlue:            false,
    searchData:        [],
    defaultItem:       '',
    autoFocus:         false,
    withAllCitiesLink: false,
  }

  state = {
    query:   '',
    focused: false,
  }

  componentDidMount() {
    const {
      defaultItem,
      autoFocus,
    } = this.props;

    if (autoFocus && this.inputNode) {
      this.inputNode.focus();
    }

    if (defaultItem) {
      this.requestCityData(defaultItem);
    }
  }

  onSelectCity = (item) => {
    const {routePage} = this.props;
    const shouldAddRoute = routePage.match(URL_REGEXP);

    history.push(`/${item.url_path}${shouldAddRoute ? routePage : '/'}`);
    this.props.toggleSearchVisibility(false);
    this.setState({
      query:   '',
      focused: false,
    });
  }

  onSearch = (query) => {
    this.setState({query});
    this.requestCityData(query);
  }

  onChange = (e) => {
    this.onSearch(e.target.value);
  }

  onClear = () => {
    this.onSearch('');
  }

  onFocus = () => {
    this.setState({focused: true});
  }

  onBlur = () => {
    this.setState({focused: false});
  }

  requestCityData = (query) => {
    this.props.getCities(query);
  }

  goToWorld = () => {
    this.props.toggleSearchVisibility(false);
    this.onClear();
    history.push('/world/');
  }

  render() {
    const {
      rootClassName,
      searchClassName,
      suggestClassname,
      isBlue,
      searchData,
      page,
      top100Attr,
      withAllCitiesLink,
    } = this.props;

    const {
      query,
      focused,
    } = this.state;

    const data = query.length ? searchData : [];

    return (
      <div className={cn(s.root, rootClassName)}>
        <div className={cn(s.search, searchClassName)}>
          <input
            type="text"
            value={query}
            className={cn(
              s.input,
              isBlue && s.inputBlue,
            )}
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            placeholder="Введите город или место"
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            ref={node => this.inputNode = node}
          />

          {query.length > 0 && (
            <IconClear
              className={cn(
                s.icon,
                s.iconClear,
                isBlue && s.iconClearBlue,
              )}
              onClick={this.onClear}
            />
          )}
          <IconSearch
            className={cn(
              s.icon,
              s.iconSearch,
              isBlue && s.iconSearchBlue,
            )}
          />
        </div>
        <div className={cn(s.suggest, suggestClassname, focused && s.visible)}>
          {data.map((item, i) => {
            const key = `${item.city}-${item.weight}-${i}`;

            const re = new RegExp(`^${query}`, 'gi');

            const place = `${item.city}, ${item.region}`;
            const text = place.replace(re, `<b>${query}</b>`);

            return (
              <div
                className={s.city}
                key={key}
                data-weather={`${page}::${top100Attr}::suggest_${i + 1}`}
                onMouseDown={() => this.onSelectCity(item)}
              >
                <span
                  className={s.cityText}
                  title={place}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{__html: text}}
                />
                <div className={s.info}>
                  <span className={s.temp}>{item.temperature}</span>
                  <WeatherIcon
                    type={item.type}
                    className={s.sky}
                  />
                </div>
              </div>
            );
          })}
          {withAllCitiesLink && (
            <div
              className={s.city}
              data-weather={`${page}::${top100Attr}::suggest_all_cities`}
              onMouseDown={this.goToWorld}
            >
              <span className={s.cityText}>Все города</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const SearchWithoutHocs = Search;
export default withStyles(s)(Search);
