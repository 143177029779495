import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import Link from 'components/Link';
import CitiesSearch from 'Desktop/components/CitiesSearch';

import {MONTHS_ENG, MONTHS_PREP} from 'constants/months';

import s from './index.scss';

class ResortCitiesWeather extends React.Component {
  static propTypes = {
    listData:  pt.array,
    page:      pt.string.isRequired,
    monthName: pt.string.isRequired,
    notIndex:  pt.bool,
  }

  static defaultProps = {
    listData: null,
    notIndex: false,
  }

  shouldComponentUpdate(nextProps) {
    return this.props.page !== nextProps.page
      || this.props.notIndex !== nextProps.notIndex;
  }

  render() {
    const {
      listData,
      page,
      notIndex,
      monthName,
    } = this.props;

    if (!listData || !listData.length > 0) return null;

    const monthIndex = MONTHS_ENG.indexOf(monthName);
    const month = MONTHS_PREP[monthIndex];

    return (
      <div className={s.cities}>
        {(page !== 'main' || notIndex) &&
          <div
            className={s.hiddenComment}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: '<!--noindex-->'}}
          />
        }
        <h2 className={s.citiesTitle}>Погода в {month} в курортных городах</h2>
        <CitiesSearch top100Attr="weather_by_cities::search" />
        <div className={s.citiesList}>
          <div className={s.citiesListItem}>
            <div className={s.citiesListItemBlock}>
              <div className={s.citiesListItemTowns}>
                {listData.map((item, i) => (
                  <div className={s.citiesListItemTownWrapper} key={item.href}>
                    <Link
                      className={s.citiesListItemTown}
                      to={`/${item.href}`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`country-${i}`}
                      data-weather={`${page}::weather_by_cities::resorts::${item.name}`}
                    >
                      {item.name}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {(page !== 'main' || notIndex) &&
          <div
            className={s.hiddenComment}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: '<!--/noindex-->'}}
          />
        }
      </div>
    );
  }
}

export const ResortCitiesWeatherWithoutHocs = ResortCitiesWeather;
export default withStyles(s)(ResortCitiesWeather);
