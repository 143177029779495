import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import {
  AD_SUPERFOOTER_ID,
  AD_LISTING_1_ID,
} from 'constants/ad';

import Ad from 'components/Ad';
import WeatherWarning from 'components/WeatherWarning';
import WeatherInCity from 'Desktop/components/WeatherInCity';
import WeatherWeek from 'Desktop/components/WeatherWeek';
import GeoModal from 'components/GeoModal';
import CitiesWeather from 'Desktop/components/CitiesWeather';
import SimpleNowBlock from 'Desktop/components/SimpleNowBlock';
import SimplePopularRequests from 'Desktop/components/SimplePopularRequests';
// import Map from 'components/Map';

import s from './index.scss';

class HomePage extends React.Component {
  static propTypes = {
    notIndex:        pt.bool,
    windowType:      pt.string.isRequired,
    screenTypeReady: pt.bool.isRequired,
  }

  static defaultProps = {
    notIndex: false,
  }

  render() {
    const {
      notIndex,
      windowType,
      screenTypeReady,
    } = this.props;

    return (
      <div className={s.home}>
        <WeatherWarning />
        <div className={s.weatherContainer}>
          <WeatherInCity />
          <WeatherWeek />
        </div>
        {windowType !== 'mobile' && screenTypeReady &&
          <Ad
            id={AD_SUPERFOOTER_ID}
            onRenderWrapperStyle={{marginTop: 20}}
            isLazy
          />
        }
        <div className={s.simpleBlocks}>
          <SimpleNowBlock />
          <SimplePopularRequests />
        </div>
        {windowType === 'mobile' && screenTypeReady &&
          <Ad
            id={AD_LISTING_1_ID}
            onRenderWrapperStyle={{marginTop: 20}}
            isMobInDesk
            isLazy
          />
        }
        {/*<Map />*/}
        <CitiesWeather notIndex={notIndex} />

        <GeoModal />
      </div>
    );
  }
}

export const HomeWithoutHocs = HomePage;
export default withStyles(s)(HomePage);
