import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import cn from 'classnames';

import {createSelector} from 'reselect';

import {Tabs, TabsItem} from 'rambler-ui/Tabs';

import s from './index.scss';

const tabsSelector = createSelector(
  [
    (state) => state.runtime.isMobile,
    (state) => state.runtime.page,
  ],
  (
    isMobile,
    pageName,
  ) => ({
    pageName,
    isMobile,
  }),
);

function ChartTabs({currentTab, handleTabChange, tabsClassName, tabsItemClassName, tabsPosition}) {
  useStyles(s);
  const {pageName, isMobile} = useSelector(tabsSelector);

  return (
    <div className={s.tabsWrapper}>
      <Tabs
        className={cn(s.tabs, tabsClassName, isMobile && s.tabsMobile)}
        size="medium"
        position={isMobile ? 'bottom' : tabsPosition}
        value={currentTab}
        onChange={(e, value) => handleTabChange(value)}
      >
        <TabsItem
          className={cn(tabsItemClassName, isMobile && s.tabsMobileItem)}
          value="temperature"
          data-weather={`${pageName}::graph_tab::temperature`}
        >
          Температура
        </TabsItem>
        <TabsItem
          className={cn(tabsItemClassName, isMobile && s.tabsMobileItem)}
          value="humidity"
          data-weather={`${pageName}::graph_tab::humidity`}
        >
          Влажность
        </TabsItem>
        <TabsItem
          className={cn(tabsItemClassName, isMobile && s.tabsMobileItem)}
          value="pressure"
          data-weather={`${pageName}::graph_tab::pressure`}
        >
          Давление
        </TabsItem>
        <TabsItem
          className={cn(tabsItemClassName, isMobile && s.tabsMobileItem)}
          value="windSpeed"
          data-weather={`${pageName}::graph_tab::wind`}
        >
          Ветер
        </TabsItem>
        <TabsItem
          className={cn(tabsItemClassName, isMobile && s.tabsMobileItem)}
          value="precipitation"
          data-weather={`${pageName}::graph_tab::precipitation`}
        >
          Осадки
        </TabsItem>
      </Tabs>
    </div>
  );
}

ChartTabs.propTypes = {
  currentTab:        PropTypes.string.isRequired,
  handleTabChange:   PropTypes.func.isRequired,
  tabsClassName:     PropTypes.string,
  tabsItemClassName: PropTypes.string,
  tabsPosition:      PropTypes.oneOf(['top', 'bottom']),
};

ChartTabs.defaultProps = {
  tabsClassName:     '',
  tabsItemClassName: '',
  tabsPosition:      'top',
};

export default ChartTabs;
