import _min from 'lodash.min';
import _max from 'lodash.max';

const defaultXAxisCategories = ['00', '03', '06', '09', '12', '15', '18', '21', '24'];

const getByType = {
  min: _min,
  max: _max,
};

const getChartData = (chartsData) => {
  if (!chartsData || (Array.isArray(chartsData) && chartsData.length === 0)) {
    return {};
  }

  const prepareData = !Array.isArray(chartsData) ? [chartsData] : chartsData;

  const [initialDayData, initialNightData] = prepareData;
  const dataLength = initialDayData.temperature
    ? initialDayData.temperature.length
    : initialDayData.geomagnetic.length;

  const day = {
    name: 'day',
    data: [],
  };

  const night = {
    name: 'night',
    data: [],
  };

  for (let i = 0; i < dataLength; i++) {
    day.data.push({
      time: defaultXAxisCategories[i],
      date: initialDayData.xAxisCategories
        ? `${initialDayData.xAxisCategories[i].date}${initialDayData.xAxisCategories[i].isHoliday ? ' isHoliday' : ''}`
        : undefined,
      temperature:   initialDayData.temperature?.[i],
      humidity:      initialDayData.humidity?.[i],
      pressure:      initialDayData.pressure?.[i],
      windSpeed:     initialDayData.wind?.speed[i],
      windDirection: initialDayData.wind?.direction[i],
      precipitation: initialDayData.precipitation?.[i],
      icon:          initialDayData.icon?.[i],
      geomagnetic:   initialDayData.geomagnetic?.[i],
    });

    if (initialNightData) {
      night.data.push({
        time: defaultXAxisCategories[i],
        date: initialNightData.xAxisCategories
          ? `${initialNightData.xAxisCategories[i].date}${initialNightData.xAxisCategories[i].isHoliday ? ' isHoliday' : ''}`
          : undefined,
        temperature:   initialNightData.temperature?.[i],
        humidity:      initialNightData.humidity?.[i],
        pressure:      initialNightData.pressure?.[i],
        windSpeed:     initialNightData.wind?.speed[i],
        windDirection: initialNightData.wind?.direction[i],
        precipitation: initialNightData.precipitation?.[i],
        icon:          initialNightData.icon?.[i],
        geomagnetic:   initialNightData.geomagnetic?.[i],
      });
    }
  }

  const getMinMaxData = (type, dataName) => {
    if (
      (!initialDayData.wind && !initialDayData[dataName])
      || (
        dataName === 'geomagnetic' && !initialDayData[dataName]
      )
    ) return [];

    const dayDataOfType = dataName === 'windSpeed' ? initialDayData.wind.speed : initialDayData[dataName];
    let nightDataOfType;

    if (initialNightData) {
      nightDataOfType = dataName === 'windSpeed' ? initialNightData.wind.speed : initialNightData[dataName];
    }

    return [
      getByType[type](dayDataOfType),
      nightDataOfType ? getByType[type](nightDataOfType) : undefined,
    ];
  };

  const allSame = (array) => {
    if (array.length === 0) {
      return undefined;
    }

    const firstElement = array[0];

    for (let i = 1; i < array.length; i++) {
      if (array[i] !== firstElement && array[i] !== null) {
        return false;
      }
    }

    return true;
  };

  const allSameDayNight = (dataName) => {
    if (
      (!initialDayData.wind && !initialDayData[dataName])
    || (
      dataName === 'geomagnetic' && !initialDayData[dataName]
    )
    ) return [];

    const isDaySame = allSame(dataName === 'windSpeed' ? initialDayData.wind.speed : initialDayData[dataName]);
    let isNightSame;

    if (initialNightData) {
      isNightSame = allSame(dataName === 'windSpeed' ? initialNightData.wind.speed : initialNightData[dataName]);
    }

    return [
      isDaySame,
      isNightSame,
    ];
  };

  const result = {
    dayNight: [day, night],
    minValue: {
      temperature:   getMinMaxData('min', 'temperature'),
      humidity:      getMinMaxData('min', 'humidity'),
      pressure:      getMinMaxData('min', 'pressure'),
      windSpeed:     getMinMaxData('min', 'windSpeed'),
      precipitation: getMinMaxData('min', 'precipitation'),
      geomagnetic:   getMinMaxData('min', 'geomagnetic'),
    },
    maxValue: {
      temperature:   getMinMaxData('max', 'temperature'),
      humidity:      getMinMaxData('max', 'humidity'),
      pressure:      getMinMaxData('max', 'pressure'),
      windSpeed:     getMinMaxData('max', 'windSpeed'),
      precipitation: getMinMaxData('max', 'precipitation'),
      geomagnetic:   getMinMaxData('max', 'geomagnetic'),
    },
    sameValues: {
      temperature:   allSameDayNight('temperature'),
      humidity:      allSameDayNight('humidity'),
      pressure:      allSameDayNight('pressure'),
      windSpeed:     allSameDayNight('windSpeed'),
      precipitation: allSameDayNight('precipitation'),
      geomagnetic:   allSameDayNight('geomagnetic'),
    },
  };

  return result;
};

export default getChartData;
