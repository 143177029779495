import moment from 'moment';

import {MONTHS_ABBR} from 'constants/months';

export default (currentDate = null) => {
  const today = currentDate ? moment(currentDate) : moment();
  const currentMonth = today.month();
  const currentDay = today.date();
  const currentYear = today.year();

  const todayText = `${currentDay} ${MONTHS_ABBR[currentMonth]}`;
  const nextMonthIndex = currentMonth === 11 ? 0 : currentMonth + 1;
  const monthFromToday = moment(`${currentDay}.${nextMonthIndex + 1}.${currentYear}`, 'D.M.YYYY');
  let monthFromTodayText = '';
  if (monthFromToday.isValid()) {
    monthFromTodayText = `${monthFromToday.date()} ${MONTHS_ABBR[monthFromToday.month()]}`;
  } else {
    const lastDayOfNextMonth = moment(`1.${nextMonthIndex + 1}.${currentYear}`, 'D.M.YYYY').endOf('month');
    monthFromTodayText = `${lastDayOfNextMonth.date()} ${MONTHS_ABBR[lastDayOfNextMonth.month()]}`;
  }

  return {
    todayText,
    monthFromTodayText,
  };
};
