import {connect} from 'react-redux';

import TableDataBlock from './index';

const mapStateToProps = state => ({
  seoDescription: state.seo.seoDescription,
  town:           state.town.town,
});

export default connect(mapStateToProps)(TableDataBlock);

