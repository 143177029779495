import {createBrowserHistory} from 'history';

// Navigation manager, e.g. history.push('/home')
// https://github.com/mjackson/history

const history = __TEST__
  ? {push: jest.fn()}
  : process.env.BROWSER && createBrowserHistory();

export default history;
