import React from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';

import moment from 'moment';
import 'moment/locale/ru';

import {MONTHS_GEN, WEEKDAYS} from 'constants/months';

import sendMetrics from 'core/metrics/metrics';

import ChangeDay from 'components/ChangeDay';
import WeatherDetailParams from 'components/WeatherDetailParams';
import Link from 'components/Link';
import BigWeatherIconTemper from 'components/BigWeatherIconTemper';

import YandexLogo from 'components/YandexLogo';

import ChangeUserGeo from 'Desktop/components/ChangeUserGeo';
import ChangeCity from 'Desktop/components/ChangeCity';
import WeatherPeriods from 'Desktop/components/WeatherPeriods';

import s from './index.scss';

class TodayBlock extends React.Component {
  static propTypes = {
    prevLink:   pt.string,
    nextLink:   pt.string,
    currentDay: pt.string.isRequired,
    town:       pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    dayParts:              pt.object,
    bigWeatherTemperature: pt.number,
    bigWeatherIcon:        pt.string,
    weatherDetail:         pt.object,
    page:                  pt.string.isRequired,
    isFirstVisit:          pt.bool.isRequired,
    isAgroprognoz:         pt.bool,
  }

  static defaultProps = {
    dayParts:              null,
    prevLink:              null,
    nextLink:              null,
    isAgroprognoz:         false,
    bigWeatherTemperature: null,
    bigWeatherIcon:        null,
    weatherDetail:         null,
  }

  componentDidMount() {
    if (window.performance) {
      const {
        page,
        isFirstVisit,
      } = this.props;
      const mountMoment = performance.now();
      const renderTime = Math.round(mountMoment - window.startTime);

      if (isFirstVisit) {
        sendMetrics({
          name:  'client_desktop_part_today_data_time',
          value: renderTime,
          page,
        });
      }
    }
  }

  getCurrentDateTime = (page, time) => {
    let dateTime = '';

    if (page === 'today') {
      dateTime = moment(time).format('HH:mm');
    } else if (page === 'day') {
      const date = new Date(time);
      const day = date.getDate();
      const month = MONTHS_GEN[date.getMonth()];
      const year = date.getFullYear();

      dateTime = `${day} ${month} ${year}`;
    }

    return dateTime;
  }

  getTitleText = (town, page, time) => `<h1>Погода${
    page === 'agroprognoz' ? ' для садовода' : ''
  } ${town.loc_case_name}${
    page === 'today' ? ` на сегодня</h1>, ${time}` : ''
  }`

  scrollToTable = () => {
    const table = document.getElementById('tableDataBlock');
    if (table) {
      window.scroll({
        // 55 - высота шапки
        top:      window.pageYOffset + (table.getBoundingClientRect().top - 55),
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      town,
      prevLink,
      nextLink,
      currentDay,
      page,
      isAgroprognoz,
      bigWeatherIcon,
      bigWeatherTemperature,
      dayParts,
      weatherDetail,
    } = this.props;

    const time = this.getCurrentDateTime(page, currentDay);
    const currentDate = new Date(currentDay);

    return (
      <div className={s.dayBlock}>
        <div className={s.dayHead} data-block="location">
          <div className={s.dayHeadTop}>
            <div
              className={s.dayTitle}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{__html: this.getTitleText(town, page, time)}}
            />
            <ChangeUserGeo />
          </div>
          {isAgroprognoz ?
            <div
              className={cn(s.fewDaysButton, s.daySwitcher)}
              onClick={this.scrollToTable}
              data-weather={`${page}::more_days`}
            >
              <div className={s.fewDaysButtonBurger} />
              <p className={s.fewDaysButtonText}>Прогноз на несколько дней</p>
            </div> :
            <ChangeDay
              className={s.daySwitcher}
              prevLink={prevLink}
              nextLink={nextLink}
              currentDay={currentDay}
              dataDay={currentDay}
            />
          }
          <ChangeCity className={s.dayLocation} />
        </div>
        {isAgroprognoz &&
          <div className={s.date}>
            {WEEKDAYS[currentDate.getDay()]},
            {' '}
            {currentDate.getDate()} {MONTHS_GEN[currentDate.getMonth()]}
          </div>
        }
        <div className={s.dayDetail} data-block="main">
          <div className={s.dayPeriodsWrapper}>
            {isAgroprognoz &&
              <Link
                to={`/${town.url_path}/now/`}
                data-weather={`${page}::detail_icon`}
              >
                <BigWeatherIconTemper
                  icon={bigWeatherIcon}
                  temperature={bigWeatherTemperature}
                  className={s.nowWeather}
                />
              </Link>
            }
            {dayParts && <WeatherPeriods data={dayParts} className={s.dayPeriods} />}
          </div>
          <div
            className={isAgroprognoz ?
              s.dayParamsWrapperAgroprognoz :
              s.dayParamsWrapperToday
            }
          >
            <WeatherDetailParams
              className={isAgroprognoz ? s.dayParamsAgroprognoz : s.dayParamsDay}
              paramClassName={isAgroprognoz ? s.dayParamAgroprognoz : s.dayParamDay}
              data={weatherDetail}
              showGardenerCalendarLink={!isAgroprognoz}
            />
          </div>
        </div>
        <YandexLogo />
      </div>
    );
  }
}

export const TodayBlockWithoutHocs = TodayBlock;
export default withStyles(s)(TodayBlock);
