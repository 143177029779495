import {
  FETCH_NOW_DATA_SUCCESS,
  FETCH_NOW_DATA_FAILURE,
} from 'constants/actions/now';

export default function now(state = {}, action) {
  switch (action.type) {
    case FETCH_NOW_DATA_SUCCESS:
      return {
        ...state,
        hoursList: action.payload.data.hour_weather,
        today:     action.payload.data.local_now,
        town:      action.payload.data.town,
        periods:   action.payload.data.current_weather.periods,
        now:       {
          temperature: action.payload.data.current_weather.temperature,
          icon:        action.payload.data.current_weather.icon,
          tempFeels:   action.payload.data.current_weather.temp_feels,
          detail:      {
            precipitationProbability: action.payload.data.current_weather.precipitation_probability,
            wind:                     {
              speed:     action.payload.data.current_weather.wind_speed,
              direction: action.payload.data.current_weather.wind_direction,
            },
            pressure:         action.payload.data.current_weather.pressure_mm,
            moonPhase:        action.payload.data.current_weather.moon_phase,
            waterTemperature: action.payload.data.current_weather.temp_water || null,
            uv:               action.payload.data.current_weather.uv,
            geomagnetic:      action.payload.data.current_weather.geomagnetic,
          },
          roadwayVisibility: action.payload.data.current_weather.roadway_visibility,
        },
        seo: action.payload.data.seo,
      };
    case FETCH_NOW_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        data:  {},
      };
    default:
      return state;
  }
}
