import React, {Fragment} from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import _chunk from 'lodash.chunk';

import {MONTHS_GEN, MONTHS_ENG, MONTHS} from 'constants/months';

import Link from 'components/Link';
import WeatherIcon from 'components/WeatherIcon';

import s from './index.scss';

class NearestCities extends React.Component {
  static propTypes = {
    cities: pt.arrayOf(pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number.isRequired,
      temperature:   pt.number.isRequired,
      icon:          pt.string.isRequired,
    }).isRequired),
    town: pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    page:        pt.string.isRequired,
    windowType:  pt.string.isRequired,
    isNewDesign: pt.bool.isRequired,
    cityPeriod:  pt.string.isRequired,
    dataDay:     pt.string,
  }

  static defaultProps = {
    cities:  null,
    dataDay: undefined,
  }

  clickItem = (itemName) => {
    const yaCounter1 = typeof window !== 'undefined' && window.yaCounter29582730;
    const yaCounter2 = typeof window !== 'undefined' && window.yaCounter26557662;

    const params = {
      nearest_cities: {
        choose: [itemName],
      },
    };
    if (yaCounter1) {
      // eslint-disable-next-line no-undef
      ym(29582730, 'reachGoal', 'nearest_cities_choose', params);
    }
    if (yaCounter2) {
      // eslint-disable-next-line no-undef
      ym(26557662, 'reachGoal', 'nearest_cities_choose', params);
    }
  }

  render() {
    const {
      cities,
      town,
      page,
      windowType,
      isNewDesign,
      cityPeriod,
      dataDay,
    } = this.props;

    if (!cities) {
      return null;
    }

    const chunkSize = windowType !== 'normal' ? 4 : 3;

    const getPeriod = () => {
      if (MONTHS_ENG.includes(cityPeriod)) {
        return ` на ${MONTHS[MONTHS_ENG.indexOf(cityPeriod)]} ${(new Date()).getFullYear()}`;
      }

      switch (cityPeriod) {
        case 'today':
          return ' сегодня';
        case 'now':
          return ' сейчас';
        case 'tomorrow':
          return ' на завтра';
        case '3-days':
          return ' на 3 дня';
        case '7-days':
          return ' на неделю';
        case '10-days':
          return ' на 10 дней';
        case '14-days':
          return ' на две недели';
        case '30-days':
          return ' на месяц (30 дней)';
        case 'weekend':
          return ' на выходных';
        default: {
          if (dataDay) {
            const date = new Date(dataDay);
            const day = date.getDate();
            const month = MONTHS_GEN[date.getMonth()];
            const year = date.getFullYear();

            return ` на ${day} ${month} ${year}`;
          } else {
            break;
          }
        }
      }
    };

    return (
      <div
        className={cn(
          s.nearestCities,
          isNewDesign ? s.nearestCitiesNewDesign : s.nearestCitiesOldDesign,
        )}
        data-weather={`${page}::nearest_cities::${town.name}`}
        data-block="nearest"
      >
        <h2 className={isNewDesign
          ? s.nearestCitiesTitleNewDesign
          : s.nearestCitiesTitleOldDesign}
        >
          {town.name} — погода рядом{getPeriod()}
        </h2>
        <div className={s.nearestCitiesList}>
          {_chunk(cities, chunkSize).map((items, index) => (
            <div
              className={cn(
                s.nearestCitiesListItemTowns,
                s.nearestCitiesListItemTownsIcons,
              )}
              // eslint-disable-next-line react/no-array-index-key
              key={`cities-${chunkSize}-${index}`}
            >
              {items.map(item => (
                <div
                  className={cn(
                    s.nearestCitiesListItemTownContainer,
                    isNewDesign
                      ? s.nearestCitiesListItemTownContainerNewDesign
                      : s.nearestCitiesListItemTownContainerOldDesign,
                  )}
                  key={`nearestCities-${chunkSize}-${item.geo_id}`}
                >
                  <Link
                    className={cn(
                      s.nearestCitiesListItemTown,
                      isNewDesign
                        ? s.nearestCitiesListItemTownNewDesign
                        : s.nearestCitiesListItemTownOldDesign,
                    )}
                    to={`/${item.url_path}/${cityPeriod}/`}
                    key={`city-${chunkSize}-${item.geo_id}`}
                    data-weather={`${item.name}`}
                    onClick={() => this.clickItem(item.name)}
                  >
                    <Fragment>
                      <WeatherIcon
                        size={20}
                        type={item.icon}
                        className={s.nearestCitiesListItemTownIcon}
                      />
                      <span className={cn(
                        s.nearestCitiesListItemTownTemp,
                        isNewDesign
                          ? s.nearestCitiesListItemTownTempNewDesign
                          : s.nearestCitiesListItemTownTempOldDesign,
                      )}
                      >
                        {item.temperature < 0 ? `−${Math.abs(item.temperature)}` : item.temperature}&deg;
                      </span>
                    </Fragment>
                    {item.name}
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const CitiesWithoutHocs = NearestCities;
export default withStyles(s)(NearestCities);
