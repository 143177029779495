import {connect} from 'react-redux';
import DayBlock from './index';

const mapStateToProps = state => ({
  day:          state.day.day,
  dayParts:     state.day.dayParts,
  prevLink:     state.day.prevDay,
  nextLink:     state.day.nextDay,
  dataDay:      state.day.dataDay,
  currentDay:   state.day.currentDay,
  chartsData:   state.day.chartsData,
  town:         state.town.town,
  page:         state.runtime.page,
  isFirstVisit: state.runtime.pageNumber === 1,
});

export default connect(mapStateToProps)(DayBlock);

