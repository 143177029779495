export default {
  1: 'Новолуние',
  2: 'Молодая луна',
  3: 'Правая четверть',
  4: 'Прибывающая луна',
  5: 'Полнолуние',
  6: 'Убывающая луна',
  7: 'Последняя четверть',
  8: 'Старая луна',
};
