import React from 'react';
import * as pt from 'prop-types';

import {AD_SUPERFOOTER_ID} from 'constants/ad';

import Ad from 'components/Ad';
import TableDataBlock from 'Desktop/components/TableDataBlock';
import TodayBlock from 'Desktop/components/TodayBlock';
import CitiesWeather from 'Desktop/components/CitiesWeather';
import GeoModal from 'components/GeoModal';

const PERIODS_NAMES = ['night', 'morning', 'afternoon', 'evening'];

class Agroprognoz extends React.Component {
  static propTypes = {
    dateWeather: pt.object.isRequired,
    tableData:   pt.array.isRequired,
    currentDay:  pt.string.isRequired,
  }

  getData() {
    const {tableData, currentDay} = this.props;
    const rawData = {
      temperature: [...tableData[0].temperature, ...tableData[1].temperature],
      icon:        [...tableData[0].icon, ...tableData[1].icon],
    };
    const date = new Date(currentDay);
    const startPosition = Math.floor(date.getHours() / 6);
    let dayParts = {};

    for (let i = startPosition + 1; i < startPosition + 4; ++i) {
      dayParts = {
        ...dayParts,
        [PERIODS_NAMES[i % 4]]: {
          temperature: rawData.temperature[i],
          icon:        rawData.icon[i],
        },
      };
    }
    return {
      bigWeatherTemperature: rawData.temperature[startPosition],
      bigWeatherIcon:        rawData.icon[startPosition],
      dayParts,
    };
  }

  render() {
    const {
      tableData,
      dateWeather,
      currentDay,
    } = this.props;
    return (
      <div>
        <TodayBlock
          isAgroprognoz
          {...this.getData()}
          weatherDetail={dateWeather}
          currentDay={currentDay}
        />
        <Ad
          id={AD_SUPERFOOTER_ID}
          onRenderWrapperStyle={{marginTop: '15px'}}
          isLazy
        />
        <TableDataBlock
          tableData={tableData}
          currentDay={currentDay}
          period="gardener"
        />
        <CitiesWeather />

        <GeoModal />
      </div>
    );
  }
}

export default Agroprognoz;
