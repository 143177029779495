import React, {Fragment} from 'react';
import * as pt from 'prop-types';
import compose from 'recompose/compose';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import onClickOutside from 'core/HOCs/onClickOutside';

import CitiesSearchNewDesign from 'components/CitiesSearch';
import CitiesSearch from 'Desktop/components/CitiesSearch';

import LocationIcon from './i/location.svg';

import s from './index.scss';

class ChangeCity extends React.Component {
  static propTypes = {
    isNewDesign:            pt.bool.isRequired,
    toggleSearchVisibility: pt.func.isRequired,
    className:              pt.string,
    isSearchVisible:        pt.bool.isRequired,
    town:                   pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    page: pt.string.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  shouldComponentUpdate(nextProps) {
    return (this.props.isSearchVisible !== nextProps.isSearchVisible)
      || (this.props.page !== nextProps.page)
      || (this.props.town.url_path !== nextProps.town.url_path);
  }

  handleClickOutside = () => {
    const {isSearchVisible} = this.props;

    if (isSearchVisible) {
      this.props.toggleSearchVisibility(false);
    }
  }

  render() {
    const {
      isNewDesign,
      toggleSearchVisibility,
      className,
      isSearchVisible,
      town,
      page,
    } = this.props;

    const text = isNewDesign
      ? 'Поиск города или места'
      : (
        <Fragment>
          <LocationIcon className={s.settingsIcon} /> Поиск города или места
        </Fragment>
      );

    const Search = isNewDesign
      ? (
        <CitiesSearchNewDesign
          top100Attr="region_other"
          autoFocus
          withAllCitiesLink
        />
      )
      : (
        <CitiesSearch
          defaultItem={town.name}
          top100Attr="region_other"
          isNarrow
          autoFocus
        />
      );

    return (
      <div className={cn(
        s.settings,
        isNewDesign ? s.settingsNewDesign : s.settingsOldDesign,
        className,
      )}
      >
        <div className={s.buttonsBlock}>
          {isSearchVisible &&
            <div className={s.citiesSelect} ref={node => this.rootNode = node}>
              {Search}
            </div>
          }
          <button
            className={cn(
              s.settingsLink,
              isNewDesign ? s.settingsLinkNewDesign : s.settingsLinkOldDesign,
            )}
            onClick={() => toggleSearchVisibility(true)}
            data-weather={`${page}::region_other`}
          >
            {text}
          </button>
        </div>
      </div>
    );
  }
}

const enhance = compose(
  withStyles(s),
  onClickOutside,
);

export const ChangeCityWithoutHocs = ChangeCity;
export default enhance(ChangeCity);
