/* eslint-disable no-underscore-dangle */
import hoistNonReactStatic from 'hoist-non-react-statics';
import React from 'react';

//ВНИМАНИЕ! чтоб этот ХОК работал, надо повесить на контейнер ref={node=>this.rootNode = node}

export default function enhanceWithClickOutside(WrappedComponent) {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  class EnhancedComponent extends React.Component {
    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside, true);
      document.addEventListener('touchstart', this.handleClickOutside, true);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside, true);
      document.removeEventListener('touchstart', this.handleClickOutside, true);
    }

    handleClickOutside = (e) => {
      const domNode = this.__domNode;
      const wrappedComponent = this.__wrappedComponent;
      if (
        (!domNode || !domNode.contains(e.target)) &&
        typeof wrappedComponent.handleClickOutside === 'function'
      ) {
        wrappedComponent.handleClickOutside(e);
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          ref={(c) => {
            if (c !== null) {
              this.__wrappedComponent = c;
              this.__domNode = c.rootNode;
            }
          }}
        />
      );
    }
  }

  EnhancedComponent.displayName = `Wrapped${componentName}`;

  return hoistNonReactStatic(EnhancedComponent, WrappedComponent);
}
