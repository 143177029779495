export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_RUNTIME_VARIABLES = 'SET_RUNTIME_VARIABLES';
export const FETCH_RUNTIME_ADMIN_VARIABLES = 'FETCH_RUNTIME_ADMIN_VARIABLES';
export const FETCH_RUNTIME_ADMIN_TIMERS = 'FETCH_RUNTIME_ADMIN_TIMERS';
export const INCREMENT_RUNTIME_VARIABLE = 'INCREMENT_RUNTIME_VARIABLE';
export const FETCH_RUNTIME_ADMIN_VARIABLES_FAILURE = 'FETCH_RUNTIME_ADMIN_VARIABLES_FAILURE';
export const FETCH_RUNTIME_ADMIN_TIMERS_FAILURE = 'FETCH_RUNTIME_ADMIN_TIMERS_FAILURE';
export const SET_UI_STATE = 'SET_UI_STATE';
export const SET_ADBLOCK_STATE = 'SET_ADBLOCK_STATE';
export const SET_AD_STATE = 'SET_AD_STATE';
