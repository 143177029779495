import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import WarningIcon from './i/alert.svg';
import WarningIconNewDesign from './i/alertNewDesign.svg';
import s from './index.scss';

class WeatherWarning extends React.Component {
  static propTypes = {
    isMobile:    pt.bool,
    warning:     pt.string,
    isNewDesign: pt.bool.isRequired,
  }

  static defaultProps = {
    isMobile: false,
    warning:  '',
  }

  render() {
    const {
      isMobile,
      warning,
      isNewDesign,
    } = this.props;

    if (warning.length === 0) return null;

    let icon = isNewDesign
      ? (<WarningIconNewDesign className={s.warningIcon} />)
      : (<WarningIcon className={s.warningIcon} />);

    if (isMobile && isNewDesign) {
      icon = null;
    }

    return (
      <div className={
        cn(
          s.warning,
          isMobile && s.warningMobile,
          isNewDesign ? s.warningNewDesign : s.warningOldDesign,
        )}
      >
        {icon}
        <span className={s.warningText}>{warning}</span>
      </div>
    );
  }
}

export const WeatherWarningWithoutHocs = WeatherWarning;
export default withStyles(s)(WeatherWarning);
