import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import moment from 'moment';
import 'moment/locale/ru';

import Link from 'components/Link';

import {MONTHS_GEN, WEEKDAYS} from 'constants/months';

import ArrowIcon from './arrow.svg';
import s from './index.scss';

class ChangeDay extends React.Component {
  static propTypes = {
    prevLink:      pt.string,
    nextLink:      pt.string,
    currentDay:    pt.string.isRequired,
    dataDay:       pt.string.isRequired,
    className:     pt.string,
    textClassName: pt.string,
    town:          pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    page: pt.string.isRequired,
  }

  static defaultProps = {
    className:     '',
    textClassName: '',
    prevLink:      null,
    nextLink:      null,
  }

  shouldComponentUpdate(nextProps) {
    const currentProps = this.props;

    return (currentProps.prevLink !== nextProps.prevLink)
      || (currentProps.town.url_path !== nextProps.town.url_path);
  }

  getSlug = (addedDay, currentDay, dataDay) => {
    const currentDate = moment(currentDay);
    const day = moment(dataDay);
    const linkDate = day.clone().add(addedDay, 'days');
    let text = '';

    if (currentDate.isSame(linkDate, 'day')) {
      text = 'today';
    } else if (currentDate.add(1, 'days').isSame(linkDate, 'day')) {
      text = 'tomorrow';
    } else {
      text = linkDate.locale('en').format('D-MMMM').toLowerCase();
    }

    return text;
  }

  render() {
    const {
      prevLink,
      nextLink,
      currentDay,
      dataDay,
      className,
      textClassName,
      town,
      page,
    } = this.props;
    const currentDate = new Date(dataDay);

    return (
      <div className={cn(s.changer, className)}>
        {prevLink &&
          <Link
            className={s.changerPrev}
            to={`/${town.url_path}/${this.getSlug(-1, currentDay, dataDay)}/`}
            data-weather={`${page}::date_nav::prev`}
          >
            <ArrowIcon className={s.changerPrevIcon} />
          </Link>
        }
        {!prevLink && <ArrowIcon className={cn(s.changerPrevIcon, s.changerDisabled)} />}
        <span className={cn(s.changerCurrent, textClassName)}>
          {WEEKDAYS[currentDate.getDay()]},
          {' '}
          {currentDate.getDate()} {MONTHS_GEN[currentDate.getMonth()]}
        </span>
        {nextLink &&
          <Link
            className={s.changerNext}
            to={`/${town.url_path}/${this.getSlug(1, currentDay, dataDay)}/`}
            data-weather={`${page}::date_nav::next`}
          >
            <ArrowIcon className={s.changerNextIcon} />
          </Link>
        }
        {!nextLink && <ArrowIcon className={cn(s.changerNextIcon, s.changerDisabled)} />}
      </div>
    );
  }
}

export const ChangeDayWithoutHocs = ChangeDay;
export default withStyles(s)(ChangeDay);
