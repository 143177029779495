import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import equal from 'deep-equal';

import _map from 'lodash.map';

import moment from 'moment';
import 'moment/locale/ru';

import moonPhases from 'constants/moonPhases';

import Link from 'components/Link';
import icons from 'components/WeatherDetailParamsIcons/icons';
import iconsNewDesign from 'components/WeatherDetailParamsIcons/iconsNewDesign';

import Geomagnetic from 'static/geomagnetic.json';

import s from './index.scss';

class WeatherDetailParams extends React.Component {
  static propTypes = {
    isNewDesign:              pt.bool.isRequired,
    data:                     pt.object,
    className:                pt.string,
    paramClassName:           pt.string,
    page:                     pt.string.isRequired,
    showGardenerCalendarLink: pt.bool,
    moonTop100:               pt.string,
    isMobile:                 pt.bool.isRequired,
    town:                     pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    alwaysShowPrecipitationProbability: pt.bool,
  }

  static defaultProps = {
    data:                               null,
    className:                          '',
    paramClassName:                     '',
    showGardenerCalendarLink:           false,
    moonTop100:                         null,
    alwaysShowPrecipitationProbability: false,
  }

  shouldComponentUpdate(nextProps) {
    // page можно не проверять, т.к. при обновлении data новая (текущая) подтянется и так
    return !equal(this.props.data, nextProps.data, {strict: true})
      || this.props.town !== nextProps.town;
  }

  getParamText = (name, value) => {
    const {isMobile} = this.props;

    switch (name) {
      case 'precipitationProbability': {
        if (value === 0) {
          return 'Без осадков';
        }

        return isMobile ?
          `Вероятность осадков ${value}%` :
          (
            <span>
              <span className={s.paramTextWide}>Вероятность осадков</span>
              <span className={s.paramTextNarrow}>Осадки</span>
              {' '}
              {value}%
            </span>
          );
      }
      case 'precipitation': {
        return `Осадки за месяц ${value} мм`;
      }
      case 'wind': {
        return `Ветер ${value.speed} м/с`;
      }
      case 'windSpeed': {
        return `Скорость ветра ${value} м/с`;
      }
      case 'minTemperature': {
        return isMobile ?
          `Мин. температура воздуха ${value < 0 ? `−${Math.abs(value)}` : value}°` :
          (
            <span>
              <span className={s.paramTextWide}>Мин. температура воздуха</span>
              <span className={s.paramTextNarrow}>Мин. температура</span>
              {' '}
              {value < 0 ? `−${Math.abs(value)}` : value}°
            </span>
          );
      }
      case 'maxTemperature': {
        return isMobile ?
          `Макс. температура воздуха ${value < 0 ? `−${Math.abs(value)}` : value}°` :
          (
            <span>
              <span className={s.paramTextWide}>Макс. температура воздуха</span>
              <span className={s.paramTextNarrow}>Макс. температура</span>
              {' '}
              {value < 0 ? `−${Math.abs(value)}` : value}°
            </span>
          );
      }
      case 'humidity': {
        return `Влажность ${value}%`;
      }
      case 'pressure': {
        return `Давление ${value} мм`;
      }
      case 'rise': {
        const date = moment(value);
        return `Восход ${date.format('HH:mm')}`;
      }
      case 'set': {
        const date = moment(value);
        return `Закат ${date.format('HH:mm')}`;
      }
      case 'daylight': {
        const hours = Math.floor(value / 60 / 60);
        const minutes = (value / 60) % 60;
        return `Световой день ${hours} ч ${minutes} мин`;
      }
      case 'moonPhase': {
        const phase = Math.ceil(value / 45);

        return moonPhases[phase > 0 ? phase : 1];
      }
      case 'waterTemperature': {
        return `Температура воды ${value < 0 ? `−${Math.abs(value)}` : value}°`;
      }
      case 'uv': {
        return `UV-индекс ${value}`;
      }
      case 'sadovod': {
        return 'Календарь садовода';
      }
      case 'soilTemperature':
        return `Температура почвы ${value < 0 ? `−${Math.abs(value)}` : value}°`;
      case 'soilMoisture': {
        return `Влажность почвы ${value}%`;
      }
      default:
        return '';
    }
  }

  getParamIcon = (name, value) => {
    const {isNewDesign} = this.props;

    let Element = null;
    let phase = 0;
    const isMoon = name === 'moonPhase';
    const isWind = name === 'wind';

    const neededIcons = isNewDesign ? iconsNewDesign : icons;

    if (isMoon) {
      phase = Math.ceil(value / 45);
      Element = neededIcons[`${name}${phase > 0 ? phase : 1}`];
    } else {
      Element = neededIcons[name];
    }

    return (Element && <Element className={
      cn(
        s.paramIcon,
        isNewDesign ? s.paramIconNewDesign : s.paramIconOldDesign,
        name,
        isWind && [s[`param${name}${value.direction}`]],
      )
    }
    />);
  }

  render() {
    const {
      isNewDesign,
      data,
      className,
      paramClassName,
      page,
      showGardenerCalendarLink,
      moonTop100,
      town,
      alwaysShowPrecipitationProbability,
      isMobile,
    } = this.props;

    const paramFinalClassName = (name = '', isHidden = false) => cn(
      s.param,
      name,
      isHidden && s.hidden,
      isMobile && s.mobile,
      isNewDesign ? s.paramNewDesign : s.paramOldDesign,
      paramClassName,
    );

    const paramTextClassName = cn(
      s.paramText,
      isMobile && s.mobile,
      isNewDesign ? s.paramTextNewDesign : s.paramTextOldDesign,
    );

    const paramLinkClassName = (name = '') => cn(
      s.param,
      isNewDesign ? s.paramNewDesign : s.paramOldDesign,
      name,
      s.paramLink,
      isNewDesign ? s.paramLinkNewDesign : s.paramLinkOldDesign,
    );

    return (
      <div className={
        cn(
          s.params,
          isMobile && s.mobile,
          isNewDesign ? s.paramsNewDesign : s.paramsOldDesign,
          className,
        )}
      >
        {data && _map(data, (val, name) => {
          if (val === null) {
            return null;
          }

          if (name === 'sun') {
            if (!val.rise && !val.set) return null;

            return (
              <div
                className={cn(s.paramCombined, paramClassName, isMobile && s.mobile)}
                key="sun"
              >
                {_map(val, (sunVal, sunName) => (
                  <div
                    className={paramFinalClassName(sunName, !sunVal)}
                    key={sunName}
                  >
                    {this.getParamIcon(sunName, sunVal)}
                    <span className={paramTextClassName}>
                      {this.getParamText(sunName, sunVal)}
                    </span>
                  </div>
                ))}
              </div>
            );
          }

          if (name === 'moonPhase') {
            return (
              <div
                className={paramFinalClassName()}
                key={name}
              >
                <a
                  className={paramLinkClassName(name)}
                  href="https://horoscopes.rambler.ru/moon/calendar/?utm_source=weather&utm_medium=link&utm_campaign=self_promo&utm_content=horoscopes&utm_term=moon_detailed"
                  target="_blank"
                  data-weather={moonTop100 || `${page}::info::moon`}
                >
                  {this.getParamIcon(name, val)}
                  <span className={paramTextClassName}>
                    {this.getParamText(name, val)}
                  </span>
                </a>
              </div>
            );
          }

          if (name === 'geomagnetic' && val >= 0) {
            return (
              <div
                className={paramFinalClassName()}
                key={name}
              >
                <Link
                  className={paramLinkClassName()}
                  to={`/${town.url_path}/geomagnetic/`}
                  data-weather={`${page}::info::geomagnetic`}
                >
                  {this.getParamIcon(name, val)}
                  <span className={paramTextClassName}>
                    {Geomagnetic[val].fullTitle}
                  </span>
                </Link>
              </div>
            );
          }

          if (
            val
            || name === 'minTemperature'
            || name === 'maxTemperature'
            || (alwaysShowPrecipitationProbability && name === 'precipitationProbability')
          ) {
            return (
              <div
                className={paramFinalClassName(name)}
                key={name}
              >
                {this.getParamIcon(name, val)}
                <span className={paramTextClassName}>
                  {this.getParamText(name, val)}
                </span>
              </div>
            );
          }

          return null;
        })}

        {showGardenerCalendarLink &&
          (
            <div className={paramFinalClassName()}>
              <a
                className={paramLinkClassName()}
                href="https://horoscopes.rambler.ru/sadovod/?utm_source=weather&utm_medium=link&utm_campaign=self_promo&utm_content=horoscopes&utm_term=sadovod_detailed"
                target="_blank"
                data-weather={`${page}::info::sadovod`}
              >
                {this.getParamIcon('sadovod')}
                <span className={paramTextClassName}>{this.getParamText('sadovod')}</span>
              </a>
            </div>
          )
        }
      </div>
    );
  }
}

export const WeatherDetailParamsWithoutHocs = WeatherDetailParams;
export default withStyles(s)(WeatherDetailParams);
