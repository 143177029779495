import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import WeatherIcon from 'components/WeatherIcon';
import periodTitles from 'static/periodTitles';

import s from './index.scss';

class WeatherPeriodsLine extends React.Component {
  static propTypes = {
    data:      pt.object.isRequired,
    className: pt.string,
  }

  static defaultProps = {
    className: '',
  }

  render() {
    const {
      data,
      className,
    } = this.props;
    const periodsData = Object.keys(data);

    return (
      <div className={cn(s.period, className)}>
        {periodsData.map(key => (
          <div
            className={cn(s.periodItem, [s[`periodItem${periodsData.length}`]])}
            key={`periods-${key}`}
          >
            <div className={s.periodTitle}>{periodTitles[key]}</div>
            <div className={s.periodParameters}>
              <WeatherIcon
                size={30}
                type={data[key].icon}
                className={s.periodIcon}
              />
              <span
                className={s.periodTemperature}
              >
                {data[key].temperature < 0 ? `−${Math.abs(data[key].temperature)}` : data[key].temperature}&deg;
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export const WeatherPeriodsLineWithoutHocs = WeatherPeriodsLine;
export default withStyles(s)(WeatherPeriodsLine);
