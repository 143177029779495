import React from 'react';

import {
  setRuntimeVariable,
  setRuntimeVariables,
} from 'actions/runtime';
import fetchCountriesData from 'actions/countries';

import region from './region';

async function action({store, path}, params) {
  const geoid = +store.getState().runtime.geoid || null;
  const isMobile = store.getState().runtime.isMobile || false;

  const isCountryWithRegions = ['rossiya', 'usa', 'belarus', 'indoneziya', 'kitay', 'ukraina'].includes(params.country);

  store.dispatch(setRuntimeVariables({
    page:                  isCountryWithRegions ? 'list_districts' : 'list_cities',
    weatherText:           '',
    weatherAdditionalText: '',
    isNewDesign:           false,
  }));

  await store.dispatch(fetchCountriesData(geoid, params.country));

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let Page;
  let StaticText;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    StaticText = require('Desktop/components/StaticText').default;
    if (isCountryWithRegions) {
      Page = require('Desktop/pages/Countries/Regions').default;
    } else {
      Page = require('Desktop/pages/Countries/Cities').default;
    }
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    if (isCountryWithRegions) {
      Page = require('Mobile/pages/Countries/Regions').default;
    } else {
      Page = require('Mobile/pages/Countries/Cities').default;
    }
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.countries.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={path}>
        <Page />
        {StaticText && <StaticText text={meta.seoText} />}
      </Layout>
    ),
  };
}

export default {
  path:     '/:country',
  children: [
    {
      path: '',
      action,
    },
    region,
  ],
};
