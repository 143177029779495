const getText = (currentTab, value) => {
  if (currentTab === 'temperature') {
    return `${value < 0 ? `–${Math.abs(value)}` : value}°`;
  } else if (currentTab === 'humidity') {
    return `${value}%`;
  } else if (currentTab === 'pressure' || currentTab === 'precipitation') {
    return `${value} мм`;
  } else if (currentTab === 'windSpeed') {
    return `${value} м/с`;
  }

  return `${value}`;
};

export default getText;
