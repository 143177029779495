import {connect} from 'react-redux';

import Now from './index';

const mapStateToProps = state => ({
  windowType:        state.runtime.screen,
  screenTypeReady:   state.runtime.screenTypeReady,
  roadwayVisibility: state.now.now.roadwayVisibility,
});

export default connect(mapStateToProps)(Now);
