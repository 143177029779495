import {connect} from 'react-redux';
import Breadcrumbs from './index.js';

const mapStateToProps = ({breadcrumbs, runtime}) => ({
  breadcrumbs:     breadcrumbs.breadcrumbs,
  isWithMicrodata: breadcrumbs.isWithMicrodata,
  page:            runtime.page,
});

export default connect(mapStateToProps)(Breadcrumbs);
