export const GEO_MODAL_OPTION_AUTO = '1';
export const GEO_MODAL_OPTION_MANUAL = '2';
export const SET_GEO_LOCATION = 'SET_GEO_LOCATION';
export const FETCH_GEO_AUTODETECT_DATA = 'FETCH_GEO_AUTODETECT_DATA';
export const FETCH_GEO_AUTODETECT_DATA_FAILURE = 'FETCH_GEO_AUTODETECT_DATA_FAILURE';
export const FETCH_GEO_CURRENT_DATA = 'FETCH_GEO_CURRENT_DATA';
export const FETCH_GEO_CURRENT_DATA_FAILURE = 'FETCH_GEO_CURRENT_DATA_FAILURE';
export const SET_GEO_CURRENT_DATA_FAILURE = 'SET_GEO_CURRENT_DATA_FAILURE';
export const FETCH_GEO_SUGGEST_DATA = 'FETCH_GEO_SUGGEST_DATA';
export const FETCH_GEO_SUGGEST_DATA_FAILURE = 'FETCH_GEO_SUGGEST_DATA_FAILURE';
export const CLEAR_GEO_SUGGEST_DATA = 'CLEAR_GEO_SUGGEST_DATA';
export const GEO_SUGGEST_LIMIT = 7;
