import {createSelector} from 'reselect';

const getMonthsData = state => state || [];

export default createSelector(
  [getMonthsData],
  (data) => {
    const sectionOrder = ['day', 'night'];
    const chartsData = [];

    // eslint-disable-next-line
    for (const section of sectionOrder) {
      const params = {
        temperature:     [],
        icon:            [],
        xAxisCategories: [],
      };

      // eslint-disable-next-line
      for (const day of data) {
        if (day.is_prev_date) continue;

        params.xAxisCategories.push({
          date:      day.date,
          isHoliday: day.is_holiday,
        });

        params.icon.push(day.icon);
        params.temperature.push(day[`${section}_temperature`]);
      }

      chartsData.push({
        temperature:     params.temperature,
        icon:            params.icon,
        xAxisCategories: params.xAxisCategories,
      });
    }

    return chartsData;
  },
);
