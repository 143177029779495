import _debounce from 'lodash.debounce';

import {incrementRuntimeVariable} from 'actions/runtime';
import {current} from 'actions/geo';
import {fetchHomeData} from 'actions/home';

import AABWrapper from 'core/aabWrapper';
import {brandingCleaner} from 'core/brandingCleaner';

import {YANDEX_METRICS} from 'constants/counters';
import top100Wrapper from 'core/top100Wrapper';

//TODO: вообще перенести в экшоны
/* eslint-disable no-restricted-globals */
let referer = __CLIENT__ ? location.pathname : '';

const ADS_RELOAD_DEBOUNCE = 100;

export const updateCounters = (version) => {
  if (window.ga) {
    window.ga('send', 'pageview');
  }

  top100Wrapper.trackPageview();

  const yaVersionVal = version === 'desktop'
    ? [YANDEX_METRICS.DESKTOP_UNIQUE]
    : [YANDEX_METRICS.MOBILE_UNIQUE, YANDEX_METRICS.MOBILE_PORTAL];

  const ids = [
    YANDEX_METRICS.COMMON,
    YANDEX_METRICS.PORTAL,
    ...yaVersionVal,
  ];

  window.ym && ids.forEach((id) => {
    const counter = window[`yaCounter${id}`];
    if (!counter) return;
    window.ym(id, 'hit', window.location.pathname, {referer});
  });

  if (window.tnsCounterRambler_ru && window.tnsID) {
    window.tnsCounterRambler_ru.hit(window.tnsID, location.pathname);
  }
  referer = location.pathname;
};

export const updateAds = _debounce((version) => {
  updateCounters(version);
  if (window.isAdblockEnabled) {
    // обновляем вызовом антиадблока, если есть адблок
    AABWrapper.reconnect();
  }
}, ADS_RELOAD_DEBOUNCE);

export const updateHome = (geoID, version) => (
  async(dispatch) => {
    brandingCleaner();
    await dispatch(incrementRuntimeVariable('pageNumber'));
    geoID && await dispatch(fetchHomeData(null, geoID, true));
    await dispatch(current());
    updateAds(version);
  }
);

