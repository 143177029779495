import {connect} from 'react-redux';

import getTableData from 'selectors/month/tableData';

import WeatherMonthNameTable from './index.js';

const mapStateToProps = state => ({
  weeks:     getTableData(state.monthName.monthList),
  town:      state.town.town,
  page:      state.runtime.page,
  monthName: state.monthName.monthName,
});

export default connect(mapStateToProps)(WeatherMonthNameTable);
