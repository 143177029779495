import zoneColors from '../zoneColors.json';

const getSingleColor = (dataValue, currentTab) => {
  let roundValue = 5;

  if (currentTab === 'precipitation') {
    roundValue = 0.5;
  } else if (currentTab === 'windSpeed' || currentTab === 'geomagnetic') {
    roundValue = 1;
  }

  const minRounded = Math.floor(dataValue / roundValue) * roundValue;

  return zoneColors.find(({value}) => value[currentTab] === minRounded).color;
};

export default getSingleColor;
