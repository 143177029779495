import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import moment from 'moment';
import 'moment/locale/ru';

import {MONTHS_GEN} from 'constants/months';

import sendMetrics from 'core/metrics/metrics';

import ChangeDay from 'components/ChangeDay';
import Link from 'components/Link';
import BigWeatherIconTemper from 'components/BigWeatherIconTemper';
import WeatherDetailParams from 'components/WeatherDetailParams';

import NewChart from 'components/NewChart';

import YandexLogo from 'components/YandexLogo';

import ChangeUserGeo from 'Desktop/components/ChangeUserGeo';
import ChangeCity from 'Desktop/components/ChangeCity';
import ChartAsTable from 'Desktop/components/ChartAsTable';
import WeatherPeriods from 'Desktop/components/WeatherPeriods';

import s from './index.scss';

class DayBlock extends React.Component {
  static propTypes = {
    day:        pt.object.isRequired,
    dayParts:   pt.object,
    prevLink:   pt.string,
    nextLink:   pt.string,
    dataDay:    pt.string.isRequired,
    currentDay: pt.string.isRequired,
    chartsData: pt.object,
    town:       pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    page:         pt.string.isRequired,
    isFirstVisit: pt.bool.isRequired,
  }

  static defaultProps = {
    dayParts:   null,
    chartsData: null,
    prevLink:   null,
    nextLink:   null,
  }

  componentDidMount() {
    if (window.performance) {
      const {
        dayParts,
        chartsData,
        page,
        isFirstVisit,
      } = this.props;
      const mountMoment = performance.now();
      const renderTime = Math.round(mountMoment - window.startTime);

      if (chartsData && isFirstVisit) {
        sendMetrics({
          name:  'client_desktop_full_day_data_time',
          value: renderTime,
          page,
        });
      }

      if (dayParts && isFirstVisit) {
        sendMetrics({
          name:  'client_desktop_part_day_data_time',
          value: renderTime,
          page,
        });
      }
    }
  }

  getCurrentDateTime = (page, time, dataDay) => {
    let dateTime = '';

    if (page === 'today') {
      dateTime = moment(time).format('HH:mm');
    } else if (page === 'day') {
      const date = new Date(dataDay);
      const day = date.getDate();
      const month = MONTHS_GEN[date.getMonth()];
      const year = date.getFullYear();

      dateTime = `${day} ${month} ${year}`;
    }

    return dateTime;
  }

  getTitleText = (town, page, time) => {
    let text = `<h1>Погода ${town.loc_case_name} на `;

    if (page === 'today') {
      text += `сегодня</h1>, ${time}`;
    } else if (page === 'tomorrow') {
      text += 'завтра</h1>';
    } else if (page === 'day') {
      text += `${time}</h1>`;
    }

    return text;
  }

  render() {
    const {
      town,
      day,
      dayParts,
      prevLink,
      nextLink,
      dataDay,
      currentDay,
      chartsData,
      page,
    } = this.props;
    const weatherDetail = day && day.detail;
    const time = this.getCurrentDateTime(page, currentDay, dataDay);

    return (
      <div className={s.dayBlock}>
        <div className={s.dayHead} data-block="location">
          <div className={s.dayHeadTop}>
            <div
              className={s.dayTitle}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{__html: this.getTitleText(town, page, time)}}
            />
            <ChangeUserGeo />
          </div>
          <ChangeDay
            className={s.daySwitcher}
            prevLink={prevLink}
            nextLink={nextLink}
            currentDay={currentDay}
            dataDay={dataDay}
          />
          <ChangeCity className={s.dayLocation} />
        </div>
        <div className={s.dayDetail} data-block="main">
          {chartsData &&
            <Link
              to={`/${town.url_path}/now/`}
              data-weather={`${page}::detail_icon`}
            >
              <BigWeatherIconTemper
                icon={day.icon}
                temperature={day.temperature}
                needFeel
                tempFeels={day.tempFeels}
              />
            </Link>
          }
          {dayParts && <WeatherPeriods data={dayParts} className={s.dayPeriods} />}
          <div
            className={cn(
              s.dayParamsWrapper,
              dayParts && s.dayParamsParts,
            )}
          >
            <WeatherDetailParams
              className={cn(
                s.dayParams,
                dayParts && s.dayParamsParts,
              )}
              paramClassName={cn(
                s.dayParam,
                dayParts && s.dayParamsParts,
              )}
              data={weatherDetail}
              showGardenerCalendarLink
            />
          </div>
          {chartsData &&
            <noscript
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `<style>
                  .${s.dayCharts} {display: none}
                  .${s.dayTable} {
                    visibility: visible;
                    height: auto;
                    min-height: 200px !important;
                    padding-top: 20px !important;
                  }
                </style>`,
              }}
            />
          }
          {chartsData && <NewChart rootClassName={s.dayCharts} />}
          {chartsData && <ChartAsTable
            className={s.dayTable}
            chartsData={chartsData}
          />}
        </div>
        <YandexLogo />
      </div>
    );
  }
}

export const DayBlockWithoutHocs = DayBlock;
export default withStyles(s)(DayBlock);
