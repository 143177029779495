export default {
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Unexpected token <',
    '<unlabeled event>',
    'Не удается выполнить программу',
    'execute code from a freed script',
    'Разрешение отклонено',
    'Permission denied',
    'InvalidAccessError',
    'jQuery',
    'safeframe-bundles',
    'NS_ERROR_FILE_CORRUPTED',
    'NS_ERROR_DOM_QUOTA_REACHED',
    'NS_ERROR_FILE_NO_DEVICE_SPACE',
    'QuotaExceededError',
    'Failed to read',
    'kaspersky',
    'KasperskyLab',
    /SyntaxError JSON syntax error \/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/main\.js in Object\.ThrowError/i,
    /Cannot read property 'getAttribute' of null [0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/main/i,
    'getWotReputation',
    'w-display',
    'launcher.4.multi',
    'reachGoal',
    'm_miner',
    'k2o',
    '<anonymous> in abort',
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
    '/capirs_async.js',
    '/px2/client.js',
  ],
  blacklistUrls: [
    'kaspersky-labs.com',
    'https://translate.googleapis.com/',
    'https://metrics.nt.vc',
    'https://pingoli.info',
    'https://jh8hrfnvs.ru/pjs',
    'https://fireadd.men',
    'https://media.adrcdn.com/scripts/w-display',
    'https://cvn.wafflewariest.com',
    'https://nan.mashfsttest.com',
    'https://umk.journallingpercolates.com/',
    'https://static.reasedoper.pw',
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    /127\.0\.0\.1:4001\/isrunning/i,
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
};
