import {connect} from 'react-redux';

import getNdaysNoJSData from 'selectors/ndays/noJSData';

import Ndays from './index';

const mapStateToProps = state => ({
  days:              state.ndays.days,
  chunkedChartsData: state.ndays.data ? getNdaysNoJSData(state.ndays.data) : null,
  town:              state.town.town,
  windowType:        state.runtime.screen,
  screenTypeReady:   state.runtime.screenTypeReady,
  page:              state.runtime.page,
  rangeData:         state.ndays.rangeData,
  tableData:         state.ndays.tableData,
  currentDay:        state.ndays.currentDay,
});

export default connect(mapStateToProps)(Ndays);
