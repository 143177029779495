import * as SentryReact from '@sentry/react';

import sentryFilters from 'static/filters';

/**
 * Инициализирует Sentry в браузере, некоторыми runtime
 */
const initSentry = (runtime, config) => {
  const {
    appVersion,
    isMobile,
    ruid,
  } = runtime;

  SentryReact.init({
    ...sentryFilters,
    dsn:         config.SENTRY_DSN,
    sampleRate:  config.SENTRY_SAMPLE_RATE_BROWSER,
    release:     appVersion,
    environment: config.ENV,
  });

  const scope = SentryReact.getCurrentScope();

  if (ruid) {
    scope.setUser({
      id: ruid,
    });
  }

  scope.setTag('app', 'client');
  scope.setTag('user_agent', window.navigator.userAgent);
  scope.setTag('is_mobile', String(isMobile));
  scope.setTag('viewport', String(window.innerWidth));
  scope.setTag('adblock_enabled', String(window.isAdblockEnabled));
  scope.setTag('anti_adblock_enabled', String(!!window.QW50aS1BZEJsb2Nr));
};

export default initSentry;
