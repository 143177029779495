import {connect} from 'react-redux';

import {setRuntimeVariable} from 'actions/runtime';
import {updateHome} from 'core/updater';

import Layout from './index';

const mapStateToProps = ({runtime, town}) => ({
  isNewDesign:     runtime.isNewDesign,
  isPadVersion:    runtime.isPadVersion,
  windowType:      runtime.screen,
  screenTypeReady: runtime.screenTypeReady,
  isCityChanging:  runtime.isCityChanging,
  town:            town.town,
});

const mapDispatchToProps = dispatch => ({
  setScreenType(type, isPadVersion) {
    dispatch(setRuntimeVariable({
      name:  'screen',
      value: type,
    }));

    if (isPadVersion) {
      dispatch(setRuntimeVariable({
        name:  'screenTypeReady',
        value: true,
      }));
    }
  },

  setCityChanging(value) {
    dispatch(setRuntimeVariable({
      name: 'isCityChanging',
      value,
    }));
  },

  updateAll() {
    dispatch(updateHome(null, 'desktop'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
