import _groupBy from 'lodash.groupby';

import {
  FETCH_COUNTRIES_DATA_SUCCESS,
  FETCH_COUNTRIES_DATA_FAILURE,
  FETCH_COUNTRY_DATA_SUCCESS,
  FETCH_REGION_DATA_SUCCESS,
} from 'constants/actions/countries';

export default function countries(state = [], action) {
  switch (action.type) {
    case FETCH_COUNTRIES_DATA_SUCCESS: {
      return {
        ...state,
        countries: _groupBy(action.payload.data.countries, country => country.name[0]),
      };
    }
    case FETCH_COUNTRY_DATA_SUCCESS: {
      const regions = action.payload.data.page === 'regions'
        ? _groupBy(action.payload.data.regions_set, region => region.name[0])
        : [];
      const cities = action.payload.data.page === 'cities'
        ? _groupBy(action.payload.data.towns_weather, town => town.town__name[0])
        : [];
      return {
        ...state,
        country: action.payload.data.country,
        regions,
        cities,
      };
    }
    case FETCH_REGION_DATA_SUCCESS: {
      const cities = _groupBy(action.payload.data.towns_weather, town => town.town__name[0]);
      return {
        ...state,
        // здесь специально записываю регион в поле country,
        // чтобы можно было вывести в универсальном компоненте Cities
        country: action.payload.data.region,
        cities,
      };
    }
    case FETCH_COUNTRIES_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
