import {connect} from 'react-redux';

import setUIState from 'actions/ui';

import TopPart from './index';

const mapStateToProps = ({runtime, ui, header, town}) => ({
  page:         runtime.page,
  reloadGeoKey: ui.geoModal.reloadGeoKey,
  urlPath:      town.town.url_path,
  header,
});

const mapDispatchToProps = dispatch => ({
  toggleModalVisibility(value) {
    dispatch(setUIState({
      component: 'geoModal',
      name:      'isModalVisible',
      value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopPart);
