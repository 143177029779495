import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import Tooltip from 'rambler-ui/Tooltip';

import LocationIcon from './i/location.svg';
import LocationIconNewDesign from './i/locationNewDesign.svg';

import s from './index.scss';

class ChangeUserGeo extends React.Component {
  static propTypes = {
    isNewDesign:           pt.bool.isRequired,
    toggleModalVisibility: pt.func.isRequired,
    className:             pt.string,
    page:                  pt.string.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  shouldComponentUpdate(nextProps) {
    return this.props.page !== nextProps.page;
  }

  render() {
    const {
      isNewDesign,
      toggleModalVisibility,
      className,
      page,
    } = this.props;

    const Icon = isNewDesign ? LocationIconNewDesign : LocationIcon;

    return (
      <Tooltip
        content="Изменить город по умолчанию"
        className={cn(s.geo, isNewDesign ? s.geoNewDesign : s.geoOldDesign, className)}
      >
        <Icon
          className={cn(
            s.geoIcon,
            isNewDesign ? s.geoIconNewDesign : s.geoIconOldDesign,
          )}
          onClick={() => toggleModalVisibility(true)}
          data-weather={`${page}::region_change`}
        />
      </Tooltip>
    );
  }
}

export const ChangeUserGeoWithoutHocs = ChangeUserGeo;
export default withStyles(s)(ChangeUserGeo);
