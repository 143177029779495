import {connect} from 'react-redux';
import NearestCities from './index';

const mapStateToProps = state => ({
  cities:      state.nearestCities.cities,
  town:        state.town.town,
  page:        state.runtime.page,
  windowType:  state.runtime.screen,
  isNewDesign: state.runtime.isNewDesign,
  cityPeriod:  state.runtime.cityPeriod,
  dataDay:     state.day?.dataDay,
});

export default connect(mapStateToProps)(NearestCities);
