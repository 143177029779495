import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment';

import sendMetrics from 'core/metrics/metrics';
import getDayText from 'core/getDayText';

import WeatherIcon from 'components/WeatherIcon';
import WeatherDetailParams from 'components/WeatherDetailParams';
import {MONTHS_ENG, MONTHS} from 'constants/months';

import s from './index.scss';

class WeatherReport extends React.Component {
  static propTypes = {
    data: pt.shape({
      rainyDays:     pt.number.isRequired,
      sunnyDays:     pt.number.isRequired,
      overcastDays:  pt.number.isRequired,
      weatherDetail: pt.shape({
        minTemperature: pt.number.isRequired,
        maxTemperature: pt.number.isRequired,
        humidity:       pt.number.isRequired,
        pressure:       pt.number.isRequired,
        precipitation:  pt.number.isRequired,
        windSpeed:      pt.number.isRequired,
      }).isRequired,
    }),
    town: pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }),
    monthName:    pt.string.isRequired,
    isFirstVisit: pt.bool.isRequired,
    isNewDesign:  pt.bool.isRequired,
  };

  static defaultProps = {
    data: null,
    town: null,
  };

  componentDidMount() {
    if (window.performance) {
      const mountMoment = performance.now();
      const renderTime = Math.round(mountMoment - window.startTime);

      if (this.props.isFirstVisit) {
        sendMetrics({name: 'client_desktop_monthName_report_time', value: renderTime});
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    return (this.props.town.url_path !== nextProps.town.url_path
      || this.props.monthName !== nextProps.monthName);
  }

  getTitle = (town) => {
    const {monthName} = this.props;
    const month = MONTHS[MONTHS_ENG.indexOf(monthName)];
    const currentYear = moment().year();
    return `<h2>Сводка погоды ${town.loc_case_name} за ${month} ${currentYear}</h2>`;
  }

  getDayText = (dayCount, type) => getDayText(dayCount, type)

  getDaysProgress = (dayCount, type) => {
    const {monthName} = this.props;
    const currentYear = moment().year();
    const daysInMonth = moment(`1 ${monthName} ${currentYear}`).daysInMonth();
    return (
      <div className={s.dayProgress}>
        <div
          className={cn(s.dayProgressActive, s[type])}
          style={{width: `${Math.round((dayCount * 100) / daysInMonth)}%`}}
        />
      </div>
    );
  }

  getDay = (type) => {
    const {
      data,
      monthName,
    } = this.props;
    const typeNames = {
      clear:  'sunnyDays',
      cloudy: 'overcastDays',
      rain:   'rainyDays',
    };
    const dayCount = data[typeNames[type]];
    return (
      <div className={s.day} key={`${monthName}-${type}`}>
        <span className={s.dayCount}>{dayCount}</span>
        <span className={s.dayTitle}>{this.getDayText(dayCount, type)}</span>
        <div className={s.dayProgressContainer}>
          <WeatherIcon
            className={cn(s[type], s.dayIcon)}
            size={30}
            type={type}
          />
          {this.getDaysProgress(dayCount, type)}
        </div>
      </div>
    );
  }

  render() {
    const {
      data,
      town,
      isNewDesign,
    } = this.props;

    return (
      <div className={cn(s.root, isNewDesign ? s.rootNewDesign : s.rootOldDesign)}>
        {data &&
          <div className={s.container}>
            <div
              className={cn(s.title, isNewDesign ? s.titleNewDesign : s.titleOldDesign)}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{__html: this.getTitle(town)}}
            />
            <div className={s.daysLine}>
              {['clear', 'cloudy', 'rain'].map(type => this.getDay(type))}
            </div>
            <div className={s.weatherDetailWrapper}>
              <WeatherDetailParams
                className={cn(s.weatherDetail, s.weatherDetailParams)}
                paramClassName={s.weatherDetailParam}
                data={data.weatherDetail}
              />
            </div>
          </div>
        }
        {!data &&
          <div className={s.noData}>
            Извините, нет подходящих данных.<br />
            Попробуйте обновить страницу
          </div>
        }
      </div>
    );
  }
}

export const WeatherReportWithoutHocs = WeatherReport;
export default withStyles(s)(WeatherReport);
