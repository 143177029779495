import React from 'react';

import {
  setRuntimeVariable,
  setRuntimeVariables,
} from 'actions/runtime';
import {fetchHomeData} from 'actions/home';
import fetchNowData from 'actions/now';
import {
  current as fetchCurrentGeoData,
  autodetect as fetchAutodetectGeoData,
} from 'actions/geo';

import ndays from './ndays';
import weekend from './weekend';
import month from './month';
import day from './day';
import now from './now';
import monthName from './monthName';
import geomagnetic from './geomagnetic';
import agroprognoz from './agroprognoz';

async function action({store}, {v: cityPrefix, city, location: cityLocation}) {
  const location = cityLocation ? `/${encodeURIComponent(cityLocation)}` : '';
  const urlPath = `${cityPrefix}-${encodeURIComponent(city)}${location}`;
  const geoid = +store.getState().runtime.geoid || null;
  const isMobile = store.getState().runtime.isMobile || false;

  store.dispatch(setRuntimeVariables({
    page:                  'main',
    pagePrefix:            cityPrefix,
    weatherText:           '',
    weatherAdditionalText: '',
    isNewDesign:           true,
  }));

  const workers = [
    store.dispatch(fetchHomeData(urlPath, geoid)),
    store.dispatch(fetchAutodetectGeoData()),
    store.dispatch(fetchCurrentGeoData()),
    store.dispatch(fetchNowData(geoid, urlPath, true)),
  ];

  await Promise.all(workers);

  if (__CLIENT__) {
    store.dispatch(setRuntimeVariable({
      name:  'pageIsLoaded',
      value: true,
    }));
  }

  let Layout;
  let Home;
  let StaticText;

  /* eslint-disable global-require */
  if (__DESKTOP__ && !isMobile) {
    Layout = require('Desktop/components/Layout').default;
    Home = require('Desktop/pages/Home').default;
    StaticText = require('Desktop/components/StaticText').default;
  } else if (__MOBILE__ && isMobile) {
    Layout = require('Mobile/components/Layout').default;
    Home = require('Mobile/pages/Home').default;
  }
  /* eslint-enable global-require */

  const newState = store.getState();
  const meta = newState.seo;
  // eslint-disable-next-line prefer-destructuring
  const error = newState.home.error;

  if (error) {
    return {
      status:   error.status || 500,
      redirect: true,
    };
  }

  return {
    meta,
    component: (
      <Layout routePage={urlPath}>
        <Home notIndex />
        {StaticText && <StaticText text={meta.seoText} />}
      </Layout>
    ),
  };
}

export default {
  path:     '',
  children: [
    {
      path:     '/:v-:city',
      children: [
        {
          path: '',
          action,
        },
        month,
        ndays,
        weekend,
        day,
        now,
        monthName,
        geomagnetic,
        agroprognoz,
        {
          path:     '/:location',
          children: [
            {
              path: '',
              action,
            },
            month,
            ndays,
            weekend,
            day,
            now,
            monthName,
            geomagnetic,
            agroprognoz,
          ],
        },
      ],
    },
  ],
};
