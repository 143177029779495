import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

import {AD_SUPERFOOTER_ID} from 'constants/ad';

import Ad from 'components/Ad';
import CitiesSearch from 'Desktop/components/CitiesSearch';
import Cities from 'Desktop/components/Cities';

import s from './index.scss';

class CitiesPage extends React.Component {
  render() {
    return (
      <div className={s.home}>
        <div className={s.citiesSearch} data-block="srch">
          <CitiesSearch top100Attr="search" isCountries />
        </div>
        <Cities />
        <Ad
          id={AD_SUPERFOOTER_ID}
          onRenderWrapperStyle={{marginTop: '15px'}}
          isLazy
        />
      </div>
    );
  }
}

export default withStyles(s)(CitiesPage);
