import {connect} from 'react-redux';
import CitiesWeather from './index';

const mapStateToProps = ({runtime, weatherCities}) => ({
  isNewDesign: runtime.isNewDesign,
  page:        runtime.page,
  listData:    weatherCities.listData,
});

export default connect(mapStateToProps)(CitiesWeather);
