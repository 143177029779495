import React, {useRef, useState, useMemo, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Switcher from 'rambler-ui/Switcher';
import IconButton from 'rambler-ui/IconButton';
import Button from 'rambler-ui/Button';
import ChevronRightIcon from 'rambler-ui/icons/forms/ChevronRightIcon';
import ChevronLeftIcon from 'rambler-ui/icons/forms/ChevronLeftIcon';

import moment from 'moment';

import {ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Line} from 'recharts';
import {useConveyer} from '@egjs/react-conveyer';

import {createSelector} from 'reselect';

import getNDaysChartsData from 'selectors/ndays/chartsData';
import getMonthChartsData from 'selectors/month/chartsData';
import getExactMonthChartsData from 'selectors/monthName/chartsData';

import {N_DAYS_PATTERN} from 'constants/index';
import {MONTHS, MONTHS_ABBR_GEN_2, MONTHS_ENG} from 'constants/months';

import Link from 'components/Link';
import WeatherIcon from 'components/WeatherIcon';

import ChartTabs from './components/Tabs';

import getChartData from './utils/getChartData';
import getZonesColors from './utils/getZonesColors';
import getSingleColor from './utils/getSingleColor';
import getText from './utils/getText';
import windDirectionsText from './windDirectionsText.json';
import IconLeft from './icons/left.svg';
import IconRight from './icons/right.svg';

const MAIN_CHART_COLOR = '#A2C4FF';
const DAY = 0;
const NIGHT = 1;
const DATA_BUILD_KEY_PREFIX = 'NewChart-data-build';
const SCROLL_NORMAL_STEP = 900;
const SCROLL_SMALL_STEP = 575;
const SCROLL_DURATION = 500;

const selectData = createSelector(
  [
    (state) => state.runtime.isMobile,
    (state) => state.runtime.page,
    (state) => state.runtime.screen,
    (state) => state.runtime.routingEnded,
    (state) => state.runtime.isNewDesign,
    (state) => state.town.town,
    (state) => state.day.chartsData,
    (state) => state.ndays.data,
    (state) => state.ndays.days,
    (state) => state.month.monthList,
    (state) => state.monthName,
    (state) => state.geomagnetic.chartsData,
  ],
  (
    isMobile,
    pageName,
    windowType,
    routingEnded,
    isNewDesign,
    town,
    singleDayChartData,
    nDaysChartData,
    nDaysAmountOfDays,
    monthChartData,
    exactMonthChartData,
    geomagneticChartData,
  ) => {
    const isSingleDay = ['tomorrow', 'day'].includes(pageName);
    const isNdays = N_DAYS_PATTERN.test(pageName);
    const isMonth = pageName === 'city_month';
    const isExactMonth = pageName === 'monthly';
    const isGeomagnetic = pageName === 'geomagnetic';

    let chartsData = [];

    if (isSingleDay && singleDayChartData) {
      chartsData = singleDayChartData;
    } else if (isNdays && nDaysChartData) {
      chartsData = getNDaysChartsData(nDaysChartData);
    } else if (isMonth && monthChartData) {
      chartsData = getMonthChartsData(monthChartData);
    } else if (isExactMonth && exactMonthChartData) {
      chartsData = getExactMonthChartsData(exactMonthChartData);
    } else if (isGeomagnetic && geomagneticChartData) {
      chartsData = [{
        geomagnetic:     geomagneticChartData.rates,
        xAxisCategories: geomagneticChartData.dates,
      }];
    }

    let chartHeight = 250;

    if (isSingleDay) {
      chartHeight = isMobile ? 165 : 275;
    } else if (isNdays) {
      if (isMobile) {
        chartHeight = 190;
      } else {
        chartHeight = chartsData.length !== 1 ? 250 : 230;
      }
    } else if (isMonth) {
      chartHeight = isMobile ? 220 : 255;
    } else if (isExactMonth) {
      chartHeight = 250;
    } else if (isGeomagnetic) {
      chartHeight = isMobile ? 190 : 135;
    }

    let chartWidth = 550;

    if (isSingleDay) {
      chartWidth = isMobile ? '100%' : 585;
    } else if (isNdays) {
      chartWidth = '100%';
    } else if (isGeomagnetic) {
      chartWidth = '100%';
    }

    // chartWidth by windowType and device
    if (isNdays) {
      if (nDaysAmountOfDays === 14) {
        if (windowType === 'small') {
          chartWidth = 1150;
        } else if (isMobile) {
          chartWidth = 900;
        }
      } else if (nDaysAmountOfDays === 10 && isMobile) {
        chartWidth = 650;
      }
    } else if (chartsData[0] && (isMonth || isExactMonth)) {
      const amountOfDaysInMonth = chartsData[0].temperature.length;

      if (amountOfDaysInMonth === 32) {
        chartWidth = windowType === 'normal' ? 2400 : 2630;
      } else if (amountOfDaysInMonth === 31) {
        chartWidth = windowType === 'normal' ? 2310 : 2560;
      } else if (amountOfDaysInMonth === 30) {
        chartWidth = windowType === 'normal' ? 2240 : 2470;
      } else if (amountOfDaysInMonth === 29) {
        chartWidth = windowType === 'normal' ? 2170 : 2380;
      } else if (amountOfDaysInMonth === 28) {
        chartWidth = windowType === 'normal' ? 2085 : 2300;
      }
    }

    let isNeedShowArrows = false;

    if (
      !isMobile && (
        (isNdays && nDaysAmountOfDays === 14 && windowType === 'small')
        || isMonth
        || isExactMonth
      )
    ) {
      isNeedShowArrows = true;
    }

    let prevMonth;
    let nextMonth;

    if (isExactMonth && chartsData[0].xAxisCategories[0]) {
      const dataMonth = new Date(chartsData[0].xAxisCategories[0].date);
      const momentDate = moment(dataMonth);
      const prevMonthIndex = momentDate.clone().add(-1, 'month').format('M') - 1;
      const nextMonthIndex = momentDate.clone().add(1, 'month').format('M') - 1;

      if (prevMonthIndex !== 11) {
        prevMonth = {
          url:    `/${town.url_path}/${MONTHS_ENG[prevMonthIndex]}/`,
          top100: `${pageName}::month_prev`,
          text:   `${MONTHS[prevMonthIndex]} ${dataMonth.getFullYear()}`,
        };
      }

      if (nextMonthIndex !== 0) {
        nextMonth = {
          url:    `/${town.url_path}/${MONTHS_ENG[nextMonthIndex]}/`,
          top100: `${pageName}::month_next`,
          text:   `${MONTHS[nextMonthIndex]} ${dataMonth.getFullYear()}`,
        };
      }
    }

    return {
      isMobile,
      windowType,
      routingEnded,
      isNewDesign,
      isSingleDay,
      isNdays,
      isMonth,
      isExactMonth,
      isGeomagnetic,
      chartsData,
      chartHeight,
      chartWidth,
      withTabs: !isMonth && !isGeomagnetic,
      isNeedShowArrows,
      prevMonth,
      nextMonth,
    };
  },
);

function NewChart({rootClassName}) {
  const chartRef = useRef();
  const chartScrollValueRef = useRef(0);
  const isAwaitToIncrement = useRef(false);
  const {
    isMobile,
    windowType,
    routingEnded,
    isNewDesign,
    isSingleDay,
    isNdays,
    isExactMonth,
    isGeomagnetic,
    chartsData,
    chartHeight,
    chartWidth,
    withTabs,
    isNeedShowArrows,
    prevMonth,
    nextMonth,
  } = useSelector(selectData);
  const [currentTab, setCurrentTab] = useState(isGeomagnetic ? 'geomagnetic' : 'temperature');
  const [isNight, setIsNight] = useState(false);
  const [dataBuildCount, setDataBuildCount] = useState(1);

  const isTemperature = currentTab === 'temperature';
  const isHumidity = currentTab === 'humidity';
  const dataTime = isNight && !(isExactMonth && currentTab !== 'temperature') ? NIGHT : DAY;

  const transformedData = useMemo(() => getChartData(chartsData), [chartsData, routingEnded]);

  const getStrokeColor = useCallback(() => {
    if (transformedData.sameValues?.[currentTab][dataTime]) {
      return getSingleColor(
        transformedData.dayNight[dataTime].data[0][currentTab], currentTab,
      );
    }

    return 'url(#gradientCode)';
  }, [transformedData, currentTab, isNight]);

  const data = useMemo(() => ({
    ...transformedData,
    gradientColors: transformedData.minValue
      ? getZonesColors(
        transformedData.minValue[currentTab][dataTime],
        transformedData.maxValue[currentTab][dataTime],
        currentTab,
      ).reverse()
      : [],
    strokeColor: getStrokeColor(),
  }), [transformedData, currentTab, isNight, getStrokeColor]);

  const handleTabChange = (tabValue) => {
    setCurrentTab(tabValue);
  };

  const gradientCode = useMemo(() => {
    const length = data.gradientColors.length;
    const interval = length === 2 ? 100 : 100 / (length - 1);
    const stopsArr = [];

    for (let i = 0; i < length; i++) {
      let percentage;

      if (i === 0) {
        percentage = 0;
      } else if (i === length - 1) {
        percentage = 100;
      } else {
        percentage = i * interval;
      }

      stopsArr.push({
        percentage: `${Math.round(percentage * 100) / 100}%`,
        color:      data.gradientColors[i],
      });
    }

    return (
      <linearGradient id="gradientCode" x1="0" y1="0" x2="0" y2="1">
        {stopsArr.map(stopItem => (
          <stop key={`gradientCode-${stopItem.percentage}`} offset={stopItem.percentage} stopColor={stopItem.color} stopOpacity={1} />
        ))}
      </linearGradient>
    );
  }, [data.gradientColors]);

  const lideDot = useCallback((cx, cy, index, value, dayNightIndex, key, allDotData) => {
    if (value === null) return null;

    const isWindTab = currentTab === 'windSpeed';
    const whiteBubbleHeight = isWindTab ? 50 : 30;
    const text = getText(currentTab, value);
    let textWidth = (text.length * 5);
    let textXShift = 0;

    const isCalm = allDotData.windDirection === 'C' || value === 0;

    if (isTemperature) {
      textWidth += 41;
      textXShift = 38;

      if (value < 0) {
        /**
         * In the new weather, we also need to increase the textWidth - here, unfortunately,
         * the width of the wrapper and the number of bubbles do not allow this
         */
        textXShift += 3;
      }
    } else if (isGeomagnetic) {
      textWidth = 30;
      textXShift = 15;
    } else {
      textWidth += 30;
      textXShift = 18 + (text.length * 2);
    }

    let textYShift = 16.5;

    if (isWindTab) {
      if (!isCalm) {
        textYShift = 35;
      } else {
        textYShift = 26;
      }
    }

    return (
      <g
        transform={`translate(${Math.floor(cx - textWidth / 2)},${Math.floor(cy - whiteBubbleHeight / 2)})`}
        key={`${currentTab}-Line-${value}-${text}-${key}`}
        filter="url(#shadow)"
      >
        {/* Transparent bounding box */}
        <rect x="0" y="0" width="150" height="150" fill="none" />
        <g width={textWidth} height={whiteBubbleHeight}>
          <rect
            width={textWidth}
            height={whiteBubbleHeight}
            rx={15}
            fill="#fff"
          />
          {isTemperature && (
            <WeatherIcon
              isNewDesign
              // size only for backward compatibility with old code
              size={20}
              type={allDotData.icon}
              svgAttrs={{x: 5, y: 5.5, width: 20, height: 20}}
            />
          )}
          {isWindTab && !isCalm && (
            <text
              className="NewChart-dotText"
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`translate(${textXShift},17)`}
            >
              {windDirectionsText[allDotData.windDirection]}
            </text>
          )}
          <text
            className="NewChart-dotText"
            dominantBaseline="middle"
            textAnchor="middle"
            transform={`translate(${textXShift},${textYShift})`}
          >
            {text}
          </text>
        </g>
      </g>
    );
  }, [currentTab, isTemperature, isHumidity]);

  const xAxisTick = useCallback(({
    stroke,
    orientation,
    width,
    height,
    x,
    y,
    fill,
    payload,
    textAnchor,
    className,
  }) => {
    const isHoliday = payload.value.includes('isHoliday');
    const pureDate = isHoliday ? payload.value.replace(' isHoliday', '') : payload.value;

    const date = new Date(pureDate);
    const day = `${date.getDate()}`;
    const month = MONTHS_ABBR_GEN_2[date.getMonth()];

    const tickText = `${day} ${month}`;

    return (
      <text
        stroke={stroke}
        orientation={orientation}
        width={width}
        height={height}
        x={x}
        y={y}
        fill={fill}
        className={cn(className, 'recharts-text', isHoliday && 'recharts-text-holiday')}
        textAnchor={textAnchor}
      >
        <tspan x={x} dy="0.71em">{tickText}</tspan>
      </text>
    );
  }, []);

  const {
    scrollPos,
    onFinishScroll,
    isReachStart,
    isReachEnd,
    scrollTo,
    scrollIntoView,
    updateContainer,
  } = useConveyer(chartRef, {
    horizontal: true,
  });

  onFinishScroll(() => {
    chartScrollValueRef.current = scrollPos;
  }, [scrollPos]);

  useEffect(() => {
    isAwaitToIncrement.current = true;
    scrollTo(0, 0);
  }, [windowType, routingEnded]);

  useEffect(() => {
    if (isAwaitToIncrement.current && isReachStart && routingEnded) {
      isAwaitToIncrement.current = false;
      setDataBuildCount((count) => count + 1);
    }
  }, [isReachStart, routingEnded, windowType]);

  const handleArrowLeftClick = useCallback(() => {
    if (isNdays) {
      scrollTo(0, SCROLL_DURATION);
    } else { // month and monthName
      const scrollStep = windowType === 'normal' ? SCROLL_NORMAL_STEP : SCROLL_SMALL_STEP;
      const approxPrevValue =
        chartScrollValueRef.current - scrollStep;

      chartScrollValueRef.current = approxPrevValue < 0 ? 0 : approxPrevValue;

      scrollTo(chartScrollValueRef.current, SCROLL_DURATION);
    }
  }, [isNdays, windowType]);

  const handleArrowRightClick = useCallback(() => {
    if (isNdays) {
      scrollTo(SCROLL_SMALL_STEP, SCROLL_DURATION);
    } else { // month and monthName
      const scrollStep = windowType === 'normal' ? SCROLL_NORMAL_STEP : SCROLL_SMALL_STEP;
      const approxNextValue =
        chartScrollValueRef.current + scrollStep;

      if (approxNextValue + scrollStep > chartWidth) {
        scrollIntoView('start', {
          align:    'start',
          duration: 500,
        });
      } else {
        chartScrollValueRef.current = approxNextValue;
        scrollTo(chartScrollValueRef.current, SCROLL_DURATION);
      }
    }
  }, [isNdays, windowType]);

  const MemoChart = useCallback(({children}) => {
    if (typeof chartWidth === 'string') {
      return (
        <ResponsiveContainer
          width={chartWidth}
          height={chartHeight}
          minWidth={isMobile && isSingleDay ? 600 : 450}
        >
          <LineChart
            className={cn('NewChart-chart', isMobile && 'NewChart-chart-mobile', isGeomagnetic && 'NewChart-chart-geomagnetic')}
            margin={{left: 0, right: 0, top: 0, bottom: 0}}
          >
            {children}
          </LineChart>
        </ResponsiveContainer>
      );
    } else {
      return (
        <LineChart
          className={cn('NewChart-chart', isMobile && 'NewChart-chart-mobile', isGeomagnetic && 'NewChart-chart-geomagnetic')}
          width={chartWidth}
          height={chartHeight}
          minWidth={isMobile && isSingleDay ? 600 : 450}
          margin={{left: 0, right: 0, top: 0, bottom: 0}}
        >
          {children}
        </LineChart>
      );
    }
  }, [chartWidth, chartHeight, isMobile, isSingleDay, isGeomagnetic]);

  const yAxisDomain = useCallback(([dataMin, dataMax]) => [dataMin, dataMax], [currentTab]);

  if (!chartsData || (Array.isArray(chartsData) && chartsData.length === 0)) return null;

  const showActions = chartsData.length === 2 || isNeedShowArrows;
  const showLeftButton = Boolean(isNeedShowArrows && isReachStart && isExactMonth && prevMonth);
  const showRightButton = Boolean(isNeedShowArrows && isReachEnd && isExactMonth && nextMonth);

  return (
    <div className={cn('NewChart-root', !showActions && 'NewChart-root-noActions', rootClassName)}>
      {withTabs && <ChartTabs currentTab={currentTab} handleTabChange={handleTabChange} />}
      {showActions && (
        <div className={cn('NewChart-actions', !isMobile && !isNewDesign && isNeedShowArrows && 'NewChart-actions-desktopOldArrows')}>
          {chartsData.length === 2 && !(isExactMonth && currentTab !== 'temperature') && (
            <div className={cn('NewChart-switcher', isMobile && 'NewChart-switcher-mobile', isMobile && isNewDesign && 'NewChart-switcher-mobile-newDesign', isNeedShowArrows && 'NewChart-switcher-inline')}>
              <Switcher checked={isNight} onCheck={(e, value) => setIsNight(value)}>Ночь</Switcher>
            </div>
          )}
          {isNeedShowArrows && (
            <div className="NewChart-nav">
              {prevMonth && (
                <Button
                  icon={<ChevronLeftIcon />}
                  iconPosition="left"
                  type="secondary"
                  size="small"
                  container={
                    <Link
                      to={prevMonth.url}
                      data-weather={prevMonth.top100}
                    />
                  }
                  className={showLeftButton ? undefined : 'NewChart-nav-hidden'}
                >
                  {prevMonth.text}
                </Button>
              )}
              <IconButton
                type="secondary"
                size="small"
                disabled={isReachStart}
                className={cn(
                  'NewChart-arrow',
                  'NewChart-arrow-left',
                  showLeftButton && 'NewChart-nav-hidden',
                )}
                onClick={handleArrowLeftClick}
              >
                <IconLeft />
              </IconButton>
              <IconButton
                type="secondary"
                size="small"
                disabled={isReachEnd}
                className={cn(
                  'NewChart-arrow',
                  'NewChart-arrow-right',
                  showRightButton && 'NewChart-nav-hidden',
                )}
                onClick={handleArrowRightClick}
              >
                <IconRight />
              </IconButton>
              {nextMonth && (
                <Button
                  icon={<ChevronRightIcon />}
                  iconPosition="right"
                  type="secondary"
                  size="small"
                  container={
                    <Link
                      to={nextMonth.url}
                      data-weather={nextMonth.top100}
                    />
                  }
                  className={showRightButton ? 'NewChart-arrow-right' : 'NewChart-nav-hidden'}
                >
                  {nextMonth.text}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
      <div
        className={cn(isMobile ? 'NewChart-scroller-mobile' : 'NewChart-scroller-desktop')}
        ref={chartRef}
      >
        <MemoChart>
          <CartesianGrid horizontal={false} style={{stroke: MAIN_CHART_COLOR}} />
          <XAxis
            dataKey={isSingleDay ? 'time' : 'date'}
            type="category"
            stroke={MAIN_CHART_COLOR}
            tickLine={false}
            allowDecimals={false}
            allowDataOverflow
            interval={0}
            padding={{left: 40, right: 40}}
            tick={isSingleDay ? true : xAxisTick}
            tickFormatter={(value) => isNdays ? value : `${value}:00`}
          />
          <YAxis
            dataKey={currentTab}
            stroke={MAIN_CHART_COLOR}
            padding={currentTab === 'windSpeed' ? {top: 35, bottom: 35} : {top: 25, bottom: 25}}
            domain={yAxisDomain}
            hide
            allowDataOverflow
          />
          <g className="recharts-cartesian-grid">
            <g className="recharts-cartesian-grid-horizontal">
              <line
                fill="none"
                x="0"
                y="0"
                height={chartHeight - 40}
                x1="0"
                y1="0"
                x2="100%"
                y2="0"
                style={{stroke: MAIN_CHART_COLOR, strokeDasharray: '4 4'}}
              />
              <line
                fill="none"
                x="0"
                y="0"
                height={chartHeight - 40}
                x1="0"
                y1={((chartHeight - 40) * 0.25) + 5}
                x2="100%"
                y2={((chartHeight - 40) * 0.25) + 5}
                style={{stroke: MAIN_CHART_COLOR, strokeDasharray: '4 4'}}
              />
              <line
                fill="none"
                x="0"
                y="0"
                height={chartHeight - 40}
                x1="0"
                y1={((chartHeight - 40) * 0.5) + 5}
                x2="100%"
                y2={((chartHeight - 40) * 0.5) + 5}
                style={{stroke: MAIN_CHART_COLOR, strokeDasharray: '4 4'}}
              />
              <line
                fill="none"
                x="0"
                y="0"
                height={chartHeight - 40}
                x1="0"
                y1={((chartHeight - 40) * 0.75) + 5}
                x2="100%"
                y2={((chartHeight - 40) * 0.75) + 5}
                style={{stroke: MAIN_CHART_COLOR, strokeDasharray: '4 4'}}
              />
            </g>
          </g>
          <defs>
            <filter id="shadow">
              <feDropShadow dx="0" dy="10" stdDeviation="10" floodColor="rgba(49, 94, 251, 0.15)" />
            </filter>

            {gradientCode}
          </defs>
          <Line
            key={`${DATA_BUILD_KEY_PREFIX}-${dataBuildCount}`}
            type="monotone"
            dataKey={currentTab}
            data={data.dayNight[dataTime].data}
            name={data.dayNight[dataTime].name}
            stroke={data.strokeColor}
            strokeWidth={2}
            strokeOpacity={1}
            animationDuration={500}
            dot={({cx, cy, index, value, key, payload}) =>
              lideDot(cx, cy, index, value, dataTime, key, payload)
            }
            onAnimationEnd={() => {
              updateContainer();
            }}
          />
        </MemoChart>
      </div>
    </div>
  );
}

NewChart.propTypes = {
  rootClassName: PropTypes.string,
};

NewChart.defaultProps = {
  rootClassName: '',
};

export default React.memo(NewChart);
