import {connect} from 'react-redux';

import setUIState from 'actions/ui';

import ChangeUserGeo from './index.js';

const mapStateToProps = ({runtime}) => ({
  page:        runtime.page,
  isNewDesign: runtime.isNewDesign,
});

const mapDispatchToProps = dispatch => ({
  toggleModalVisibility(value) {
    dispatch(setUIState({
      component: 'geoModal',
      name:      'isModalVisible',
      value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserGeo);
