import React from 'react';
import * as pt from 'prop-types';

import sendMetrics from 'core/metrics/metrics';

import RoutePageContext from 'context/routePageContext';

import Search from './Search';

class CitiesSearch extends React.Component {
  static propTypes = {
    page:         pt.string.isRequired,
    isFirstVisit: pt.bool.isRequired,
    isCountries:  pt.bool,
  }

  static defaultProps = {
    isCountries: false,
  }

  componentDidMount() {
    if (window.performance) {
      const {
        page,
        isFirstVisit,
        isCountries,
      } = this.props;
      const mountMoment = performance.now();
      const renderTime = Math.round(mountMoment - window.startTime);

      if (isFirstVisit && isCountries) {
        sendMetrics({
          name:  'client_desktop_countries_time',
          value: renderTime,
          page,
        });
      }
    }
  }

  render() {
    const {props} = this;

    return (
      <RoutePageContext.Consumer>
        {routePage => (
          <Search {...props} routePage={routePage} />
        )}
      </RoutePageContext.Consumer>
    );
  }
}

export default CitiesSearch;
