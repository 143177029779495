import fetch from 'core/fetch';

function createFetchKnowingCookie({cookie}) {
  if (!process.env.BROWSER) {
    return (url, options = {}) => {
      const isLocalUrl = /^\/($|[^/])/.test(url);

      // pass cookie only for itself.
      // We can't know cookies for other sites BTW
      if (isLocalUrl && options.credentials === 'include') {
        const headers = {
          ...options.headers,
          cookie,
        };
        return fetch(url, {...options, headers});
      }

      return fetch(url, options);
    };
  }

  return fetch;
}

export default function createHelpers(helpersData) {
  const fetchKnowingCookie = createFetchKnowingCookie(helpersData);

  return {
    fetch:   fetchKnowingCookie,
    history: helpersData.history,
    statsd:  !process.env.BROWSER ? helpersData.statsd : {},
  };
}
