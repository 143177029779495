import {connect} from 'react-redux';

import {fetchWeatherCities} from 'actions/weatherCities';
import setUIState from 'actions/ui';

import CitiesSearch from './index';

const mapStateToProps = ({runtime, weatherCities}) => ({
  page:         runtime.page,
  isFirstVisit: runtime.pageNumber === 1,
  searchData:   weatherCities.searchData,
});

const mapDispatchToProps = dispatch => ({
  getCities(query) {
    dispatch(fetchWeatherCities(query, 7));
  },

  toggleSearchVisibility(value) {
    dispatch(setUIState({
      component: 'geoSearch',
      name:      'isSearchVisible',
      value,
    }));

    if (!value) {
      dispatch(fetchWeatherCities(''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CitiesSearch);
