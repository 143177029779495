import {
  FETCH_COUNTRIES_DATA_SUCCESS,
  FETCH_COUNTRY_DATA_SUCCESS,
  FETCH_REGION_DATA_SUCCESS,
  FETCH_COUNTRIES_DATA_FAILURE,
} from 'constants/actions/countries';
import {FETCH_TOWN_DATA_SUCCESS} from 'constants/actions/town';
import FETCH_SEO_DATA_SUCCESS from 'constants/actions/seo';
import FETCH_BREADCRUMBS_DATA_SUCCESS from 'constants/actions/breadcrumbs';

import {
  DEFAULT_API_URL,
  COUNTRIES_API_URLS,
} from 'constants/index';

import captureException from 'core/captureException';

export default function fetchCountriesData(geoID, countryName, regionName) {
  return async(dispatch, getState, {fetch, statsd}) => {
    let startTime = new Date();

    try {
      let fetchType = FETCH_COUNTRIES_DATA_SUCCESS;
      let location = '';
      let geo = '';
      if (countryName && regionName) {
        fetchType = FETCH_REGION_DATA_SUCCESS;
        location = `?location=${countryName}/${regionName}`;
        geo = geoID ? `&geo_id=${geoID}` : '';
      } else if (countryName) {
        fetchType = FETCH_COUNTRY_DATA_SUCCESS;
        location = `?location=${countryName}`;
        geo = geoID ? `&geo_id=${geoID}` : '';
      } else {
        geo = geoID ? `?geo_id=${geoID}` : '';
      }

      const url = `${DEFAULT_API_URL}${COUNTRIES_API_URLS.home}${location}${geo}`;
      const req = await fetch(url);

      if (req.ok === false) {
        const err = new Error(`${new Date()} Не удалось получить список стран: ${url}`);

        err.status = req.status;
        throw err;
      }

      if (__SERVER__) {
        statsd.timing('api_request.fetchCountriesData.timer', startTime);
        statsd.timing('api_request.fetchGlobalTimer.timer', startTime);
      }

      startTime = new Date();
      const data = await req.json();
      if (__SERVER__) {
        statsd.timing('json.fetchCountriesData.timer', startTime);
      }

      await dispatch({
        type:    fetchType,
        payload: {data},
      });
      await dispatch({
        type:    FETCH_TOWN_DATA_SUCCESS,
        payload: {town: data.town},
      });
      await dispatch({
        type:    FETCH_SEO_DATA_SUCCESS,
        payload: {seo: data.seo},
      });
      await dispatch({
        type:    FETCH_BREADCRUMBS_DATA_SUCCESS,
        payload: {breadcrumbs: data.breadcrumbs},
      });
    } catch (error) {
      captureException(error);

      if (__SERVER__) {
        statsd.increment('service_error.fetchCountriesData.counter');
      }

      await dispatch({
        type:    FETCH_COUNTRIES_DATA_FAILURE,
        payload: {
          stack:   error.stack,
          message: error.message,
          status:  error.status || 500,
        },
      });
      console.error(`${new Date()} ${error}`);
    }
  };
}
