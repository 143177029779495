import {connect} from 'react-redux';

import getTableData from 'selectors/getPeriodData';

import WeatherDayLines from './index.js';

const mapStateToProps = (state, props) => ({
  page: state.runtime.page,
  town: state.town.town,
  data: getTableData(props),
});

export default connect(mapStateToProps)(WeatherDayLines);
