import {createSelector} from 'reselect';

const getRangeData = props => props.rangeData || [];
const getTableDate = props => props.tableData || [];

export default createSelector(
  getRangeData,
  getTableDate,
  (rangeData, tableData) => {
    const readyData = [];

    tableData.forEach((day, index) => {
      const dayObj = {
        date:      day.date,
        isHoliday: rangeData[index].is_holiday,
        periods:   [
          {
            name:        'Ночью',
            temperature: day.temperature[0],
            icon:        day.icon[0],
          },
          {
            name:        'Днём',
            temperature: day.temperature[2],
            icon:        day.icon[2],
          },
        ],
        params: {
          precipitationProbability: rangeData[index].precipitation_probability,
          wind:                     {
            speed:     day.wind_speed[2],
            direction: day.wind_direction[2],
          },
          moonPhase: rangeData[index].moon_phase,
        },
      };

      readyData.push(dayObj);
    });

    return readyData;
  },
);
