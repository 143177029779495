import {createSelector} from 'reselect';

import {DAYS_IN_WEEK} from 'constants/months';

const getTableData = state => state || [];

export default createSelector(
  [getTableData],
  (days) => {
    const weeks = [];

    while (days.length > 0) {
      const currentSlice = days.splice(0, DAYS_IN_WEEK);
      const currentSliceLength = currentSlice.length;
      const firstDayInWeek = new Date(currentSlice[0].date);
      const lastDayInWeek = new Date(currentSlice[currentSliceLength - 1].date);
      const isFirstWeek = (firstDayInWeek.getMonth() !== lastDayInWeek.getMonth())
        || firstDayInWeek.getDate() === 1;

      if (currentSliceLength < DAYS_IN_WEEK) {
        const missingDays = new Array(DAYS_IN_WEEK - currentSliceLength);
        currentSlice.push(...missingDays);
      }

      weeks.push(currentSlice.map((day) => {
        if (!day) return day;
        const newDay = {
          ...day,
          isFirstWeek,
          isMuted: day.is_prev_date,
        };
        return newDay;
      }));
    }
    return weeks;
  },
);
