import {createSelector} from 'reselect';
import moment from 'moment';

import _filter from 'lodash.filter';

const getChartsData = state => state || [];

const getMonthNameData = (data) => {
  const sectionOrder = ['day', 'night'];
  const chartsData = [];

  const currentYear = moment().year();
  const monthDate = moment(`1 ${data.monthName} ${currentYear}`);

  const filteredData = _filter(
    data.monthList,
    item => moment(item.date).isSame(monthDate, 'month'),
  );

  // eslint-disable-next-line
  for (const section of sectionOrder) {
    const params = {
      temperature:     [],
      humidity:        [],
      pressure:        [],
      wind_direction:  [],
      wind_speed:      [],
      precipitation:   [],
      icon:            [],
      xAxisCategories: [],
    };
    const paramsKeys = Object.keys(params);
    // eslint-disable-next-line
    for (const day of filteredData) {
      params.xAxisCategories.push({
        date:      day.date,
        isHoliday: day.is_holiday,
      });
      // eslint-disable-next-line
      for (const param of paramsKeys) {
        if (param === 'xAxisCategories') continue;
        if (param === 'temperature') {
          params[param].push(day[`${section}_temperature`]);
        } else if (param === 'icon' || section === 'day') {
          params[param].push(day[param]);
        }
      }
    }

    chartsData.push({
      temperature: params.temperature,
      humidity:    params.humidity,
      pressure:    params.pressure,
      wind:        {
        speed:     params.wind_speed,
        direction: params.wind_direction,
      },
      precipitation:   params.precipitation,
      icon:            params.icon,
      xAxisCategories: params.xAxisCategories,
    });
  }
  return chartsData;
};

export default createSelector(
  [getChartsData],
  data => getMonthNameData(data),
);
