const toggle = __TEST__
  ? jest.fn()
  : (bool) => {
    // eslint-disable-next-line prefer-destructuring
    const body = document.body;

    if (bool) {
      body.classList.add('noScroll');
    } else {
      body.classList.remove('noScroll');
    }
  };

export default toggle;
