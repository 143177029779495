import clear from './i/NewDesign/clear.svg';
import clearNight from './i/NewDesign/clear-night.svg';
import cloudy from './i/NewDesign/cloudy.svg';
import fog from './i/NewDesign/fog.svg';
import lightRain from './i/NewDesign/light-rain.svg';
import lightRainNight from './i/NewDesign/light-rain-night.svg';
import occRain from './i/NewDesign/occ-rain.svg';
import occSnow from './i/NewDesign/occ-snow.svg';
import lightSnowNight from './i/NewDesign/light-snow-night.svg';
import partlyCloudy from './i/NewDesign/partly-cloudy.svg';
import partlyCloudyNight from './i/NewDesign/partly-cloudy-night.svg';
import rain from './i/NewDesign/rain.svg';
import rainNight from './i/NewDesign/rain-night.svg';
import sleet from './i/NewDesign/sleet.svg';
import snow from './i/NewDesign/snow.svg';
import snowNight from './i/NewDesign/snow-night.svg';
import thunder from './i/NewDesign/thunder.svg';

import geo from './i/NewDesign/geo.svg';
import wind from './i/NewDesign/wind.svg';

export default {
  geo,
  clear,
  'clear-night':         clearNight,
  cloudy,
  fog,
  'light-rain':          lightRain,
  'light-rain-night':    lightRainNight,
  'occ-rain':            occRain,
  'occ-snow':            occSnow,
  'light-snow-night':    lightSnowNight,
  'partly-cloudy':       partlyCloudy,
  'partly-cloudy-night': partlyCloudyNight,
  rain,
  'rain-night':          rainNight,
  sleet,
  snow,
  'snow-night':          snowNight,
  thunder,
  wind,
};
