export const GTM_ID_RAMBLER = 'GTM-KJBSQR';

export const GA_DESKTOP_ID = 'UA-38705902-1';

export const YANDEX_METRICS = {
  COMMON:         29582730,
  PORTAL:         26649402,
  DESKTOP_UNIQUE: 26557662,
  MOBILE_PORTAL:  27440552,
  MOBILE_UNIQUE:  30260472,
};

export const TOP100_DESKTOP_ID = 139601;
export const TOP100_MOBILE_ID = 3120529;
// для отслеживания суперпользователей по всему холдингу
export const TOP100_COMMON_ID = 7726560;
// для отслеживания суперюзеров внутри портала Рамблер
export const TOP100_RAMBLER_ID = 7728281;
export const TOP100_DATASET_DESKTOP = [
  'weather',
  'logo',
  'cerber',
  'cerber-topline',
];

export const TOP100_DATASET_MOBILE = [
  'weather',
  'cerber',
  'cerber-topline-mob',
];

export const GEMIUS_ID = 'nSzgvPuSG0jfABCp5jRWjnZHXmDBvAdjV.RMPABTRG3.57';
