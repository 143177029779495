import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import _map from 'lodash.map';

import s from './index.scss';

const defaultCols = ['00', '03', '06', '09', '12', '15', '18', '21', '24'];

class ChartAsTable extends React.Component {
  static propTypes = {
    chartsData: pt.object.isRequired,
    className:  pt.string.isRequired,
  }

  getRowName = (name) => {
    switch (name) {
      case 'temperature': {
        return 'Температура, °C';
      }
      case 'humidity': {
        return 'Влажность, %';
      }
      case 'pressure': {
        return 'Давление, мм';
      }
      case 'wind': {
        return 'Ветер, м/с';
      }
      case 'precipitation': {
        return 'Осадки, мм';
      }
      default:
        return '';
    }
  }

  render() {
    const {
      chartsData,
      className,
    } = this.props;

    return (
      <div className={cn(s.chartAsTable, className)}>
        <table className={s.chartAsTableTable}>
          <thead>
            <tr>
              <th></th>
              {defaultCols.map((item, key) => {
                const index = `ChartAsTable-head-${key}`;
                return (
                  <th
                    className={s.chartAsTableHead}
                    key={index}
                  >
                    {`${item}:00`}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {_map(chartsData, (item, key) => {
              if (key === 'icon') {
                return null;
              }
              const index = `ChartAsTable-body-${key}`;
              const dataArray = key === 'wind' ? item.speed : item;

              return (
                <tr key={index}>
                  <td>{this.getRowName(key)}</td>
                  {dataArray.map((value, i) => {
                    const childIndex = `ChartAsTable-body-item-${i}`;
                    return (
                      <td
                        className={s.chartAsTableValue}
                        key={childIndex}
                      >
                        {value}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export const ChartAsTableWithoutHocs = ChartAsTable;
export default withStyles(s)(ChartAsTable);
