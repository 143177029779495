import {
  FETCH_TOWN_DATA_SUCCESS,
  FETCH_TOWN_DATA_FAILURE,
} from 'constants/actions/town';

export default function town(state = {}, action) {
  switch (action.type) {
    case FETCH_TOWN_DATA_SUCCESS: {
      return {
        ...state,
        town: action.payload.town,
      };
    }
    case FETCH_TOWN_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
