import {
  FETCH_HOME_DATA_SUCCESS,
  FETCH_HOME_DATA_FAILURE,
} from 'constants/actions/home';

export default function home(state = {}, action) {
  switch (action.type) {
    case FETCH_HOME_DATA_SUCCESS: {
      return {
        ...state,
        currentWeather: action.payload.data.current_weather,
        rangeWeather:   action.payload.data.range_weather,
        town:           action.payload.data.town,
        today:          action.payload.data.local_now,
        seo:            action.payload.data.seo,
      };
    }
    case FETCH_HOME_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
