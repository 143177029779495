import React from 'react';
import * as pt from 'prop-types';

import {
  AD_SUPERFOOTER_ID,
  AD_LISTING_1_ID,
} from 'constants/ad';

import Ad from 'components/Ad';

import WeatherWarning from 'components/WeatherWarning';
import DayBlock from 'Desktop/components/DayBlock';
import TodayBlock from 'Desktop/components/TodayBlock';
import TableDataBlock from 'Desktop/components/TableDataBlock';
import CitiesWeather from 'Desktop/components/CitiesWeather';
import NearestCities from 'Desktop/components/NearestCities';
import GeoModal from 'components/GeoModal';
import RoadwayVisibility from 'components/RoadwayVisibility';
// import Map from 'components/Map';

class Day extends React.Component {
  static propTypes = {
    windowType:        pt.string.isRequired,
    screenTypeReady:   pt.bool.isRequired,
    page:              pt.string.isRequired,
    tableData:         pt.array,
    currentDay:        pt.string,
    weatherDetail:     pt.object,
    roadwayVisibility: pt.shape({
      points:      pt.number.isRequired,
      description: pt.string.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    tableData:     null,
    currentDay:    null,
    weatherDetail: null,
  }

  getDayParts = () => {
    const data = this.props.tableData && this.props.tableData[0];
    return data && {
      night: {
        temperature: data.temperature[0],
        icon:        data.icon[0],
      },
      morning: {
        temperature: data.temperature[2],
        icon:        data.icon[2],
      },
      day: {
        temperature: data.temperature[4],
        icon:        data.icon[4],
      },
      evening: {
        temperature: data.temperature[6],
        icon:        data.icon[6],
      },
    };
  }

  render() {
    const {
      windowType,
      screenTypeReady,
      page,
      tableData,
      currentDay,
      roadwayVisibility,
      weatherDetail,
    } = this.props;

    const isToday = page === 'today';
    const isTomorrow = page === 'tomorrow';

    return (
      <div>
        {isToday && <WeatherWarning />}
        {isToday ?
          <TodayBlock
            currentDay={currentDay}
            dayParts={this.getDayParts()}
            weatherDetail={weatherDetail}
          /> :
          <DayBlock />
        }
        {
          (isToday || isTomorrow) &&
            <RoadwayVisibility {...roadwayVisibility} />
        }
        <NearestCities />
        {windowType !== 'mobile' && screenTypeReady &&
          <Ad
            id={AD_SUPERFOOTER_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isLazy
          />
        }
        {isToday &&
          <TableDataBlock
            period="today"
            tableData={tableData}
            currentDay={currentDay}
          />
        }
        {windowType === 'mobile' && screenTypeReady &&
          <Ad
            id={AD_LISTING_1_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isMobInDesk
            isLazy
          />
        }
        {/*isToday && <Map />*/}
        <CitiesWeather />

        <GeoModal />
      </div>
    );
  }
}

export const DayWithoutHocs = Day;
export default Day;
