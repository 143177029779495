import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import _map from 'lodash.map';

import {
  AD_SUPERFOOTER_ID,
  AD_LISTING_1_ID,
} from 'constants/ad';

import GeomagneticData from 'static/geomagnetic.json';

import Ad from 'components/Ad';
import GeomagneticChart from 'components/GeomagneticChart';
import GeoModal from 'components/GeoModal';

import YandexLogo from 'components/YandexLogo';

import ChangeUserGeo from 'Desktop/components/ChangeUserGeo';
import ChangeCity from 'Desktop/components/ChangeCity';
import ChartGeomagneticAsTable from 'Desktop/components/ChartGeomagneticAsTable';
import SimpleNowBlock from 'Desktop/components/SimpleNowBlock';
import SimplePopularRequests from 'Desktop/components/SimplePopularRequests';
import CitiesWeather from 'Desktop/components/CitiesWeather';

import s from './index.scss';

class Geomagnetic extends React.Component {
  static propTypes = {
    windowType:      pt.string.isRequired,
    screenTypeReady: pt.bool.isRequired,
    // page:            pt.string.isRequired,
    title:           pt.string.isRequired,
  }

  render() {
    const {
      title,
      windowType,
      screenTypeReady,
      // page,
    } = this.props;

    return (
      <div className={s.geomagnetic}>
        <div className={s.geomagneticBlock}>
          <div className={s.geomagneticTop}>
            <h1 className={s.geomagneticTitle}>{title}</h1>
            <ChangeUserGeo />
          </div>
          <ChangeCity className={s.geomagneticLocation} />
        </div>
        <noscript
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<style>
              .${s.geomagneticChart} {display: none}
              .${s.geomagneticNoJsTable} {
                visibility: visible;
                padding: 0 20px 30px;
                height: auto;
              }
            </style>`,
          }}
        />
        <GeomagneticChart className={s.geomagneticChart} />
        <ChartGeomagneticAsTable className={s.geomagneticNoJsTable} />
        <div className={s.geomagneticInfo}>
          <span className={s.geomagneticInfoTitle}>Состояние магнитного поля</span>
          <div className={s.geomagneticInfoData}>
            {_map(GeomagneticData, (item, key) => {
              const itemKey = `GeomagneticInfo-item-${key}`;

              return (
                <div
                  className={s.geomagneticInfoItem}
                  key={itemKey}
                >
                  <span className={cn(
                    s.geomagneticInfoItemCircle,
                    [s[`geomagneticInfoItemCircle${key}`]],
                  )}
                  />
                  <span className={s.geomagneticInfoItemValue}>{key}</span>
                  <span className={s.geomagneticInfoItemText}>{item.title}</span>
                </div>
              );
            })}
          </div>
          <YandexLogo className={s.yandex} />
        </div>
        {windowType !== 'mobile' && screenTypeReady &&
          <Ad
            id={AD_SUPERFOOTER_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isLazy
          />
        }
        {windowType === 'mobile' && screenTypeReady &&
          <Ad
            id={AD_LISTING_1_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isMobInDesk
            isLazy
          />
        }
        <div className={s.geomagneticSimpleBlocks}>
          <SimpleNowBlock />
          <SimplePopularRequests />
        </div>
        <CitiesWeather />

        <GeoModal />
      </div>
    );
  }
}

export const GeomagneticWithoutHocs = Geomagnetic;
export default withStyles(s)(Geomagnetic);
