import React from 'react';
import * as pt from 'prop-types';

import {AD_SUPERFOOTER_ID} from 'constants/ad';

import Ad from 'components/Ad';

import NowBlock from 'Desktop/components/NowBlock';
import WeatherWarning from 'components/WeatherWarning';
import WeatherHours from 'Desktop/components/WeatherHours';
import CitiesWeather from 'Desktop/components/CitiesWeather';
import NearestCities from 'Desktop/components/NearestCities';
import GeoModal from 'components/GeoModal';
import RoadwayVisibility from 'components/RoadwayVisibility';
// import Map from 'components/Map';

class NowPage extends React.Component {
  static propTypes = {
    windowType:        pt.string.isRequired,
    screenTypeReady:   pt.bool.isRequired,
    roadwayVisibility: pt.shape({
      points:      pt.number.isRequired,
      description: pt.string.isRequired,
    }).isRequired,
  }

  render() {
    const {
      windowType,
      screenTypeReady,
      roadwayVisibility,
    } = this.props;

    return (
      <div>
        <WeatherWarning />
        <NowBlock />
        <RoadwayVisibility {...roadwayVisibility} />
        <WeatherHours />
        <NearestCities />
        {windowType !== 'mobile' && screenTypeReady &&
          <Ad
            id={AD_SUPERFOOTER_ID}
            onRenderWrapperStyle={{marginTop: '15px'}}
            isLazy
          />
        }
        {/*<Map />*/}
        <CitiesWeather />

        <GeoModal />
      </div>
    );
  }
}

export const NowWithoutHocs = NowPage;
export default NowPage;
