import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import Button from 'rambler-ui/Button';

import ButtonComponent from 'components/Button';
import BigWeatherIconTemper from 'components/BigWeatherIconTemper';
import WeatherDetailParams from 'components/WeatherDetailParams';
import Link from 'components/Link';

import s from './index.scss';

class SimpleNowBlock extends React.Component {
  static propTypes = {
    isNewDesign: pt.bool.isRequired,
    now:         pt.object.isRequired,
    town:        pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }).isRequired,
    page: pt.string.isRequired,
  }

  render() {
    const {
      isNewDesign,
      town,
      now,
      page,
    } = this.props;
    const weatherDetail = now && now.detail;

    if (!weatherDetail) return null;

    return (
      <div
        className={cn(
          s.nowBlock,
          isNewDesign ? s.nowBlockNewDesign : s.nowBlockOldDesign,
        )}
        data-block="now"
      >
        <div className={cn(
          s.nowHead,
          isNewDesign ? s.nowHeadNewDesign : s.nowHeadOldDesign,
        )}
        >
          <div
            className={cn(
              s.nowTitle,
              isNewDesign ? s.nowTitleNewDeisgn : s.nowTitleOldDesign,
            )}
          >
            Погода {town.loc_case_name} сейчас
          </div>
        </div>
        <div className={s.nowDetail}>
          <BigWeatherIconTemper
            icon={now.icon}
            temperature={now.temperature}
            className={cn(
              s.nowTemperature,
              isNewDesign ? s.nowTemperatureNewDeisgn : s.nowTemperatureOldDesign,
            )}
          />
          <WeatherDetailParams
            className={cn(
              s.nowParams,
              isNewDesign ? s.nowParamsNewDeisgn : s.nowParamsOldDesign,
            )}
            paramClassName={s.nowParam}
            data={weatherDetail}
            moonTop100={`${page}::link_now_requests::now::link`}
          />
        </div>
        {isNewDesign
          ? (
            <ButtonComponent
              className={cn(s.link, s.linkNewDesign)}
              href={`/${town.url_path}/now/`}
              data-weather={`${page}::link_now_requests::now::button`}
            >
              Подробнее
            </ButtonComponent>
          )
          : (
            <div className={cn(s.link, s.linkOldDesign)}>
              <Button
                size="small"
                type="outline"
                iconPosition="right"
                container={
                  <Link
                    to={`/${town.url_path}/now/`}
                    data-weather={`${page}::link_now_requests::now::button`}
                  />
                }
              >
                Подробно
              </Button>
            </div>
          )}
      </div>
    );
  }
}

export const NowBlockWithoutHocs = SimpleNowBlock;
export default withStyles(s)(SimpleNowBlock);
