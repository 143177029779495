import React from 'react';

export default function(WrappedComponent) {
  return class clientSideOnly extends React.Component {
    state = {
      isMounted: false,
    };

    componentDidMount() {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({isMounted: true});
    }

    render() {
      if (!this.state.isMounted) return null;
      return <WrappedComponent {...this.props} />;
    }
  };
}
