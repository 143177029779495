import FETCH_SEO_DATA_SUCCESS from 'constants/actions/seo';

export default function seo(state = {}, action) {
  switch (action.type) {
    case FETCH_SEO_DATA_SUCCESS: {
      const seoData = {...action.payload.seo};
      let canonical = seoData.canonical;

      if (canonical && canonical.includes('donetskaya-nardnaya-respublika')) {
        canonical = canonical.replace('donetskaya-nardnaya-respublika', 'donetskaya-narodnaya-respublika');
      }

      return {
        ...state,
        title:          seoData.title,
        description:    seoData.description,
        keywords:       seoData.keywords,
        canonical,
        seoText:        seoData.seo_text,
        seoDescription: seoData.seo_text_description,
      };
    }
    default:
      return state;
  }
}
