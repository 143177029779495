import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import _omit from 'lodash.omit';

import s from './index.scss';

class ChartMonthAsTable extends React.Component {
  static propTypes = {
    chartsData: pt.array.isRequired,
    className:  pt.string,
  }

  static defaultProps = {
    className: '',
  }

  getRowName = (name) => {
    switch (name) {
      case 'temperatureNight': {
        return 'Температура ночью, °C';
      }
      case 'temperatureDay': {
        return 'Температура днём, °C';
      }
      case 'humidity': {
        return 'Влажность, %';
      }
      case 'pressure': {
        return 'Давление, мм';
      }
      case 'wind': {
        return 'Ветер, м/с';
      }
      case 'precipitation': {
        return 'Осадки, мм';
      }
      default:
        return '';
    }
  }

  render() {
    const {
      chartsData,
      className,
    } = this.props;

    const params = Object.keys(_omit(chartsData[0], 'date'));

    return (
      <div className={cn(s.chartAsTable, className)}>
        <table className={s.chartAsTableTable}>
          <thead>
            <tr>
              <th></th>
              {chartsData.map((item, index) => {
                const key = `ChartMonthAsTable-head-1th-${index}`;

                return (
                  <th
                    className={s.chartAsTableHead}
                    key={key}
                  >
                    {item.date}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {params.map((paramName, index) => {
              const dayKey = `ChartMonthAsTable-body-${index}-${paramName}`;

              return (
                <tr key={dayKey}>
                  <td>{this.getRowName(paramName)}</td>
                  {chartsData.map((day, paramIndex) => {
                    const key = `ChartMonthAsTable-${paramName}-${index}-${paramIndex}`;

                    return (
                      <td
                        className={s.chartAsTableValue}
                        key={key}
                      >
                        {day[paramName]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export const ChartMonthAsTableWithoutHocs = ChartMonthAsTable;
export default withStyles(s)(ChartMonthAsTable);
