import {connect} from 'react-redux';

import getMonthNoJSData from 'selectors/month/noJSData';

import Month from './index';

const mapStateToProps = state => ({
  page:              state.runtime.page,
  town:              state.town.town,
  windowType:        state.runtime.screen,
  screenTypeReady:   state.runtime.screenTypeReady,
  currentDate:       state.month.currentDate,
  hasClimateData:    state.month.hasClimateData,
  chunkedChartsData: getMonthNoJSData(state.month.monthList),
});

export default connect(mapStateToProps)(Month);
