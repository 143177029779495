import {connect} from 'react-redux';
import ResortCitiesWeather from './index';

const mapStateToProps = state => ({
  listData:  state.weatherCities.resortListData,
  monthName: state.monthName.monthName,
  page:      state.runtime.page,
});

export default connect(mapStateToProps)(ResortCitiesWeather);
