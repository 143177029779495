import {
  FETCH_DAY_DATA_SUCCESS,
  FETCH_DAY_DATA_FAILURE,
} from 'constants/actions/day';

export default function day(state = [], action) {
  switch (action.type) {
    case FETCH_DAY_DATA_SUCCESS: {
      const data = {...action.payload.data};

      return {
        ...state,
        prevDay:    data.prev_day,
        nextDay:    data.next_day,
        chartsData: data.today_hourly ? {
          temperature: data.today_hourly.temperature,
          humidity:    data.today_hourly.humidity,
          pressure:    data.today_hourly.pressure,
          wind:        {
            speed:     data.today_hourly.wind_speed,
            direction: data.today_hourly.wind_direction,
          },
          precipitation: data.today_hourly.precipitation,
          icon:          data.today_hourly.icon,
        } : null,
        dayParts: data.today_parts || null,
        day:      {
          temperature: data.date_weather.temperature,
          icon:        data.date_weather.icon,
          alerts:      data.date_weather.alerts,
          tempFeels:   data.date_weather.temp_feels,
          detail:      {
            precipitationProbability: data.date_weather.precipitation_probability,
            wind:                     {
              speed:     data.date_weather.wind_speed,
              direction: data.date_weather.wind_direction,
            },
            pressure: data.date_weather.pressure_mm,
            sun:      {
              rise: data.date_weather.sunrise,
              set:  data.date_weather.sunset,
            },
            daylight:         data.date_weather.daylight,
            moonPhase:        data.date_weather.moon_phase,
            waterTemperature: data.date_weather.temp_water || null,
            uv:               data.date_weather.uv,
            geomagnetic:      data.date_weather.geomagnetic,
          },
          roadwayVisibility: data.date_weather.roadway_visibility,
        },
        currentDay: data.local_now,
        dataDay:    data.date_weather.date,
        town:       data.town,
        seo:        data.seo,
        tableData:  data.table_data,
      };
    }
    case FETCH_DAY_DATA_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
