import {SET_UI_STATE} from 'constants/redux/index';

// TODO need automatic solution - зачем? какое automatic?
export const initialState = {
  headerSearch: {
    isTyping:            false,
    isSuggestVisible:    false,
    selectedSuggestItem: -1,
  },
  header: {
    isSearchVisible: true,
  },
  geoModal: {
    isModalVisible: false,
    selectedOption: '',
    reloadGeoKey:   1,
  },
  geoSuggest: {
    isSuggetVisible: false,
  },
  geoSearch: {
    isSearchVisible: false,
  },
  geoModalSuggest: {
    isTyping:            false,
    isFocused:           false,
    isSuggestVisible:    false,
    selectedSuggestItem: {index: -1, geoID: null},
  },
  toplineDropdown: {
    isDropdownVisible: false,
  },
  user: {
    isDropdownVisible: false,
    isEmailValid:      true,
    isPasswordValid:   true,
  },
  topline: {
    sticky:     false,
    hidden:     false,
    focused:    false,
    showShadow: false,
  },
  toplineAside: {
    isAsideVisible: false,
    pageYOffset:    0,
  },
  pushSubscribe: {
    isDialogVisible: false,
  },
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case SET_UI_STATE:
      return {
        ...state,
        [action.payload.component]: {
          ...state[action.payload.component],
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
}
