import * as SentryReact from '@sentry/react';
import {sendToSentry} from '../sentry/common';

/**
 * Функция отправки ошибки с браузера с настраиваемым scope
 *
 * @param err - Ошибка типа `new Error()`
 * @param extraTags - Опционально. Тэги в виде объекта
 * @param modifyscope - Опционально. Callback типа `(scope) => {...}`
 */
const sentryReactSend = sendToSentry(SentryReact);

export default (error, data) => {
  if (__CLIENT__ && !__TEST__) {
    if (!__DEV__) {
      sentryReactSend(error, data);
    } else {
      console.error(error, data); // eslint-disable-line no-console
    }
  }
};
