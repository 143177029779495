import {connect} from 'react-redux';

import {fetchWeatherCities} from 'actions/weatherCities';
import setUIState from 'actions/ui';

import CitiesSearch from './index';

const mapStateToProps = state => ({
  searchData:   state.weatherCities.searchData,
  page:         state.runtime.page,
  isFirstVisit: state.runtime.pageNumber === 1,
});

const mapDispatchToProps = dispatch => ({
  getCities(query) {
    dispatch(fetchWeatherCities(query, 7));
  },

  toggleSearchVisibility(value) {
    dispatch(setUIState({
      component: 'geoSearch',
      name:      'isSearchVisible',
      value,
    }));

    if (!value) {
      dispatch(fetchWeatherCities(''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CitiesSearch);
