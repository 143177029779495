/**
 * https://yandex.ru/support/metrika/code/counter-initialize.html#counter-initialize__counter_params
 * https://yandex.ru/support/metrika/code/counter-spa-setup.html
 * Обработка двух кейсов при отправке ивентов в метрику
 * – ивент отправили, но счетчик еще не прогрузился
 * – счетчик уже прогрузился - можно отправлять сразу
 */
class YandexEvent {
  constructor(id) {
    this.id = id;

    document.addEventListener(`yacounter${this.id}inited`, this.onCounterInit);
  }

  eventsPool = [];

  onCounterInit = () => {
    this.eventsPool.forEach(event => this.send(event));
    this.eventsPool = [];
  };

  send(event) {
    if ((window[`yaCounter${this.id}`] && window.ym) || __TEST__) {
      window.ym(this.id, event.type, event.data);
      document.removeEventListener(`yacounter${this.id}inited`, this.onCounterInit);
    } else {
      this.eventsPool.push(event);
    }
  }
}

export default YandexEvent;
