import React from 'react';
import cn from 'classnames';
import * as pt from 'prop-types';

import iconsOldDesign from './iconsOldDesign';
import iconsNewDesign from './iconsNewDesign';

import s from './index.scss';

class WeatherIcon extends React.Component {
  static propTypes = {
    isNewDesign: pt.bool.isRequired,
    size:        pt.number,
    type:        pt.string.isRequired,
    isAzure:     pt.bool,
    className:   pt.string,
    svgAttrs:    pt.object,
  };

  static defaultProps = {
    size:      100,
    isAzure:   false,
    className: '',
    svgAttrs:  {},
  }

  getIcons = () => {
    const {
      isNewDesign,
      size,
      type,
    } = this.props;

    const checker = (icons, isNew) => isNew
      ? !icons[type]
      : !icons[size] || !icons[size][type];

    const icons = isNewDesign ? iconsNewDesign : iconsOldDesign;

    if (checker(icons, isNewDesign)) {
      return null;
    }

    return icons;
  }

  render() {
    const {
      isNewDesign,
      size,
      type,
      isAzure,
      className,
      svgAttrs,
    } = this.props;

    const icons = this.getIcons();

    if (!icons) return null;

    const Element = isNewDesign ? icons[type] : icons[size][type];

    return (
      <Element
        className={cn(
          isNewDesign && s.defaultColor,
          isNewDesign && isAzure && s.azureColor,
          className,
        )}
        {...svgAttrs}
      />
    );
  }
}

export default WeatherIcon;
