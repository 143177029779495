import {
  FETCH_WEEKEND_DATA_SUCCESS,
  FETCH_WEEKEND_DATA_FAILURE,
} from 'constants/actions/weekend';

export default function weekend(state = {}, action) {
  switch (action.type) {
    case FETCH_WEEKEND_DATA_SUCCESS: {
      const data = {...action.payload.data};

      return {
        ...state,
        rangeData:  data.range_weather,
        tableData:  data.table_data,
        currentDay: data.local_now,
      };
    }
    case FETCH_WEEKEND_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
