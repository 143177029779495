const AABWrapper = {
  reconnect() {
    if (this.antiadblock) {
      try {
        return this.antiadblock.reconnect();
      } catch (e) {
        console.error(e);
      }
    }
    return false;
  },

  addBlock2Place(num) {
    if (this.antiadblock && num > -1) {
      try {
        return this.antiadblock.addBlock2Place(num);
      } catch (e) {
        console.error(e);
      }
    }
    return false;
  },
};

Object.defineProperties(AABWrapper, {
  antiadblock: {
    get: () => {
      if (window && window.QW50aS1BZEJsb2Nr) {
        return window.QW50aS1BZEJsb2Nr;
      }
      return null;
    },
  },

});

export default AABWrapper;
