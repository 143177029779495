import React from 'react';
import * as pt from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import {menuLinks} from 'static/header.json';

import Link from 'components/Link';

import s from './index.scss';

class Header extends React.Component {
  static propTypes = {
    town: pt.shape({
      name:          pt.string.isRequired,
      loc_case_name: pt.string.isRequired,
      url_path:      pt.string.isRequired,
      geo_id:        pt.number,
    }),
    page: pt.string.isRequired,
  };

  static defaultProps = {
    town: null,
  };

  state = {
    isLinksVisible: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (this.props.page !== nextProps.page)
      || (this.props.town.url_path !== nextProps.town.url_path)
      || this.state.isLinksVisible !== nextState.isLinksVisible;
  }

  getTop100Text = (page, menuPage, title) => (
    `${page}::top_menu::${title}::${page === menuPage ? 'current' : 'not_current'}`
  )

  render() {
    const {
      town,
      page,
    } = this.props;

    if (!town) return null;

    return (
      <div className={s.container} data-block="menu">
        <nav className={s.menu}>
          {
            menuLinks.map((menuLink, i) => {
              const key = `header-link-${i}`;
              const url = `/${town.url_path}/${menuLink.href}/`;

              return (
                <Link
                  className={
                    cn(
                      s.menuLink,
                      menuLink.page === page && s.menuLinkActive,
                    )
                  }
                  to={url}
                  key={key}
                  data-weather={this.getTop100Text(page, menuLink.page, menuLink.title)}
                  onClick={() => this.setState({isLinksVisible: false})}
                >
                  {menuLink.title}
                </Link>
              );
            })
          }
        </nav>
      </div>
    );
  }
}

export const HeaderWithoutHocs = Header;
export default withStyles(s)(Header);
