export default {
  N:  'С',
  NE: 'СВ',
  E:  'В',
  SE: 'ЮВ',
  S:  'Ю',
  SW: 'ЮЗ',
  W:  'З',
  NW: 'СЗ',
};
