import {connect} from 'react-redux';

import setUIState from 'actions/ui';
import {
  setGeoLocation,
} from 'actions/geo';
import {fetchHeaderData} from 'actions/header';
import {setRuntimeVariable} from 'actions/runtime';

import GeoModal from './index.js';

const mapStateToProps = state => ({
  isModalVisible: state.ui.geoModal.isModalVisible,
  reloadGeoKey:   state.ui.geoModal.reloadGeoKey,
});

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(setUIState({
      component: 'geoModal',
      name:      'isModalVisible',
      value:     false,
    }));
  },

  setGeoLocation(geoID = false, reloadGeoKey) {
    dispatch(setGeoLocation(geoID));
    dispatch(setRuntimeVariable({
      name:  'geoid',
      value: geoID,
    }));
    dispatch(setUIState({
      component: 'geoModal',
      name:      'reloadGeoKey',
      value:     reloadGeoKey,
    }));
    dispatch(fetchHeaderData(geoID));
  },

  setCityChanging(value) {
    dispatch(setRuntimeVariable({
      name: 'isCityChanging',
      value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoModal);
